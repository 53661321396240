import startOfDay from "date-fns/startOfDay";
import differenceInCalendarDays from "date-fns/differenceInCalendarDays";
import formatDistanceStrict from "date-fns/formatDistanceStrict";

export function expiresInDays(
  lastDay: Date,
  addVerb = true,
  addSuffix = true,
  now: Date = new Date()
): { num: number; str: string } {
  const diff = differenceInCalendarDays(lastDay, now);
  const verb = addVerb ? (diff >= 0 ? "expires " : "expired ") : "";
  if (diff == 0) return { num: 0, str: `${verb}today` };

  const startOfToday = startOfDay(now);
  const startOfLastDay = startOfDay(lastDay);
  return {
    num: diff,
    str:
      verb +
      `${formatDistanceStrict(startOfLastDay, startOfToday, {
        unit: "day",
        addSuffix
      })}`
  };
}

/**
 * Undefined if OK, otherwise css class to present severity
 */
export function expiresInDaysSeverityClass(
  lastDay: Date,
  dangerThresholdDays: number,
  warnThresholdDays: number,
  now: Date = new Date()
) {
  const diff = differenceInCalendarDays(lastDay, now);

  if (diff < dangerThresholdDays) {
    return "is-danger";
  } else if (diff < warnThresholdDays) {
    return "is-warning";
  } else {
    return "is-success";
  }
}

import formatISO9075 from "date-fns/formatISO9075";

export function friendlyDate(txtUtcDate: string): string {
  return formatISO9075(new Date(txtUtcDate), { representation: "date" });
}

export function friendlyDateTime(txtUtcDate: string): string {
  return formatISO9075(new Date(txtUtcDate), { representation: "complete" });
}

export function friendlyDateTimeWithTz(txtUtcDate: string): string {
  const dateTime = formatISO9075(new Date(txtUtcDate));
  let tz: string | undefined = undefined;
  if (Intl?.DateTimeFormat) {
    tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return dateTime + (tz ? ` (${tz})` : "");
}

import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict";

/**
 * Returns distance string representation of ther date, for example "3 days ago"
 * @param date - ISO date string or js date number
 * @returns
 */
export function distance(date: string | number): string {
  return formatDistanceToNowStrict(
    typeof date == "string" ? new Date(date) : date,
    {
      addSuffix: true
    }
  );
}

export function friendlyDateAllFormats(isoDate: string | undefined) {
  return isoDate
    ? {
        date: friendlyDate(isoDate),
        dateTime: friendlyDateTime(isoDate),
        dateTimeWithTz: friendlyDateTimeWithTz(isoDate),
        distance: distance(isoDate)
      }
    : {
        date: undefined,
        dateTime: undefined,
        dateTimeWithTz: undefined,
        distance: undefined
      };
}
