

































import { defineComponent, computed } from "@vue/composition-api";
import useLog from "@/composables/_useLog";
import useRouter from "@/composables/_useRouter";

import { NavItem } from "@/core/navigation";

import useBreakpoints from "@/composables/useBreakpoints";

export default defineComponent({
  name: "m-navigation",
  props: {
    items: { type: Array as () => NavItem[], default: [] }
  },
  setup(props) {
    const $log = useLog();
    const { route } = useRouter();

    const breakpoints = useBreakpoints();

    const visibleItems = computed(() => {
      return props.items.filter(item =>
        breakpoints.widescreen.value ? !item.hidden : !item.hiddenMobile
      );
    });
    const itemsMap = computed(() => {
      return new Map(props.items.map(item => [item.path, item]));
    });
    const visibleSubItems = computed(() => {
      if (route.value.matched.length == 0) return [];
      $log.debug("this.$route.matched", route.value.matched);
      const r = itemsMap.value.get(route.value.matched[1].path);
      return r?.children
        ? r?.children.filter(item =>
            breakpoints.widescreen.value ? !item.hidden : !item.hiddenMobile
          )
        : [];
    });
    return {
      visibleItems,
      visibleSubItems,
      itemsMap
    };
  }
});
