import Vue from "vue";

import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";
import { getTeam } from "/@/graphql/queries";
import { updateTeam } from "/@/graphql/mutations";
import { GetTeamQuery, UpdateTeamMutation } from "/@/API";

import { SupError } from "/@/models/Errors";

export const TeamRepo = {
  getById: async (id: string) => {
    const response = (await API.graphql(
      graphqlOperation(getTeam, { id })
    )) as GraphQLResult<GetTeamQuery>;

    Vue.$log.debug("getTeam", response);

    const teamItem = response.data?.getTeam;
    // It's ok if teamItem wasn't found: the team record appears only after the app is installed!
    return teamItem;
  },

  put: async (data: any) => {
    const response = (await API.graphql(
      graphqlOperation(updateTeam, { input: data })
    )) as GraphQLResult<UpdateTeamMutation>;

    Vue.$log.debug("updateTeam", response);

    if (
      (response.errors && response.errors.length > 0) ||
      !response.data ||
      !response.data.updateTeam
    ) {
      throw new SupError("Error updating team.");
    }
    return response.data.updateTeam;
  }
};
