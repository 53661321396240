


















































import { Options } from "/@/compatibility/vue-class-component";
import { AButtonProps } from "./BaseButton";

@Options({
  props: {
    focused: Boolean
  }
})
export default class AButton extends AButtonProps {
  focused!: boolean;
  imgSrc!: string;
  faIcon!: any;
  faIconRight!: any;

  get useClass() {
    return {
      "is-primary": this.primary,
      "is-danger": this.danger,
      "is-transparent": this.transparent,
      "is-rounded": this.rounded,
      "s-icon-only": !this.$slots.default,
      "is-loading": this.loading
    };
  }
}
