


























import { defineComponent } from "@vue/composition-api";
import usePropState from "@/composables/_usePropState";
import { v4 as uuidv4 } from "uuid";

export default defineComponent({
  name: "collapse-panel",
  props: {
    title: String,
    titleIcon: String,
    open: Boolean
  },
  setup(props) {
    const a11yId = "collapse-panel-" + uuidv4();
    const openState = usePropState<string[]>(props, "open");

    return { a11yId, openState };
  }
});
