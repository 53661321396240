import Vue from "vue";
import { Route } from "vue-router";

import store from "/@/store";

import { UserViewModel } from "@/models/UserModel";

export function routeRequiresAuth(route: Route) {
  const rootMeta = route.matched[0]?.meta;
  return rootMeta?.requiresAuth ?? false;
}

function routeLoginName(route: Route) {
  const rootMeta = route.matched[0]?.meta;
  return rootMeta.login ?? "login";
}

export default async function requiresAuth(
  to: Route,
  from: Route,
  next: Function
): Promise<boolean> {
  if (routeRequiresAuth(to)) {
    const user = await getUser();
    if (user) {
      Vue.$log.info(
        `Router requiresAuthGuard: ${to.fullPath} requires auth, already authenticated`
      );
      next();
    } else {
      Vue.$log.info(
        `Router requiresAuthGuard: ${to.fullPath} auth, redirect to login`
      );

      // there are "origin" and "app_guid" params from Zendesk iframe required to persist in query,
      // for Zendesk client to work properly
      // so we keep query when redirect
      // TODO: distinguish, and only set query when Zendesk
      next({
        name: routeLoginName(to),
        query: to.query
      });
    }
    return true;
  }
  Vue.$log.info(
    `Router requiresAuthGuard: ${to.fullPath} doesn't require auth`
  );
  return false;
}

async function getUser() {
  await store.dispatch("auth/authUser");
  const user = store.getters["auth/user"] as UserViewModel | null;
  return user;
}
