





































































import { computed, defineComponent } from "@vue/composition-api";

import DropdownMenuItem from "/@/components/dropdown-menu-item.vue";
import useUser from "@/composables/useUser";

interface VideoItem {
  label: string;
  videoLink: string;
  textLink: string;
}

interface MenuItem {
  label: string;
  icon: string;
  link: string;
}

/**
 * Component can be used in the menu (shown as a dropdown with activator button) and as an inline content (see `inline` prop)
 */
export default defineComponent({
  name: "support-menu",
  components: {
    DropdownMenuItem
  },
  props: {
    header: String,
    /**
     * Dropdown content is shown inline, trigger is removed
     */
    inline: { type: Boolean, default: false },
    /**
     * Dropdown will be expanded (full-width)
     */
    expanded: { type: Boolean, default: false }
  },
  setup() {
    const { isUserAnAgent } = useUser();

    const videoItems = computed(() => {
      const agentVideoItems: VideoItem[] = [
        {
          label: "How to submit a ticket",
          videoLink:
            "https://www.youtube.com/playlist?list=PLcHYdDVcWYl1DiLKhdYUWcAU0jDAmaDKn",
          textLink:
            "https://support.suptask.com/en/getting-started/how-to-find-my-tickets"
        },
        {
          label: "How to respond to a ticket",
          videoLink: "https://youtu.be/-clT80bu_ko",
          textLink:
            "https://support.suptask.com/en/getting-started/how-to-remediate-tickets"
        },
        {
          label: "How to overview tickets",
          videoLink: "https://youtu.be/PiSjZvb3Pzc",
          textLink:
            "https://support.suptask.com/en/getting-started/how-to-find-my-submitted-tickets"
        }
      ];
      const userVideoItems: VideoItem[] = [
        {
          label: "How to submit a ticket ",
          videoLink:
            "https://www.youtube.com/playlist?list=PLcHYdDVcWYl1DiLKhdYUWcAU0jDAmaDKn",
          textLink:
            "https://support.suptask.com/en/getting-started/how-to-find-my-tickets"
        },
        {
          label: "How to overview your tickets",
          videoLink: "https://youtu.be/4upPBDj2tyI",
          textLink:
            "https://support.suptask.com/en/getting-started/how-to-find-my-submitted-tickets"
        },
        {
          label: "How to search for your tickets",
          videoLink: "https://youtu.be/6SBRZ1Ypr2Q",
          textLink:
            "https://support.suptask.com/en/getting-started/how-to-search-for-tickets"
        }
      ];
      return isUserAnAgent.value ? agentVideoItems : userVideoItems;
    });

    const menuItems: MenuItem[] = [
      {
        label: "Explore All Videos",
        icon: "video",
        link: "https://www.youtube.com/channel/UCEvCDlJGO2bLwASqGFQTtqg"
      },
      {
        label: "Explore All Support Articles",
        icon: "file-lines",
        link: "https://support.suptask.com"
      }
    ];

    return {
      videoItems,
      menuItems
    };
  }
});
