































import { defineComponent, ref, watch } from "@vue/composition-api";
import { useMagicKeys } from "@vueuse/core";

export default defineComponent({
  name: "modal-simple",
  props: {
    dismissName: String,
    title: { type: String, required: true },
    tag: { type: String, default: "span" },
    inline: Boolean,
    okButton: { type: Boolean, default: true }
  },
  setup(props) {
    const { dismissed, dismiss } = useDismiss(props.dismissName);
    const visible = ref(props.inline && !dismissed);

    function close() {
      visible.value = false;
      dismiss();
    }

    const on = {
      click(event?: Event) {
        event?.preventDefault?.();
        visible.value = true;
      }
    };

    const { escape } = useMagicKeys();
    watch(escape, () => {
      close();
    });

    return {
      visible,
      on,
      close
    };
  }
});

function useDismiss(name: string | undefined) {
  const localStorageKey = name
    ? `user.preferences.dismissedModal.${name}`
    : undefined;

  const dismissed = localStorageKey
    ? localStorage.getItem(localStorageKey) !== null
    : false;

  function dismiss() {
    if (!localStorageKey) return;
    localStorage.setItem(localStorageKey, "");
  }

  return {
    dismissed,
    dismiss
  };
}
