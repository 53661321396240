
























































































































































import {
  defineComponent,
  toRefs,
  computed,
  ref,
  watch,
  onMounted
} from "@vue/composition-api";
import { ValidationProvider } from "vee-validate";
import Multiselect from "vue-multiselect";
import ASelect2Tag from "./a-select-2.tag.vue";

export default defineComponent({
  name: "a-select-2",
  props: {
    placeholder: String,
    tagPlaceholder: { type: String, default: "Press Enter to create a value" },
    label: String,
    help: String,
    options: { type: Array, required: true },
    commands: {
      type: Array,
      default: () => {
        return [];
      }
    },
    value: { type: [String, Number, Array, Object], required: false },
    multiple: Boolean,
    taggable: Boolean,
    max: Number,
    allowEmpty: { type: Boolean, default: true },
    closeOnSelect: { type: Boolean, default: true },

    /**
     * validation-provider's vid
     */
    validationId: String,
    /**
     * validation-provider's name
     */
    validationLabel: String,
    validationMode: String as () => "aggressive" | "passive" | "lazy" | "eager",
    rules: {
      type: [String, Object],
      default: ""
    },
    required: Boolean,
    disabled: { type: Boolean, default: false },

    selectClass: String,
    optionsLabelPropertyName: { type: String, required: true },
    optionsSelectedSecondLabelPropertyName: { type: String, required: false },
    optionsIdPropertyName: { type: String, required: true },
    optionsIconPropertyName: { type: String, required: false },
    optionsClassPropertyName: { type: String, required: false },
    optionsCanDeletePropertyName: { type: String, required: false },
    /**
     * General icon for all option items, applied if no specific icon (optionsIconPropertyName) set per option
     */
    optionIcon: { type: String, required: false },
    isPrimitiveValue: Boolean,
    groupLabelPropertyName: String,
    groupValuesPropertyName: String,
    groupSelect: { type: Boolean, default: false },
    openDirection: { type: String, default: "" },
    maxHeight: { type: Number, default: 180 },
    loading: { type: Boolean, default: false },
    hideSelected: { type: Boolean, default: false }
  },
  components: {
    ValidationProvider,
    Multiselect,
    ASelect2Tag
  },
  setup(props, context) {
    const { value, optionsIdPropertyName, isPrimitiveValue } = toRefs(props);

    const internalValue = ref(value?.value);

    const optionsState = computed(() => {
      return [...props.commands, ...props.options];
    });

    watch(
      () => value?.value,
      () => {
        internalValue.value = value?.value;
      }
    );

    const selectedOption = computed(() => {
      if (isPrimitiveValue.value && internalValue.value !== undefined) {
        const valuesPropertyName = props.groupValuesPropertyName;
        const opts = valuesPropertyName
          ? optionsState.value.flatMap((g: any) => g[valuesPropertyName])
          : optionsState.value;
        if (props.multiple) {
          return opts.filter((o: any) =>
            (internalValue.value as any[]).includes(
              o[optionsIdPropertyName.value]
            )
          );
        } else {
          return opts.filter(
            (o: any) => o[optionsIdPropertyName.value] == internalValue.value
          )[0];
        }
      } else {
        return internalValue.value;
      }
    });

    // v can also be an array when multiple=true
    function input(v: Record<string, string> | Record<string, string>[]) {
      let command;
      if (!Array.isArray(v) && props.commands.includes(v)) {
        command = v[optionsIdPropertyName.value];
      }
      if (Array.isArray(v)) {
        const commands = v.filter(val => props.commands.includes(val));
        if (commands.length > 0) {
          command = commands[0][optionsIdPropertyName.value];
        }
      }
      if (command) {
        context.emit(`command:${command}`);
        return false;
      }

      if (isPrimitiveValue.value && v !== undefined) {
        if (Array.isArray(v)) {
          const val = v.map(i => i[optionsIdPropertyName.value]);
          context.emit("input", val);
          internalValue.value = val;
        } else {
          context.emit("input", v[optionsIdPropertyName.value]);
          internalValue.value = v[optionsIdPropertyName.value];
        }
      } else {
        context.emit("input", v);
        internalValue.value = v;
      }
    }
    const multiselectRef = ref(null);
    onMounted(() => {
      context.emit("update:multiselect-ref", multiselectRef.value);
    });

    return {
      optionsState,
      selectedOption,
      input,
      multiselectRef
    };
  },
  computed: {
    allRules(): string | object {
      if (!this.required) return this.rules;
      if (!this.rules) return "required";
      if (typeof this.rules === "string") {
        return ["required", this.rules].join("|");
      } else {
        return {
          required: this.required,
          ...(this.rules as Record<string, string>)
        };
      }
    }
  },
  emits: ["input", "update:multiselect-ref"]
});
