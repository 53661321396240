







































































import { defineComponent, ref, watch } from "@vue/composition-api";
import useNavigation from "@/composables/useNavigation";
import useBreakpoints from "@/composables/useBreakpoints";

import MNavigation from "/@/components/molecules/m-navigation.vue";
import SupportMenu from "/@/components/support-menu.vue";
import OUserMenu from "/@/components/organisms/o-user-menu.vue";
import SettingsMenu from "/@/components/settings-menu.vue";
import RoundButton from "/@/components/round-icon.vue";
import MPlanBillingLink from "/@/components/molecules/m-plan-billing-link.vue";

export default defineComponent({
  name: "o-header",
  components: {
    MNavigation,
    SupportMenu,
    OUserMenu,
    SettingsMenu,
    MPlanBillingLink,
    RoundButton
  },
  props: {
    logoAsLink: { type: Boolean, default: true },
    hamburger: { type: Boolean, default: true }
  },
  setup() {
    const breakpoints = useBreakpoints();

    const hamburgerActive = ref(false);
    watch(breakpoints.widescreen, value => {
      if (value) {
        hamburgerActive.value = false;
      }
    });

    return {
      hamburgerActive,
      navigation: useNavigation()
    };
  }
});
