import Vue from "vue";
import { log, catchAndLog } from "@/helpers/decorators";

import { UserViewModel } from "@/models/UserModel";
import { WizardMode } from "@/views/GetStarted/Wizard.inject";
import { getIsUserAnAgent } from "@/composables/useUser";

import {
  trackEventGetStarted,
  updateCustomUserDataGetStarted,
  updateCustomUserDataTeam
} from "./helpCrunch";
import { authHelpCrunchUser } from "@/startup/helpCrunch";

import UserpilotHelper from "./userTrackingService.userpilotHelper";
import PlausibleHelper from "./userTrackingService.plausibleHelper";

export interface GetStartedTrack {
  status: "success" | "error";
  mode: WizardMode;
  teams?: string;
  team?: string;
  useCases?: string;
  useCase?: string;
  otherUseCaseDescription?: string;
}

/**
 * UserTrackingService abstracts user trackig methods and calls external user tracking services
 * Application calls these methods during the corresponding life cycle actions.
 */
export default class UserTrackingService {
  @log({ point: "both" })
  @catchAndLog()
  public static startApplication() {
    PlausibleHelper.init();
  }

  @log({ point: "both" })
  @catchAndLog()
  public static async authUser(user: UserViewModel | null | undefined) {
    await authHelpCrunchUser(user);
    UserpilotHelper.instance?.authUser(user);
  }

  @log({ point: "both" })
  @catchAndLog()
  public static async authTeam(user: UserViewModel | null | undefined) {
    await authHelpCrunchTeam(user);
    UserpilotHelper.instance?.authTeam(user);
  }

  /**
   * Track page navigation, will be called with (to: Route) parameter
   */
  @log({ point: "both" })
  @catchAndLog()
  public static trackNavigation(navigationData: {
    requiresAuth: boolean;
    fullPath: string;
  }) {
    const excludePathsStartWith = ["/widgets"];
    const excludePath = excludePathsStartWith.some(ep =>
      navigationData.fullPath.startsWith(ep)
    );
    const doTrack = navigationData.requiresAuth && !excludePath;
    Vue.$log.trace(
      `UserTrackingService.trackNavigation ${navigationData.fullPath}`,
      doTrack
    );
    if (doTrack) {
      UserpilotHelper.instance?.unsuppress();
      UserpilotHelper.instance?.reload();
    } else {
      UserpilotHelper.instance?.suppress();
    }
  }

  @log({ point: "both" })
  @catchAndLog()
  public static trackAppInstalled() {
    PlausibleHelper.trackAppInstalled();
  }

  @log({ point: "both" })
  @catchAndLog()
  public static trackGetStartedComplete(
    user: UserViewModel | null | undefined,
    data: GetStartedTrack
  ) {
    if (data.mode == "getStarted") {
      sendGetStartedToHelpCrunch(data);
      UserpilotHelper.instance?.trackGetStartedComplete(user, data);
      PlausibleHelper.trackGetStartedComplete();
    }
  }

  @log({ point: "both" })
  @catchAndLog()
  public static trackPurchase() {
    PlausibleHelper.trackPurchase();
  }
}

function sendGetStartedToHelpCrunch(data: GetStartedTrack) {
  updateCustomUserDataGetStarted({
    info_teams: data.teams,
    info_usecases: data.useCases,
    info_usecases_other: data.otherUseCaseDescription
  });
  trackEventGetStarted({
    status: data.status,
    teams: data.teams,
    usecases: data.useCases,
    usecases_other: data.otherUseCaseDescription
  });
}

function authHelpCrunchTeam(user: UserViewModel | null | undefined) {
  updateCustomUserDataTeam({
    plan_name: user?.team?.planSubscription?.plan.name ?? "N/A",
    is_paying: !(user?.team?.planSubscription?.isFree ?? true),
    is_trial: user?.team?.planSubscription?.isTrial === true,
    is_agent: getIsUserAnAgent(user ?? null, user?.team)
  });
}
