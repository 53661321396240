import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";
import { listFieldsByTeamId, getField } from "/@/graphql/queries";
import { createField, updateField, deleteField } from "/@/graphql/mutations";
import {
  GetFieldQuery,
  CreateFieldMutation,
  UpdateFieldMutation,
  DeleteFieldMutation
} from "/@/API";

import { ActionContext } from "vuex";
import Vue from "vue";

import {
  FieldViewModel,
  UiTypeViewEnum,
  systemFieldsMeta,
  SystemFieldName,
  customFieldMeta,
  systemFieldsUi,
  customFieldUi,
  UiTypeDb,
  uiTypeViewEnumToUiTypeDb,
  LabelValueItem
} from "/@/models/FieldModel";

import { dispatchUiNotification } from "/@/models/NotificationModel";
import { SupError } from "/@/models/Errors";

import { fetchList } from "/@/helpers/api.graphql";

import { RootState } from "/@/store";

import { v4 as uuidv4 } from "uuid";

function createUUID() {
  return uuidv4();
}

type DataSourceTypeDb = "string" | "array" | "users";

const toFieldDbModel = (source: FieldViewModel): any => {
  const uiTypeDb = uiTypeViewEnumToUiTypeDb[source.uiType] ?? "textarea";

  let dataSourceDb:
    | null
    | Array<{
        label: string;
        sequence: number;
        value: string;
      }>
    | string = null;

  let dataSourceTypeDb: undefined | DataSourceTypeDb = undefined;
  switch (source.uiType) {
    case UiTypeViewEnum.TextInput:
    case UiTypeViewEnum.TextInputMultiline:
    case UiTypeViewEnum.Date:
    case UiTypeViewEnum.Time:
    case UiTypeViewEnum.DateAndTime:
      dataSourceTypeDb = "string";
      break;
    case UiTypeViewEnum.StaticText:
      dataSourceTypeDb = "string";
      dataSourceDb = source.value ?? null;
      break;
    case UiTypeViewEnum.Select:
    case UiTypeViewEnum.SelectMulti: {
      dataSourceTypeDb = "array";
      const selectValues = source.values;
      if (source.meta.manageField?.selectValuesSortOnEdit) {
        // re-sort values alphabetically
        selectValues.sort((a, b) => a.label.localeCompare(b.label));
      }
      dataSourceDb = selectValues.map((v, idx) => {
        if (typeof v === "string") {
          return { label: v, sequence: idx + 1, value: v };
        } else {
          return { label: v.label, sequence: idx + 1, value: v.value };
        }
      });
      break;
    }
    case UiTypeViewEnum.User:
    case UiTypeViewEnum.UserMulti:
      dataSourceTypeDb = "users";
      break;
  }

  let multi = false;
  if (
    source.uiType == UiTypeViewEnum.TextInputMultiline ||
    source.uiType == UiTypeViewEnum.SelectMulti ||
    source.uiType == UiTypeViewEnum.UserMulti
  ) {
    multi = true;
  }

  return {
    id: source?.id,
    label: source?.label,
    // don't fill "name" here. for update "name" is not allowed. for create a new guid value will be added later.
    uiType: uiTypeDb,
    dataSource: JSON.stringify(dataSourceDb),
    dataSourceType: dataSourceTypeDb,
    multi: multi
  };
};

function isSystemField(name: string) {
  return Object.prototype.hasOwnProperty.call(systemFieldsMeta, name);
}
function getFieldMeta(name: string) {
  return isSystemField(name)
    ? systemFieldsMeta[name as SystemFieldName]
    : customFieldMeta;
}
function getFieldUi(name: string) {
  return isSystemField(name)
    ? systemFieldsUi[name as SystemFieldName]
    : customFieldUi;
}

const toFieldViewModel = (source: any): FieldViewModel => {
  // TODO: extract transformations into a mapper, based on dictionaries

  const sourceUiType = source?.uiType as UiTypeDb | undefined;
  let uiType: UiTypeViewEnum = UiTypeViewEnum.TextInput;
  const multi = source?.multi;
  let values: Array<LabelValueItem> = [];
  let value: string | undefined;

  switch (sourceUiType) {
    case "select":
      if (source?.dataSourceType == "array") {
        uiType = multi ? UiTypeViewEnum.SelectMulti : UiTypeViewEnum.Select;
        values = JSON.parse(source?.dataSource).map(
          ({
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            sequence,
            ...rest
          }: {
            sequence: number;
            label: string;
            value: string;
          }) => rest
        );
      }
      if (source?.dataSourceType == "users") {
        uiType = multi ? UiTypeViewEnum.UserMulti : UiTypeViewEnum.User;
      }
      if (source?.dataSourceType == "agents") {
        uiType = UiTypeViewEnum.Agent;
      }
      break;
    case "textarea":
      uiType = multi
        ? UiTypeViewEnum.TextInputMultiline
        : UiTypeViewEnum.TextInput;
      break;
    case "date":
      uiType = UiTypeViewEnum.Date;
      break;
    case "time":
      uiType = UiTypeViewEnum.Time;
      break;
    case "datetime":
      uiType = UiTypeViewEnum.DateAndTime;
      break;
    case "fileinput":
      uiType = UiTypeViewEnum.FileInput;
      break;
    case "mrkdwn":
      uiType = UiTypeViewEnum.StaticText;
      value = JSON.parse(source.dataSource);
      break;
  }

  const result = {
    id: source?.id,
    name: source?.name,
    label: source?.label,
    uiType,
    values,
    value,
    createdAt: source?.createdAt,
    updatedAt: source?.updatedAt,
    isDefault: isSystemField(source?.name),
    meta: getFieldMeta(source?.name),
    ui: getFieldUi(source?.name)
  };

  Vue.$log.trace("source:", source, "result:", result);

  return result;
};

const applyFieldViewModel = (
  source: FieldViewModel,
  target: FieldViewModel
) => {
  target.name = source?.name;
  target.label = source?.label;
  target.uiType = source?.uiType;
  target.values = source?.values;
  target.createdAt = source?.createdAt;
  target.updatedAt = source?.updatedAt;
};
export interface FieldsState {
  fieldList: Required<FieldViewModel>[];
  count: number | null;
  fieldDetails: FieldViewModel | null;
}

export default {
  namespaced: true,
  state(): FieldsState {
    return {
      fieldList: [],
      count: null,
      fieldDetails: null
    };
  },

  actions: {
    async loadFieldList(context: ActionContext<any, RootState>) {
      Vue.$log.debug("Fields. Action: loadFieldList");

      const respItems = await fetchList(
        listFieldsByTeamId,
        "listFieldsByTeamId",
        { teamId: context.rootState.auth.user?.teamId }
      );

      const items = respItems.map(toFieldViewModel);

      context.commit("setFieldList", items);
    },

    async loadFieldDetails(context: ActionContext<any, any>, data: any) {
      const response = (await API.graphql(
        graphqlOperation(getField, { id: data.id })
      )) as GraphQLResult<GetFieldQuery>;

      if (response.errors) {
        // TODO:
        throw new Error();
      }

      const result = toFieldViewModel(response.data?.getField);

      context.commit("setFieldDetails", result);
    },

    async resetFieldDetails(context: ActionContext<any, any>) {
      context.commit("setFieldDetails", null);
    },

    async createField(
      context: ActionContext<any, any>,
      payload: { field: FieldViewModel; silent?: boolean }
    ) {
      const errorMessage = "Field could not be created!";

      Vue.$log.debug("Fields. Action: createField");

      const fieldDbModel = toFieldDbModel(payload.field);
      fieldDbModel.name = createUUID();

      const response = (await API.graphql(
        graphqlOperation(createField, { input: fieldDbModel })
      )) as GraphQLResult<CreateFieldMutation>;

      if (response.errors) {
        throw new SupError(errorMessage, true, response.errors);
      }
      if (!payload.silent) {
        dispatchUiNotification.info("Created!");
      }

      const result = toFieldViewModel(response.data?.createField);

      context.commit("createField", result);
      return result;
    },

    async updateField(context: ActionContext<any, any>, data: any) {
      const errorMessage = "Field could not be updated!";
      Vue.$log.debug("Fields. Action: updateField", data);
      const response = (await API.graphql(
        graphqlOperation(updateField, { input: toFieldDbModel(data) })
      )) as GraphQLResult<UpdateFieldMutation>;

      if (response.errors) {
        throw new SupError(errorMessage, true, response.errors);
      }

      dispatchUiNotification.info("Updated!");

      Vue.$log.debug("Response", response);

      const result = toFieldViewModel(response.data?.updateField);

      context.commit("updateField", result);
    },

    async deleteField(context: ActionContext<any, any>, data: any) {
      const errorMessage = "Field could not be deleted!";

      const response = (await API.graphql(
        graphqlOperation(deleteField, { input: data })
      )) as GraphQLResult<DeleteFieldMutation>;

      if (response.errors) {
        throw new SupError(errorMessage, true, response.errors);
      }
      dispatchUiNotification.info("Deleted!");

      context.commit("deleteField", data);
    }
  },

  mutations: {
    setFieldList(state: FieldsState, payload: Required<FieldViewModel>[]) {
      state.fieldList = payload;
    },

    setCount(state: FieldsState, payload: number) {
      state.count = payload;
    },

    setFieldDetails(state: FieldsState, payload: any) {
      state.fieldDetails = payload;
    },

    createField(state: FieldsState, payload: any) {
      state.fieldList.push(payload);
    },

    updateField(state: FieldsState, payload: any) {
      const idx = state.fieldList.findIndex((i: any) => i.id === payload.id);
      applyFieldViewModel(payload, state.fieldList[idx]);
    },

    deleteField(state: FieldsState, payload: any) {
      const idx = state.fieldList.findIndex((i: any) => i.id === payload.id);
      state.fieldList.splice(idx, 1);
    }
  },

  getters: {
    fieldList(state: FieldsState) {
      return state.fieldList;
    },
    fieldDict(state: FieldsState): Record<string, Required<FieldViewModel>> {
      const list = (state.fieldList as unknown) as Required<FieldViewModel>[];
      return Object.assign({}, ...list.map(f => ({ [f.id]: f })));
    },
    fieldDictByName(
      state: FieldsState
    ): Record<string, Required<FieldViewModel>> {
      const list = (state.fieldList as unknown) as Required<FieldViewModel>[];
      return Object.assign({}, ...list.map(f => ({ [f.name]: f })));
    },
    fieldListOrdered(state: FieldsState) {
      return state.fieldList.sort((a: FieldViewModel, b: FieldViewModel) => {
        if (a.isDefault == b.isDefault) {
          return a.label.localeCompare(b.label);
        }

        if (a.isDefault) {
          return -1;
        } else {
          return 1;
        }
      });
    },
    systemFields(state: FieldsState) {
      return state.fieldList
        .filter(f => f.isDefault)
        .sort((a, b) => {
          return a.meta.sequence - b.meta.sequence;
        });
    },
    systemFieldsDictByName(
      state: FieldsState,
      getters: any
    ): Record<SystemFieldName, Required<FieldViewModel>> {
      const list = (getters.systemFields as unknown) as Required<
        FieldViewModel
      >[];
      return Object.assign({}, ...list.map(f => ({ [f.name]: f })));
    },
    customFields(state: FieldsState) {
      return state.fieldList
        .filter(f => !f.isDefault)
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        });
    },
    fieldDetails(state: FieldsState) {
      return state.fieldDetails;
    },
    statusField(state: FieldsState, getters: any) {
      return getters.systemFieldsDictByName["status"] as FieldViewModel;
    },
    tagsField(state: FieldsState, getters: any) {
      return getters.systemFieldsDictByName["tags"] as FieldViewModel;
    }
  }
};
