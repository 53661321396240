import { UserViewModel } from "@/models/UserModel";
import Vue from "vue";
import { ActionContext } from "vuex";

import {
  IntegrationListItem,
  InboundIntegrationModel,
  IntegrationType
} from "/@/models/integrations";

import { dispatchUiNotification } from "/@/models/NotificationModel";

import { TeamRepo } from "@/api/auth/repos/TeamRepo";
import { GithubIntegrationRepo } from "/@/api/integrations/repos/GithubIntegrationRepo";
import { GithubIntegration } from "@/api/integrations/models/GithubIntegration";
import { GitlabIntegrationRepo } from "/@/api/integrations/repos/GitlabIntegrationRepo";
import { GitlabIntegration } from "@/api/integrations/models/GitlabIntegration";
import { SupError } from "@/models/Errors";

export interface IntegrationsState {
  integrations: IntegrationListItem[];
  editItem?: InboundIntegrationModel | null;
}

const applyIntegrationViewModel = (
  source: IntegrationListItem,
  target: IntegrationListItem
) => {
  target.name = source.name;
  target.enabled = source.enabled;
  target.forms.length = 0;
  target.forms.push(...source.forms);
};

const toIntegrationListItem = (
  type: IntegrationType,
  source: any
): IntegrationListItem => {
  return {
    type,
    ...source,
    enabled: source.enabled ?? false,
    forms: source.forms ?? []
  };
};

export default {
  namespaced: true,
  state(): IntegrationsState {
    return {
      integrations: [],
      editItem: null
    };
  },

  actions: {
    /**
     * loadList requires user to be authenticated (i.e. auth.authUser successgully performed and user.teamId initialized)
     */
    async loadList(context: ActionContext<any, any>) {
      Vue.$log.debug("Integrations. Action: loadList");

      const user: UserViewModel = context.rootGetters["auth/user"];
      const dbTeam = await TeamRepo.getById(user.teamId);
      if (!dbTeam) {
        throw new SupError("Could not load intergations: team not found", true);
      }

      // FIXME add error handling, use Promise.all / Promise.allSettled
      let githubIntegrations: GithubIntegration[] = [];
      let errorGithub;
      try {
        githubIntegrations = await GithubIntegrationRepo.get();
      } catch (e) {
        errorGithub = e;
      }

      let gitlabIntegrations: GitlabIntegration[] = [];
      let errorGitlab;
      try {
        gitlabIntegrations = await GitlabIntegrationRepo.get();
      } catch (e) {
        errorGitlab = e;
      }

      const zdObj = JSON.parse(dbTeam.iiZendesk as any);
      const zd = toIntegrationListItem("zendesk", {
        name: "Zendesk",
        enabled: zdObj.enabled,
        forms: zdObj.forms
      });

      const gh = githubIntegrations.map(i =>
        toIntegrationListItem("github", i)
      );
      const gl = gitlabIntegrations.map(i =>
        toIntegrationListItem("gitlab", i)
      );

      context.commit("setList", [zd, ...gh, ...gl]);

      if (errorGithub) {
        throw errorGithub;
      }
      if (errorGitlab) {
        throw errorGitlab;
      }
    },
    async loadDetails(
      context: ActionContext<IntegrationsState, any>,
      data: { type: string; id?: string }
    ) {
      Vue.$log.debug("Integrations. Action: loadDetails");

      if (
        !context.state.integrations ||
        context.state.integrations.length == 0
      ) {
        await context.dispatch("loadList");
      }

      const items = context.state.integrations.filter(
        i => i.type == data.type && i.id == data.id
      );

      Vue.$log.debug("Integrations. Action: loadDetails, items", items);

      if (!items || items.length !== 1) {
        // TODO:
        throw new Error();
      }

      const res = JSON.parse(JSON.stringify(items[0]));

      context.commit("setEditItem", res);
    },
    async update(
      context: ActionContext<IntegrationsState, any>,
      integration: InboundIntegrationModel
    ) {
      const user: UserViewModel = context.rootGetters["auth/user"];

      const putData: any = {
        id: user.teamId
      };

      switch (integration.type) {
        case "zendesk": {
          putData["iiZendesk"] = JSON.stringify({
            enabled: integration.enabled,
            forms: integration.forms
          });
          const dbTeam = await TeamRepo.put(putData);

          const zdObj = JSON.parse(dbTeam.iiZendesk as any);
          const zd = toIntegrationListItem("zendesk", {
            name: "Zendesk",
            enabled: zdObj.enabled,
            forms: zdObj.forms
          });

          context.commit("update", zd);
          break;
        }
        default:
          throw new Error("Not supported integration.");
      }

      dispatchUiNotification.info("Updated!");
    },

    async deleteItem(
      context: ActionContext<any, any>,
      item: IntegrationListItem
    ) {
      if (!item.type || !item.id) return;
      if (item.type == "github") {
        await GithubIntegrationRepo.delete(item.id);
        dispatchUiNotification.info("Deleted!");
        context.commit("deleteItem", item);
      }
      if (item.type == "gitlab") {
        await GitlabIntegrationRepo.delete(item.id);
        dispatchUiNotification.info("Deleted!");
        context.commit("deleteItem", item);
      }
    }
  },

  mutations: {
    setList(state: IntegrationsState, payload: any) {
      state.integrations = payload;
    },
    setEditItem(state: IntegrationsState, payload: any) {
      state.editItem = payload;
    },
    update(state: IntegrationsState, payload: IntegrationListItem) {
      const idx = state.integrations?.findIndex(
        (i: any) => i.type === payload.type && i.id === payload.id
      );
      applyIntegrationViewModel(payload, state.integrations[idx]);
    },
    create(state: IntegrationsState, payload: IntegrationListItem) {
      state.integrations.push(payload);
    },
    deleteItem(state: IntegrationsState, payload: IntegrationListItem) {
      const idx = state.integrations?.findIndex(
        (i: any) => i.type === payload.type && i.id === payload.id
      );
      state.integrations.splice(idx, 1);
    }
  },

  getters: {
    list(state: IntegrationsState) {
      return state.integrations;
    },
    editItem(state: IntegrationsState) {
      return state.editItem;
    }
  }
};
