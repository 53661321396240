import { watch } from "@vue/composition-api";

import Theme from "@/core/types/Theme";

import { createSharedComposable, useStorage } from "@vueuse/core";
import useLog from "@/composables/_useLog";

/**
 * useTheme() initializes color theme (light/dark) on UI.
 * If user preference is saved, then it will be used, otherwise light theme is the default one.
 * Returns `theme` ref which can be used to know what current theme is as well as to set theme
 */
const useTheme = createSharedComposable(() => {
  const log = useLog();

  const localStorageThemeKey = "user.preferences.theme";
  const defaultTheme: Theme = "light";

  const theme = useStorage<Theme>(localStorageThemeKey, defaultTheme);
  watch(
    theme,
    (newValue, oldValue) => {
      if (!["light", "dark"].includes(newValue)) {
        theme.value = defaultTheme;
      }
      log.debug(`Theme: ${oldValue} -> ${newValue}`);
      document.documentElement.classList.remove(oldValue ?? defaultTheme);
      document.documentElement.classList.add(newValue);
    },
    { immediate: true }
  );

  // for debug purposes use setTheme right in the browser console
  // but in vue code you should always use the composable way
  window.__debug.setTheme = (newTheme: Theme) => {
    theme.value = newTheme;
  };

  return {
    theme
  };
});

export default useTheme;
