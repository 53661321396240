import { computed } from "@vue/composition-api";
import { useStoreGetters as useAuthGetters } from "/@/store/modules/auth.use";

import { intersection } from "@/helpers/arrays";
import { SupGroup, UserViewModel } from "@/models/UserModel";
import { NavItem, navItems } from "@/core/navigation";

export default function useNavigation() {
  const { user } = useAuthGetters();

  const root = computed(() => {
    return getNavSubtree(
      { label: "root", path: "/", children: navItems },
      user.value?.groups ?? [],
      user.value?.originalGroups ?? [],
      user.value
    ) as NavItem;
  });

  const settings = computed(() => {
    return (root.value.children ?? []).find(i => i.key == "settings");
  });

  return {
    root,
    settings
  };
}

function getNavSubtree(
  root: NavItem,
  userGroups: SupGroup[],
  userOriginalGroups: SupGroup[],
  user: UserViewModel | null
): NavItem | undefined {
  const hasGroupRequirement =
    root.requires?.anyOfGroups || root.requires?.anyOfOriginalGroups;
  const hasRequiredGroup =
    (root.requires?.anyOfOriginalGroups &&
      intersection(userOriginalGroups, root.requires.anyOfOriginalGroups)
        .length > 0) ||
    (root.requires?.anyOfGroups &&
      intersection(userGroups, root.requires.anyOfGroups).length > 0);

  const customUserPredicateResult = root.requires?.customUserPredicate
    ? root.requires.customUserPredicate(user)
    : true;

  if ((!hasGroupRequirement || hasRequiredGroup) && customUserPredicateResult) {
    const rootCopy: NavItem = {
      ...root
    };
    if (root.children) {
      rootCopy.children = root.children.flatMap(
        navItem =>
          getNavSubtree(navItem, userGroups, userOriginalGroups, user) ?? []
      );
    }
    return rootCopy;
  }
  return undefined;
}
