












































import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  setup() {
    const showModal = ref(false);

    onMounted(() => {
      showModal.value = true;
    });

    return {
      showModal
    };
  }
});
