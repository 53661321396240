import { Hub } from "aws-amplify";

export enum NotificationLevelEnum {
  Info,
  Warning,
  Error
}

export interface NotificationModel {
  id?: string;
  level: NotificationLevelEnum;
  message: string;
  refId?: string;
}

function dispatchUiNotificationImpl(data: NotificationModel) {
  const payload = {
    event: "",
    data: { ...data, id: Date.now().toString() },
    message: ""
  };
  Hub.dispatch("s-ui-notifications", payload);
}

export const dispatchUiNotification = {
  info: (message: string) => {
    dispatchUiNotificationImpl({
      level: NotificationLevelEnum.Info,
      message
    });
  },
  warning: (message: string) => {
    dispatchUiNotificationImpl({
      level: NotificationLevelEnum.Warning,
      message
    });
  },
  error: (message: string, refId?: string) => {
    dispatchUiNotificationImpl({
      level: NotificationLevelEnum.Error,
      message,
      refId
    });
  }
};

function saveDelayedUiNotificationImpl(data: NotificationModel) {
  const payload = {
    event: "",
    data: { ...data, id: Date.now().toString() },
    message: ""
  };
  localStorage.setItem("s-ui-notification", JSON.stringify(payload));
}

export const saveDelayedUiNotification = {
  info: (message: string) => {
    saveDelayedUiNotificationImpl({
      level: NotificationLevelEnum.Info,
      message
    });
  },
  error: (message: string) => {
    saveDelayedUiNotificationImpl({
      level: NotificationLevelEnum.Error,
      message
    });
  }
};

export const pullDelayedUiNotification = (): unknown => {
  const item = localStorage.getItem("s-ui-notification");
  const result = item ? JSON.parse(item) : null;
  localStorage.removeItem("s-ui-notification");
  return result;
};

export const listenUiNotifications = (callback: (payload: unknown) => void) => {
  Hub.listen("s-ui-notifications", data => {
    const { payload } = data;
    callback(payload);
  });
};
