import { render, staticRenderFns } from "./m-plan-billing-link.vue?vue&type=template&id=08fc0c54&scoped=true&"
import script from "./m-plan-billing-link.vue?vue&type=script&lang=ts&"
export * from "./m-plan-billing-link.vue?vue&type=script&lang=ts&"
import style0 from "./m-plan-billing-link.vue?vue&type=style&index=0&id=08fc0c54&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08fc0c54",
  null
  
)

export default component.exports