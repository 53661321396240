































import { Options, Vue } from "/@/compatibility/vue-class-component";
import { WizardStep } from "./o-wizard-modal.vue";

const WizardNavBase = Vue.extend({
  props: {
    allStepsAvailable: Boolean,
    steps: {
      type: Array as () => WizardStep[]
    },
    currentStepIndex: {
      type: Number,
      default: 0
    }
  }
});

@Options
export default class WizardNav extends WizardNavBase {}
