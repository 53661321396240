import Vue from "vue";
import { handleSupError, handleSlackApiError } from "@/helpers/errors";
import config from "./config";
import * as Sentry from "@sentry/vue";

// Fix "Vue.config.errorHandler and errorCaptured should capture rejected promised in watchers"
// https://github.com/vuejs/vue/issues/10009
// Ugly monkey patching until https://github.com/vuejs/vue/pull/9484 is merged
const realWatch = Vue.prototype.$watch;
Vue.prototype.$watch = function $watchDetour(
  expOrFn: any,
  callback: any,
  options: any
) {
  if (typeof callback === "function") {
    if (this.$_watchCount === undefined) {
      this.$_watchCount = 0;
    }

    // Reroute $watch to $on which handles promise rejections properly
    const watchDetourEvent = `watchDetour_${this.$_watchCount}`;
    this.$_watchCount += 1;
    const realCallback = callback;
    this.$on(watchDetourEvent, (args: any) => {
      return realCallback.apply(this, args);
    });
    callback = (...args: any[]) => {
      this.$emit(watchDetourEvent, args);
    };
  }

  // realWatch will call back into $watchDetour with a function if callback wasn't one
  return realWatch.call(this, expOrFn, callback, options);
};

Vue.config.errorHandler = globalErrorHandler;

// Here suppose to be the single unhandled vue exceptions point.
export function globalErrorHandler(
  error: Error,
  vueApp?: Vue,
  vueInfo?: string
) {
  // Let's log. Behind logging error there is monitoring integration (see src/startup/vuejs-logger.ts)
  let capturedEventId: string | undefined;
  if (config.USE_SENTRY) {
    capturedEventId = Sentry.captureException(error);
  }
  Vue.$log.error("Vue global error handler.", {
    captureContext: true,
    error,
    vueApp,
    vueInfo,
    capturedEventId
  });

  // Handle errors on UI, without logging:
  handleSupError(error);
  handleSlackApiError(error);
}
