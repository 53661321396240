import Vue from "vue";
// @ts-expect-error
import { ConfigProgrammatic, Checkbox, Table, Sidebar, Collapse } from "buefy";
// @ts-expect-error
import { Dropdown, Skeleton, Radio, Progress, Collapse, Switch } from "buefy";
// @ts-expect-error
import { Breadcrumb, Tabs, Input, Tag } from "buefy";

// See full constructor options at: https://buefy.org/documentation/constructor-options
Vue.use(Checkbox);
Vue.use(Table);
Vue.use(Sidebar);
Vue.use(Collapse);
Vue.use(Dropdown);
Vue.use(Skeleton);
Vue.use(Radio);
Vue.use(Breadcrumb);
Vue.use(Progress);
Vue.use(Switch);
Vue.use(Tabs);
Vue.use(Input);
Vue.use(Tag);
ConfigProgrammatic.setOptions({
  defaultIconComponent: "font-awesome-icon",
  defaultIconPack: "fas"
});
