/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "billing",
            "endpoint": "https://6x1wfnj0pl.execute-api.eu-west-1.amazonaws.com/production",
            "region": "eu-west-1"
        },
        {
            "name": "paddleBillingWebhook",
            "endpoint": "https://ei4z23z7uf.execute-api.eu-west-1.amazonaws.com/production",
            "region": "eu-west-1"
        },
        {
            "name": "suptaskRestApi",
            "endpoint": "https://h3i5ilj7ej.execute-api.eu-west-1.amazonaws.com/production",
            "region": "eu-west-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://nbluovfrover5p6kdgihrzymea.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:fa03a318-77f5-4f33-836c-aa676930a435",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_IxjKvFuRv",
    "aws_user_pools_web_client_id": "41eh9gfgr2vur7b17pkigp2uc1",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "uptask-20210107223957-hostingbucket-production",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d1gy81poubrwv2.cloudfront.net",
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "BillingLog-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "EntityMapping-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "ExternalTicket-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "IntegrationConfiguration-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "IntegrationCredential-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "ReportConfiguration-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "TeamAgentsLog-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "UserNotification-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "WebhookEndpoint-production",
            "region": "eu-west-1"
        },
        {
            "tableName": "Workflow-production",
            "region": "eu-west-1"
        }
    ]
};


export default awsmobile;
