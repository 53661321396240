import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";
import { listFormsByTeamId, getForm } from "/@/graphql/queries";
import {
  createForm,
  updateForm,
  deleteForm,
  createQueue,
  updateQueue,
  deleteQueue
} from "/@/graphql/mutations";
import {
  GetFormQuery,
  CreateFormMutation,
  UpdateFormMutation,
  DeleteFormMutation,
  CreateQueueMutation,
  UpdateQueueMutation
} from "/@/API";

import { listFormsIds } from "/@/graphql/custom-queries";

// Queue API models
import { CreateQueueInput, UpdateQueueInput, DeleteQueueInput } from "/@/API";

import { ActionContext } from "vuex";
import Vue from "vue";

import {
  FormViewModel,
  FormField,
  FormViewModelNew
} from "/@/models/FormModel";
import { allChannelsItem } from "/@/models/ChannelModel";

import { dispatchUiNotification } from "/@/models/NotificationModel";
import { SupError } from "@/models/Errors";

import { fetchList } from "/@/helpers/api.graphql";

import { RootState } from "/@/store";
import { FieldViewModel } from "@/models/FieldModel";
import TeamService from "@/api/auth/services/TeamService";
import { AutoCreateChannels } from "@/api/auth/models/AutoCreateChannels";
import { equal } from "@/helpers/sets";
import {
  INBOX_ID_FORMAT_CHANNEL_ID,
  INBOX_ID_FORMAT_QUEUE_ID,
  InboxIdFormat
} from "@/models/Inbox";

const toFormDbModel = (
  source: FormViewModel,
  legacyFormatQueueId?: string
): any => {
  const getOriginalProperties = (f: FormField) => f.originalProperties ?? {};
  const getChangedProperties = (
    s: FormViewModel,
    f: FormField,
    index: number
  ) => ({
    sequence: index + 1,
    // Never required if "auto-create" form and field visible to Requester
    // Otherwise defined by user input
    required:
      f.field.meta.fieldAlwaysRequired ||
      (!(s.autoCreate && f.field.meta.visibility == "submit") && f.required),
    visibility: f.visibility,
    isVisibleOnSubmit: f.hideFieldForRequester
  });
  const formFieldsDb: any[] = source.formFields.map(
    (f: FormField, index: number) => ({
      id: f.id,
      properties: {
        // take original properties
        ...getOriginalProperties(f),
        // and add/override those that we manage on FE
        ...getChangedProperties(source, f, index)
      }
    })
  );

  const result = {
    id: source.id,
    name: source.name,
    enabledInDm: source.enabledInDm,
    displayChannels: source.requestChannels,
    queueId: legacyFormatQueueId ?? source.queueId,
    // Never hidden if "auto-create" form
    // Otherwise defined by user input
    hidden: !source.autoCreate && source.hiddenInRequestChannels,
    fields: JSON.stringify(formFieldsDb)
  };

  // empty list in the backend means all channels
  if (
    result.displayChannels?.includes(allChannelsItem.id) ||
    result.enabledInDm
  ) {
    result.displayChannels = [];
  }

  Vue.$log.trace("source:", source, "result:", result);

  return result;
};

const toUpdateQueueInput = (
  source: FormViewModel,
  queueId: string
): UpdateQueueInput => {
  const integrationChannels = [
    {
      channelId: source?.responderChannel,
      channelType: "SLACK"
    }
  ];

  return {
    id: queueId,
    integrationChannels: JSON.stringify(integrationChannels),
    name: source.name
  };
};

const toCreateQueueInput = (source: FormViewModel): CreateQueueInput => {
  const integrationChannels = [
    {
      channelId: source?.responderChannel,
      channelType: "SLACK"
    }
  ];

  return {
    integrationChannels: JSON.stringify(integrationChannels),
    // The Queue name is used when filtering in the Slack UI,
    // so we set it ths same as the Form name.
    name: source.name
  };
};

// TODO: extract toFormViewModel and other transform methods into a separate module
export const toFormViewModel = (
  source: any,
  fieldDict: Record<string, FieldViewModel> | null,
  autoCreateChannels?: AutoCreateChannels
): FormViewModel => {
  const formFieldsDb = JSON.parse(source?.fields)
    .filter((f: any) => !!f.id)
    .sort(
      (f1: any, f2: any) => f1.properties.sequence - f2.properties.sequence
    );

  const formFields: FormField[] = formFieldsDb.map((f: any):
    | FormField
    | undefined => {
    return fieldDict && fieldDict[f.id]
      ? {
          id: f.id,
          required: f.properties?.required,
          visibility: f.properties?.visibility,
          hideFieldForRequester: f.properties?.isVisibleOnSubmit,
          field: fieldDict[f.id],
          originalProperties: f.properties
        }
      : undefined;
  });

  //
  const formFieldsClean = formFields.filter(f => f !== undefined);

  Vue.$log.trace("toFormViewModel: formFieldsClean", formFieldsClean);

  const integrationChannelsDb = JSON.parse(source?.queue?.integrationChannels);
  const responderChannel = integrationChannelsDb[0].channelId;

  const inboxIdFormat = source?.queue?.format as InboxIdFormat;
  const queueId =
    inboxIdFormat == INBOX_ID_FORMAT_QUEUE_ID ? source?.queueId : undefined;
  const _legacyQueueId =
    inboxIdFormat == INBOX_ID_FORMAT_CHANNEL_ID ? source?.queueId : undefined;

  const result = {
    id: source?.id,
    name: source?.name,
    enabledInDm: source?.enabledInDm || false,
    requestChannels: source?.displayChannels,
    hiddenInRequestChannels: source?.hidden,
    queueId,
    _legacyQueueId,
    responderChannel,
    formFields: formFieldsClean,
    autoCreate: false,
    email: source?.email ? JSON.parse(source.email) : undefined,
    createdAt: source?.createdAt,
    updatedAt: source?.updatedAt
  };

  if (result.enabledInDm) {
    result.requestChannels = [];
  } else if (!result.requestChannels || result.requestChannels.length === 0) {
    // empty list in the backend means all channels
    result.requestChannels = [allChannelsItem.id];
  }

  if (autoCreateChannels) {
    const autoCreate = Object.entries(autoCreateChannels).some(
      pair => pair[1].formId == result.id
    );
    result.autoCreate = autoCreate;
  }

  Vue.$log.trace("source:", source, "result:", result);

  return result;
};

const applyFormViewModel = (source: FormViewModel, target: FormViewModel) => {
  target.name = source.name;
  target.enabledInDm = source.enabledInDm;
  target.autoCreate = source.autoCreate;
  target.email = source.email;
  target.hiddenInRequestChannels = source.hiddenInRequestChannels;
  target.requestChannels = source.requestChannels;
  target.queueId = source.queueId;
  target.responderChannel = source.responderChannel;
  target.createdAt = source.createdAt;
  target.updatedAt = source.updatedAt;
};

export interface FormsState {
  list: Array<FormViewModel>;
  count: number | null;
  editItem: FormViewModelNew;
}

function getEmptyItem(): FormViewModelNew {
  return {
    name: "",
    enabledInDm: false,
    autoCreate: false,
    requestChannels: [],
    hiddenInRequestChannels: false,
    responderChannel: null,
    formFields: []
  };
}

const FEATURE_AUTO_CREATE_ENABLED = true;

export default {
  namespaced: true,
  state(): FormsState {
    return {
      list: [],
      count: null,
      editItem: getEmptyItem()
    };
  },

  actions: {
    async loadList(context: ActionContext<any, RootState>) {
      Vue.$log.debug("Forms. Action: loadList");

      const [respItems, autoCreateChannels] = await Promise.all([
        fetchList(listFormsByTeamId, "listFormsByTeamId", {
          teamId: context.rootState.auth.user?.teamId
        }),
        FEATURE_AUTO_CREATE_ENABLED
          ? context.rootState.auth.user
            ? TeamService.getAutoCreateChannels(
                context.rootState.auth.user.teamId
              )
            : Promise.resolve({})
          : Promise.resolve({})
      ]);

      const items = respItems.map(i => {
        return toFormViewModel(i, null, autoCreateChannels);
      });

      context.commit("setList", items);
    },

    async loadCount(context: ActionContext<any, any>) {
      Vue.$log.debug("Forms. Action: loadCount");

      const respItems = await fetchList(listFormsIds, "listFormsByTeamId", {
        teamId: context.rootState.auth.user?.teamId
      });
      const length = respItems.length;

      context.commit("setCount", length);
    },

    async loadDetails(context: ActionContext<any, any>, data: any) {
      Vue.$log.debug("Forms. Action: loadDetails");

      await context.dispatch("fields/loadFieldList", null, { root: true });

      const response = (await API.graphql(
        graphqlOperation(getForm, { id: data.id })
      )) as GraphQLResult<GetFormQuery>;

      if (response.errors) {
        // TODO:
        throw new Error();
      }

      Vue.$log.debug("context.rootGetters", context.rootGetters);

      const autoCreateChannels = FEATURE_AUTO_CREATE_ENABLED
        ? await TeamService.getAutoCreateChannels(
            context.rootState.auth.user?.teamId
          )
        : {};

      const formViewModel = toFormViewModel(
        response.data?.getForm,
        context.rootGetters["fields/fieldDict"],
        autoCreateChannels
      );

      context.commit("setEditItem", formViewModel);
    },

    async initEmptyDetails(context: ActionContext<any, any>) {
      context.commit("setEditItem", getEmptyItem());
    },

    async create(
      context: ActionContext<any, RootState>,
      payload: { form: FormViewModel; silent?: boolean }
    ) {
      const errorMessage = "Form could not be created!";

      Vue.$log.debug("Forms. Action: create", payload);

      let legacyFormatQueueId: string | undefined = undefined;
      if (!payload.form.queueId) {
        // Legacy Inbox
        const responseCreateQueue = (await API.graphql(
          graphqlOperation(createQueue, {
            input: toCreateQueueInput(payload.form)
          })
        )) as GraphQLResult<CreateQueueMutation>;

        if (responseCreateQueue.errors) {
          throw new SupError(errorMessage, true, responseCreateQueue.errors);
        }

        Vue.$log.debug("Queue create:", responseCreateQueue.data);

        legacyFormatQueueId = responseCreateQueue.data?.createQueue?.id;
      }

      const formDbModel = toFormDbModel(payload.form, legacyFormatQueueId);

      const response = (await API.graphql(
        graphqlOperation(createForm, { input: formDbModel })
      )) as GraphQLResult<CreateFormMutation>;

      if (response.errors) {
        throw new SupError(errorMessage, true, response.errors);
      }

      const result = toFormViewModel(
        response.data?.createForm,
        context.rootGetters["fields/fieldDict"]
      );

      const queueId = legacyFormatQueueId ?? payload.form.queueId;
      if (
        FEATURE_AUTO_CREATE_ENABLED &&
        payload.form.autoCreate &&
        context.rootState.auth.user &&
        queueId
      ) {
        result.autoCreate = true;
        await TeamService.saveAutoCreateExistingForm(
          context.rootState.auth.user.teamId,
          result,
          queueId
        );
      }

      if (!payload.silent) {
        dispatchUiNotification.info("Created!");
      }

      context.commit("create", result);
      context.commit("setEditItem", result);
    },

    async update(context: ActionContext<any, RootState>, form: FormViewModel) {
      if (!form.queueId && !form._legacyQueueId) {
        throw new SupError(
          "Form could not be assigned to an old format Inbox!",
          true
        );
      }

      const errorMessage = "Form could not be updated!";

      Vue.$log.debug("Forms. Action: update", form);

      const response = (await API.graphql(
        graphqlOperation(updateForm, {
          input: toFormDbModel(form, form._legacyQueueId)
        })
      )) as GraphQLResult<UpdateFormMutation>;

      if (response.errors) {
        throw new SupError(errorMessage, true, response.errors);
      }

      Vue.$log.debug("Response", response);

      if (response.data?.updateForm?.queueId) {
        if (!form.queueId) {
          // Legacy Inbox, same queue will keep queue id but change integration channel property
          const responseUpdateQueue = (await API.graphql(
            graphqlOperation(updateQueue, {
              input: toUpdateQueueInput(
                form,
                response.data?.updateForm?.queueId
              )
            })
          )) as GraphQLResult<UpdateQueueMutation>;

          if (responseUpdateQueue.errors) {
            throw new SupError(errorMessage, true, response.errors);
          }
        }
      }

      // read again after updated
      const responseGet = (await API.graphql(
        graphqlOperation(getForm, { id: form.id })
      )) as GraphQLResult<GetFormQuery>;

      if (responseGet.errors) {
        throw new SupError(errorMessage, true, responseGet.errors);
      }

      const result = toFormViewModel(
        responseGet.data?.getForm,
        context.rootGetters["fields/fieldDict"]
      );

      if (FEATURE_AUTO_CREATE_ENABLED) {
        result.autoCreate = form.autoCreate;

        const originalItem = (context.getters["list"] as FormViewModel[]).find(
          f => f.id == form.id
        );
        if (!originalItem) {
          throw new SupError(
            "Could not save auto-create Form info: original Form not found"
          );
        }
        const formTypeChanged = form.autoCreate != originalItem.autoCreate;
        const autoCreateQueueChanged =
          form.autoCreate && form.queueId != originalItem.queueId;
        const autoCreateChannelsChanged =
          form.autoCreate &&
          !equal(
            new Set(form.requestChannels),
            new Set(originalItem.requestChannels)
          );

        if (
          (formTypeChanged ||
            autoCreateQueueChanged ||
            autoCreateChannelsChanged) &&
          context.rootState.auth.user &&
          responseGet.data?.getForm?.queueId
        ) {
          await TeamService.saveAutoCreateExistingForm(
            context.rootState.auth.user.teamId,
            result,
            responseGet.data?.getForm?.queueId
          );
        }
      }

      dispatchUiNotification.info("Updated!");
      context.commit("update", result);
      context.commit("setEditItem", result);
    },

    async delete(context: ActionContext<any, RootState>, form: FormViewModel) {
      const errorMessage = "Form could not be deleted!";
      const response = (await API.graphql(
        graphqlOperation(deleteForm, { input: { id: form.id } })
      )) as GraphQLResult<DeleteFormMutation>;

      if (response.errors) {
        throw new SupError(errorMessage, true, response.errors);
      }
      if (!response.data?.deleteForm?.queueId) {
        throw new SupError(errorMessage, false);
      }

      if (!form.queueId) {
        // Legacy Inbox, unique queue per each form, so delete queue
        const deleteQueueInput: DeleteQueueInput = {
          id: response.data?.deleteForm?.queueId
        };
        const deleteQueueResponse = (await API.graphql(
          graphqlOperation(deleteQueue, { input: deleteQueueInput })
        )) as GraphQLResult<DeleteFormMutation>;

        if (deleteQueueResponse.errors) {
          throw new SupError(errorMessage, true, deleteQueueResponse.errors);
        }
      }

      if (FEATURE_AUTO_CREATE_ENABLED) {
        const originalItem = (context.getters["list"] as FormViewModel[]).find(
          f => f.id == form.id
        );
        if (!originalItem) {
          throw new SupError(
            "Could not delete auto-create form info: original form not found"
          );
        }
        if (originalItem.autoCreate && context.rootState.auth.user) {
          await TeamService.saveAutoCreateDeletedForm(
            context.rootState.auth.user.teamId,
            form.id
          );
        }
      }

      dispatchUiNotification.info("Deleted!");
      context.commit("delete", form);
    },

    async swapFormFields(
      context: ActionContext<any, any>,
      payload: { item1: FormField; item2shift: -1 | 1 }
    ) {
      context.commit("swapFormFields", payload);
    },

    async assignFormFields(
      context: ActionContext<any, any>,
      payload: FormField[]
    ) {
      context.commit("assignFormFields", payload);
    }
  },

  mutations: {
    setList(state: FormsState, payload: any) {
      state.list = payload;
    },

    setCount(state: FormsState, payload: number) {
      state.count = payload;
    },

    setEditItem(state: FormsState, payload: any) {
      state.editItem = payload;
    },

    create(state: FormsState, payload: any) {
      state.list.push(payload);
    },

    update(state: FormsState, payload: any) {
      const idx = state.list.findIndex((i: any) => i.id === payload.id);
      applyFormViewModel(payload, state.list[idx]);
    },

    delete(state: FormsState, payload: any) {
      const idx = state.list.findIndex((i: any) => i.id === payload.id);
      state.list.splice(idx, 1);
    },

    swapFormFields(
      state: FormsState,
      payload: { item1: FormField; item2shift: -1 | 1 }
    ) {
      if (payload.item2shift !== -1 && payload.item2shift !== 1) return;
      const ff = state.editItem.formFields;
      const item1index = ff.indexOf(payload.item1);
      if (item1index == -1) return;
      const item2index = item1index + payload.item2shift;
      if (item2index > -1 && item2index < ff.length) {
        if (payload.item2shift == 1) {
          // swap forward
          ff.splice(item1index, 2, ff[item2index], ff[item1index]);
        } else {
          // swap backward
          ff.splice(item2index, 2, ff[item1index], ff[item2index]);
        }
      }
    },

    assignFormFields(state: FormsState, payload: FormField[]) {
      state.editItem.formFields = payload;
    }
  },

  getters: {
    list(state: FormsState) {
      return state.list;
    },
    count(state: FormsState) {
      return state.count;
    },
    editItem(state: FormsState) {
      Vue.$log.debug(state.editItem);
      return state.editItem;
    }
  }
};
