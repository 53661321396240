






import { defineComponent, computed } from "@vue/composition-api";

export default defineComponent({
  name: "round-icon",
  props: {
    iconType: { type: String, default: "" },
    bgColor: { type: String, default: "transparent" }
  },
  setup(props) {
    const styles = computed(() => ({
      "--bg-color": props.bgColor
    }));

    return { styles };
  }
});
