export const listFormsIds = /* GraphQL */ `
  query ListFormsIds(
    $teamId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFormsByTeamId(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;

export const listFieldsIds = /* GraphQL */ `
  query ListFieldsIds(
    $teamId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelFieldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFieldsByTeamId(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
      }
      nextToken
    }
  }
`;

export const listPublicPlanSubscriptions = /* GraphQL */ `
  query listPublicPlanSubscriptions($limit: Int, $nextToken: String) {
    listPlanSubscriptions(
      filter: { public: { eq: true } }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        planId
        plan {
          id
          name
          formsLimit
          customFieldsLimit
          code
          createdAt
          updatedAt
        }
        internalDescription
        billingPeriod
        billingPeriodPriceUsd
        billingPeriodPriceLabel
        isCustom
        isFree
        minAgents
        public
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
