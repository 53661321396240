import { Vue } from "/@/compatibility/vue-class-component";

export const AButtonProps = Vue.extend({
  props: {
    type: {
      type: String,
      default: "button"
    },
    disabled: Boolean,
    loading: Boolean,
    primary: Boolean,
    danger: Boolean,
    transparent: Boolean,
    rounded: Boolean,
    "fa-icon": String,
    "fa-icon-right": Boolean,
    "img-src": String,
    to: String,
    target: { type: String as () => "_self" | "_blank", default: "_self" }
  }
});
