import { extend } from "vee-validate";
import { required, max, integer } from "vee-validate/dist/rules";
import { intersection } from "@/helpers/arrays";
import { getAlphanumericCount } from "@/helpers/string";

extend("required", {
  ...required,
  message: "This field is required"
});

extend("max_length", {
  validate(value, { length }) {
    return value.length <= length;
  },
  params: ["length"],
  message: "Max number of items in this list is {length}"
});

extend("max_str_length", {
  validate(value, { length }) {
    return value.length <= length;
  },
  params: ["length"],
  message: "Max length of this string is {length}"
});

extend("min_alphanumeric", {
  validate(value, { length }) {
    return getAlphanumericCount(value) >= length;
  },
  params: ["length"],
  message: "Must contain at least {length} alphanumeric character"
});

extend("not_one_of", {
  validate(value, { target }: any) {
    return !target?.includes(value);
  },
  params: ["target"],
  message: "{_field_} cannot be one of the {target}"
});

extend("does_not_contain", {
  validate(value, { target }: any) {
    return !value.includes(target);
  },
  params: ["target"],
  message: "{_field_} cannot contain the {target}"
});

extend("does_not_intersect", {
  validate(value, { target }: any) {
    return intersection(value, target).length == 0;
  },
  params: ["target"],
  message: "{_field_} cannot contain the {target}"
});

extend("saving_required", {
  validate(value) {
    return typeof value.id === "string" && value.id != "";
  },
  message: "Not saved"
});

extend("max", {
  ...max,
  message: "Max length is {length}"
});

extend("integer", {
  ...integer,
  message: "This field must be an integer"
});

extend("alphanumeric_with_delimiters", {
  validate(value) {
    const validFormat = /^[a-zA-Z0-9-_ ]*$/;
    if (!validFormat.test(value)) {
      return false;
    }

    return true;
  },
  message:
    "This field can only contain latin letters, numbers, hyphens, underscores, and spaces"
});
