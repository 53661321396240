import { RouteConfig } from "vue-router";

import MainLayout from "/@/views/_layout/Main.vue";
import NoMenuLayout from "/@/views/_layout/NoMenu.vue";
import BlankLayout from "/@/views/_layout/Blank.vue";

import { adminGroups, UserViewModel } from "@/models/UserModel";
import { toQueueIdAndChannelId } from "@/models/Inbox";
import config from "/@/startup/config";
import { intersection } from "@/helpers/arrays";
import { getIsUserAnAgent } from "@/composables/useUser";

const getStartedRoute: RouteConfig = {
  path: "/",
  component: NoMenuLayout,
  children: [
    {
      path: "/get-started",
      component: BlankLayout,
      props: { height: "fit-content" },
      children: [
        {
          path: "",
          component: () =>
            import(
              /* webpackChunkName: "get-started" */ "/@/views/GetStarted/GetStarted.vue"
            )
        },
        {
          path: "/get-started/wizard",
          name: "get-started.wizard",
          components: {
            default: () =>
              import(
                /* webpackChunkName: "get-started" */ "/@/views/GetStarted/GetStarted.vue"
              ),
            dialog: () =>
              import(
                /* webpackChunkName: "get-started" */ "/@/views/GetStarted/Wizard.vue"
              )
          },
          props: {
            default: () => ({
              hideSignOut: true
            }),
            dialog: route => ({
              mode: "getStarted",
              code: route.query.code,
              existingContextUid: route.query.context
            })
          }
        },
        {
          path: "/get-started/tips",
          name: "get-started.tips",
          components: {
            default: () =>
              import(
                /* webpackChunkName: "get-started" */ "/@/views/GetStarted/GetStarted.vue"
              ),
            dialog: () =>
              import(
                /* webpackChunkName: "get-started" */ "/@/views/GetStarted/GetStartedTips.vue"
              )
          },
          props: {
            dialog: route => ({
              pushed: route.params.HIDDEN_PUSHED === "true"
            })
          }
        }
      ]
    }
  ],
  meta: {
    requiresAuth: true,
    requiresPermissions: {
      anyOfGroups: adminGroups.accountAdmins
    }
  }
};

const adminRoute: RouteConfig = {
  path: "/",
  component: MainLayout,
  children: [
    {
      path: "/theme",
      component: BlankLayout,
      children: [
        {
          path: "",
          component: () =>
            import(
              /* webpackChunkName: "theme" */ "/@/views/Theme/Typography.vue"
            )
        },
        {
          path: "buttons",
          component: () =>
            import(/* webpackChunkName: "theme" */ "/@/views/Theme/Buttons.vue")
        },
        {
          path: "form",
          component: () =>
            import(/* webpackChunkName: "theme" */ "/@/views/Theme/Form.vue")
        },
        {
          path: "misc",
          component: () =>
            import(/* webpackChunkName: "theme" */ "/@/views/Theme/Misc.vue")
        },
        {
          path: "dialog",
          component: BlankLayout,
          children: [
            {
              path: "",
              components: {
                default: () =>
                  import(
                    /* webpackChunkName: "theme" */ "/@/views/Theme/Dialog.vue"
                  )
              }
            },
            {
              path: "modal",
              components: {
                default: () =>
                  import(
                    /* webpackChunkName: "theme" */ "/@/views/Theme/Dialog.vue"
                  ),
                dialog: () =>
                  import(
                    /* webpackChunkName: "theme" */ "/@/views/Theme/DialogModal.vue"
                  )
              }
            },
            {
              path: "modal-wizard",
              components: {
                default: () =>
                  import(
                    /* webpackChunkName: "theme" */ "/@/views/Theme/Dialog.vue"
                  ),
                dialog: () =>
                  import(
                    /* webpackChunkName: "theme" */ "/@/views/Theme/DialogModalWizard.vue"
                  )
              }
            },
            {
              path: "modal-wizard-fs",
              components: {
                default: () =>
                  import(
                    /* webpackChunkName: "theme" */ "/@/views/Theme/Dialog.vue"
                  ),
                dialog: () =>
                  import(
                    /* webpackChunkName: "theme" */ "/@/views/Theme/DialogModalWizardFs.vue"
                  )
              }
            }
          ]
        },
        {
          path: "table",
          component: () =>
            import(/* webpackChunkName: "theme" */ "/@/views/Theme/Table.vue")
        },
        {
          path: "full",
          component: () =>
            import(/* webpackChunkName: "theme" */ "/@/views/Theme/Theme.vue")
        }
      ]
    },
    {
      path: "/reports",
      component: BlankLayout,
      children: [
        {
          path: "",
          redirect: "/account/reports"
        },
        {
          path: "/reports/edit/:id",
          components: {
            default: () =>
              import(
                /* webpackChunkName: "reports" */ "/@/views/Reports/Reports.vue"
              ),
            dialog: () =>
              import(
                /* webpackChunkName: "reports" */ "/@/views/Reports/EditReport.vue"
              )
          },
          props: {
            dialog: true
          }
        }
      ]
    },
    {
      path: "/account",
      component: BlankLayout,
      children: [
        {
          path: "",
          redirect: "admins"
        },
        {
          path: "billing",
          component: () =>
            config.BILLING_USE_PAGE_V1
              ? import("/@/views/Settings/Billing.v1.vue")
              : import("/@/views/Settings/Billing.vue"),
          meta: {
            requiresPermissions: {
              anyOfOriginalGroups: adminGroups.billingAdmins
            }
          }
        },
        {
          path: "admins",
          redirect: "users"
        },
        {
          path: "users",
          name: "account.users",
          component: () =>
            import(/* webpackChunkName: "settings" */ "@/views/Users/Users.vue")
        },
        {
          path: "reports",
          component: () =>
            import(
              /* webpackChunkName: "settings" */ "/@/views/Reports/Reports.vue"
            )
        },
        {
          path: "oldintegrations",
          component: () =>
            import(
              /* webpackChunkName: "settings" */ "/@/views/Settings/Integrations.vue"
            )
        },
        {
          path: "integrations",
          component: () =>
            import(
              /* webpackChunkName: "settings" */ "/@/views/Settings/NewIntegrations.vue"
            )
        },
        {
          path: "oldintegrations/edit/:type/:id?",
          components: {
            default: () =>
              import(
                /* webpackChunkName: "settings" */ "/@/views/Settings/Integrations.vue"
              ),
            dialog: () =>
              import(
                /* webpackChunkName: "settings" */ "/@/views/Integrations/EditIntegration.vue"
              )
          },
          props: {
            dialog: true
          }
        },
        {
          path: "settings",
          component: () =>
            import(
              /* webpackChunkName: "settings" */ "/@/views/AccountSettings/AccountSettings.vue"
            )
        }
      ]
    },
    {
      path: "/inboxes",
      component: BlankLayout,
      children: [
        {
          path: "",
          name: "inboxes",
          component: () => import("/@/views/Inboxes/Inboxes.vue")
        },
        {
          path: "/inboxes/new",
          name: "inboxes.new",
          components: {
            default: () => import("/@/views/Inboxes/Inboxes.vue"),
            dialog: () => import("/@/views/GetStarted/Wizard.vue")
          },
          props: {
            default: () => ({
              hideSignOut: true
            }),
            dialog: route => ({
              mode: "inbox",
              code: route.query.code,
              existingContextUid: route.query.context
            })
          }
        },
        {
          path: "/inboxes/:inboxKey/forms/edit",
          components: {
            default: () => import("/@/views/Settings/Forms.vue"),
            dialog: () => import("/@/views/Settings/FormEdit.vue")
          },
          props: {
            default: route => {
              const { queueId, responderChannelId } = toQueueIdAndChannelId(
                route.params.inboxKey
              );
              return {
                queueId,
                responderChannelId
              };
            },
            dialog: route => {
              const { queueId, responderChannelId } = toQueueIdAndChannelId(
                route.params.inboxKey
              );
              return {
                queueId,
                responderChannelId
              };
            }
          }
        },
        {
          // "Hidden" mode to show all forms, not exposed on UI but can be useful for testinng
          path: "/inboxes/forms",
          component: () => import("/@/views/Settings/Forms.vue")
        },
        {
          path: "/inboxes/:inboxKey/forms/inbox-edit",
          components: {
            default: () => import("/@/views/Settings/Forms.vue"),
            dialog: () => import("/@/views/Inboxes/EditInbox.vue")
          },
          props: {
            default: route => {
              const { queueId, responderChannelId } = toQueueIdAndChannelId(
                route.params.inboxKey
              );
              return {
                queueId,
                responderChannelId
              };
            },
            dialog: route => {
              const { queueId, responderChannelId } = toQueueIdAndChannelId(
                route.params.inboxKey
              );
              return {
                mode: "edit",
                queueId,
                responderChannelId,
                returnPath: "./"
              };
            }
          }
        },
        {
          path: "/inboxes/:inboxKey/forms",
          component: () => import("/@/views/Settings/Forms.vue"),
          props: route => {
            const { queueId, responderChannelId } = toQueueIdAndChannelId(
              route.params.inboxKey
            );
            return {
              queueId,
              responderChannelId,
              referer: route.query.referer
            };
          }
        },
        {
          path: "/inboxes/:inboxKey/forms/edit/:id",
          components: {
            default: () => import("/@/views/Settings/Forms.vue"),
            dialog: () => import("/@/views/Settings/FormEdit.vue")
          },
          props: {
            default: route => {
              const { queueId, responderChannelId } = toQueueIdAndChannelId(
                route.params.inboxKey
              );
              return {
                queueId,
                responderChannelId,
                id: route.params.id
              };
            },
            dialog: route => {
              const { queueId, responderChannelId } = toQueueIdAndChannelId(
                route.params.inboxKey
              );
              return {
                queueId,
                responderChannelId,
                id: route.params.id
              };
            }
          }
        },

        // Mind order! If `/inboxes/fields/edit` is specified after `/inboxes/:inboxKey/edit` then it will be confused
        {
          path: "/inboxes/fields/edit",
          components: {
            default: () => import("/@/views/Settings/Fields.vue"),
            dialog: () => import("/@/views/Settings/FieldEdit.vue")
          },
          props: {
            default: true,
            dialog: true
          }
        },
        {
          path: "/inboxes/fields",
          component: () => import("/@/views/Settings/Fields.vue")
        },
        {
          path: "/inboxes/fields/edit/:id",
          components: {
            default: () => import("/@/views/Settings/Fields.vue"),
            dialog: () => import("/@/views/Settings/FieldEdit.vue")
          },
          props: {
            default: true,
            dialog: true
          }
        },
        {
          path: "/inboxes/:inboxKey/view",
          components: {
            default: () => import("/@/views/Inboxes/Inboxes.vue"),
            dialog: () => import("/@/views/Inboxes/EditInbox.vue")
          },
          props: {
            dialog: route => {
              const { queueId, responderChannelId } = toQueueIdAndChannelId(
                route.params.inboxKey
              );
              return {
                mode: "view",
                queueId,
                responderChannelId,
                returnPath: "../"
              };
            }
          }
        },

        {
          path: "/inboxes/:inboxKey/edit",
          components: {
            default: () => import("/@/views/Inboxes/Inboxes.vue"),
            dialog: () => import("/@/views/Inboxes/EditInbox.vue")
          },
          props: {
            dialog: route => {
              const { queueId, responderChannelId } = toQueueIdAndChannelId(
                route.params.inboxKey
              );
              return {
                mode: "edit",
                queueId,
                responderChannelId,
                returnPath: "../"
              };
            }
          }
        },

        {
          path: "/inboxes/:inboxKey/fields/edit",
          components: {
            default: () => import("/@/views/Settings/Fields.vue"),
            dialog: () => import("/@/views/Settings/FieldEdit.vue")
          },
          props: {
            default: true,
            dialog: true
          }
        },
        {
          path: "/inboxes/:inboxKey/fields",
          component: () => import("/@/views/Settings/Fields.vue"),
          props: true
        },
        {
          path: "/inboxes/:inboxKey/fields/edit/:id",
          components: {
            default: () => import("/@/views/Settings/Fields.vue"),
            dialog: () => import("/@/views/Settings/FieldEdit.vue")
          },
          props: {
            default: true,
            dialog: true
          }
        }
      ]
    },
    {
      path: "/automations",
      component: BlankLayout,
      children: [
        {
          path: "",
          component: () =>
            import(
              /* webpackChunkName: "settings" */ "/@/views/Automations/Automations.vue"
            )
        }
      ]
    }
  ],
  meta: {
    requiresAuth: true,
    requiresPermissions: {
      anyOfGroups: adminGroups.accountAdmins
    }
  }
};

const adminOrAgentRoute: RouteConfig = {
  path: "/",
  component: MainLayout,
  children: [
    {
      path: "/dashboard",
      component: BlankLayout,
      children: [
        {
          path: "",
          component: () =>
            import(
              /* webpackChunkName: "dashboard" */ "/@/views/Dashboard/Dashboard.vue"
            )
        }
      ]
    }
  ],
  meta: {
    requiresAuth: true,
    requiresPermissions: {
      customUserPredicate: (user: UserViewModel | null) =>
        !!user &&
        (intersection(user.groups, adminGroups.accountAdmins).length > 0 ||
          getIsUserAnAgent(user, user.team))
    }
  }
};

export default [getStartedRoute, adminRoute, adminOrAgentRoute];
