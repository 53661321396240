








import { computed, defineComponent, inject } from "@vue/composition-api";
import { TAGS_LOOKUP_KEY } from "../Tickets.inject";

export default defineComponent({
  name: "ticket-tag",
  props: {
    value: { type: String, required: false }
  },
  setup(props) {
    const tagsLookup = inject(TAGS_LOOKUP_KEY);
    const label = computed(
      () => tagsLookup?.value.find(l => l.id == props.value)?.label
    );

    return {
      label
    };
  }
});
