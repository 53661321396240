import { getCurrentInstance } from "@vue/composition-api";

export default function useLog() {
  const vm = getCurrentInstance();
  if (!vm) throw new Error("useLog must be called in setup() method");

  const originalLog = vm.proxy.$log;
  const componentName = vm.proxy.$options.name;

  function getArgs(args: any[]) {
    args.unshift({ composition: true, componentName });
    return args;
  }

  return {
    trace: (...args: any[]) => {
      originalLog.trace(...getArgs(args));
    },
    debug: (...args: any[]) => {
      originalLog.debug(...getArgs(args));
    },
    info: (...args: any[]) => {
      originalLog.info(...getArgs(args));
    },
    warn: (...args: any[]) => {
      originalLog.warn(...getArgs(args));
    },
    error: (...args: any[]) => {
      originalLog.error(...getArgs(args));
    },
    fatal: (...args: any[]) => {
      originalLog.fatal(...getArgs(args));
    }
  };
}
