export enum UiTypeViewEnum {
  TextInput = 0,
  TextInputMultiline = 1,
  Select = 2,
  SelectMulti = 3,
  User = 4,
  UserMulti = 5,
  Date = 6,
  Time = 7,
  DateAndTime = 8,
  FileInput = 9,
  StaticText = 10,
  Agent = 11
}

export type UiTypeDb =
  | "select"
  | "textarea"
  | "date"
  | "time"
  | "datetime"
  | "fileinput"
  | "mrkdwn";

export const uiTypeViewEnumToUiTypeDb: Record<UiTypeViewEnum, UiTypeDb> = {
  [UiTypeViewEnum.TextInput]: "textarea",
  [UiTypeViewEnum.TextInputMultiline]: "textarea",
  [UiTypeViewEnum.Select]: "select",
  [UiTypeViewEnum.SelectMulti]: "select",
  [UiTypeViewEnum.User]: "select",
  [UiTypeViewEnum.UserMulti]: "select",
  [UiTypeViewEnum.Date]: "date",
  [UiTypeViewEnum.Time]: "time",
  [UiTypeViewEnum.DateAndTime]: "datetime",
  [UiTypeViewEnum.FileInput]: "fileinput",
  [UiTypeViewEnum.StaticText]: "mrkdwn",
  [UiTypeViewEnum.Agent]: "select"
};

export const multiValueUiTypes = [
  UiTypeViewEnum.SelectMulti,
  UiTypeViewEnum.UserMulti
];

export function isMultiValueUiType(uiType: UiTypeViewEnum) {
  return multiValueUiTypes.includes(uiType);
}

export interface LabelValueItem {
  label: string;
  value: string;
}

export interface FieldViewModel {
  id?: string;
  name: string;
  label: string;
  uiType: UiTypeViewEnum;
  values: LabelValueItem[];
  value?: string;
  createdAt?: string;
  updatedAt?: string;
  isDefault: boolean;
  meta: Readonly<FieldMeta>;
  ui: Readonly<FormFieldUi>;
}

export const uiTypeFriendlyNames = new Map([
  [UiTypeViewEnum.TextInput, "Single-line text"],
  [UiTypeViewEnum.TextInputMultiline, "Multi-line text"],
  [UiTypeViewEnum.Select, "Single-selection list"],
  [UiTypeViewEnum.SelectMulti, "Multi-selection list"],
  [UiTypeViewEnum.User, "Single Slack user"],
  [UiTypeViewEnum.UserMulti, "Multi Slack user(s)"],
  [UiTypeViewEnum.Date, "Date"],
  [UiTypeViewEnum.Time, "Time"],
  [UiTypeViewEnum.DateAndTime, "Date and time"],
  [UiTypeViewEnum.FileInput, "File attachment(s)"],
  [UiTypeViewEnum.StaticText, "Static text"],
  [UiTypeViewEnum.Agent, "Single Agent"]
]);

/**
 * Field visibility:
 * agent - visible only to agents (responders)
 * submit - agent visiblity + submit form (requesting user)
 */
export type FieldVisibility = "agent" | "submit";
/**
 * Field metadata:
 *   - Fild Management properties in `manageField`
 *   - all other fields are FormField properties (i.e. when Field is being used in Form)
 */
interface FieldMeta {
  sequence: number;
  visibility: FieldVisibility;
  visibilityNotEditable?: boolean;
  /**
   * Is field mandatory on the form.
   * If true then user can't unselect the field on the form.
   * If false, then user can select/unselect the field on the form.
   */
  fieldMandatory: boolean;
  /**
   * Is field always required.
   * If true then field always required if added on the form.
   * If false, then user can decide if value is required or not.
   */
  fieldAlwaysRequired: boolean;
  /**
   * Default properties used on the form init
   */
  defaults: {
    /**
     * If fieldMandatory = true, then fieldSelected = true
     */
    fieldSelected: boolean;
    valueRequired: boolean;
  };

  /**
   * Fild Management properties
   */
  manageField?: {
    /**
     * Disable edit values when editing field
     */
    selectValuesEditDisabled?: boolean;
    /**
     * Automatically sort select values alphabetically (if field is a select)
     */
    selectValuesSortOnEdit?: boolean;
  };
}

export type SystemFieldName =
  | "priority"
  | "status"
  | "assignee"
  | "description"
  | "organization"
  | "tags";

export const systemFieldsMeta: Record<SystemFieldName, FieldMeta> = {
  priority: {
    sequence: 3,
    visibility: "agent",
    fieldMandatory: true,
    fieldAlwaysRequired: false,
    defaults: {
      fieldSelected: true,
      valueRequired: false
    },
    manageField: {
      selectValuesEditDisabled: true
    }
  },
  status: {
    sequence: 2,
    visibility: "agent",
    visibilityNotEditable: true,
    fieldMandatory: true,
    fieldAlwaysRequired: true,
    defaults: {
      fieldSelected: true,
      valueRequired: true
    },
    manageField: {
      selectValuesSortOnEdit: false
    }
  },
  assignee: {
    sequence: 1,
    visibility: "agent",
    fieldMandatory: true,
    fieldAlwaysRequired: true,
    defaults: {
      fieldSelected: true,
      valueRequired: true
    }
  },
  description: {
    sequence: 5,
    visibility: "submit",
    visibilityNotEditable: true,
    fieldMandatory: true,
    fieldAlwaysRequired: true,
    defaults: {
      fieldSelected: true,
      valueRequired: true
    }
  },
  organization: {
    sequence: 6,
    visibility: "submit",
    fieldMandatory: false,
    fieldAlwaysRequired: false,
    defaults: {
      fieldSelected: true,
      valueRequired: false
    },
    manageField: {
      selectValuesSortOnEdit: true
    }
  },
  tags: {
    sequence: 4,
    visibility: "agent",
    fieldMandatory: false,
    fieldAlwaysRequired: false,
    defaults: {
      fieldSelected: true,
      valueRequired: false
    },
    manageField: {
      selectValuesSortOnEdit: true
    }
  }
};

export const customFieldMeta: FieldMeta = {
  sequence: Number.MAX_SAFE_INTEGER,
  visibility: "submit",
  fieldMandatory: false,
  fieldAlwaysRequired: false,
  defaults: {
    fieldSelected: false,
    valueRequired: false
  },
  manageField: {
    selectValuesSortOnEdit: true
  }
};

interface FormFieldUi {
  canEdit: boolean;
}

export const systemFieldsUi: Record<SystemFieldName, FormFieldUi> = {
  priority: {
    canEdit: true
  },
  status: {
    canEdit: true
  },
  assignee: {
    canEdit: true
  },
  description: {
    canEdit: true
  },
  organization: {
    canEdit: true
  },
  tags: {
    canEdit: true
  }
};

export const customFieldUi: FormFieldUi = {
  canEdit: true
};
