<template>
  <component v-if="currentPlan" :is="tag">
    <slot name="common"></slot>
    <template v-if="code == 'p_free'">
      <slot name="free"></slot>
    </template>
    <template v-if="code == 'p_light'">
      <slot name="light"></slot>
    </template>
    <template v-if="code >= PlanCodeEnum.p_light">
      <slot name="light-or-greater"></slot>
    </template>
    <template v-if="code == 'p_basic'">
      <slot name="basic"></slot>
    </template>
    <template v-if="code >= PlanCodeEnum.p_basic">
      <slot name="basic-or-greater"></slot>
    </template>
    <template v-if="code == 'p_custom'">
      <slot name="custom"></slot>
    </template>
  </component>
</template>

<script>
import { computed, defineComponent } from "@vue/composition-api";
import { useStoreGetters as useBillingGetters } from "/@/store/modules/Billing.use";
import { PlanCodeEnum } from "/@/models/BillingModel";

export default defineComponent({
  name: "a-plan-switch",
  props: {
    tag: {
      type: String,
      default: "div"
    }
  },
  setup() {
    const { currentPlan } = useBillingGetters();
    const code = computed(() => {
      return currentPlan.value.plan.code;
    });

    return {
      currentPlan,
      code,
      PlanCodeEnum
    };
  }
});
</script>
