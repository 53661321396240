import Vue from "vue";
import { Route, RouteMeta, RouteRecord } from "vue-router";

import store from "/@/store";

import { UserViewModel } from "@/models/UserModel";
import { intersection } from "@/helpers/arrays";

export default async function requiresPermissions(
  to: Route,
  from: Route,
  next: Function
): Promise<boolean> {
  Vue.$log.info("Router requiresGroupGuard: start");

  const requiresPermissions = getClosestPermissionsRequirement(to.matched);
  if (requiresPermissions) {
    const user = store.getters["auth/user"] as UserViewModel;
    if (user) {
      const meetsOriginalGroupsRequirements = requiresPermissions?.anyOfOriginalGroups
        ? intersection(
            requiresPermissions.anyOfOriginalGroups,
            user.originalGroups
          ).length > 0
        : true;

      const meetsGroupsRequirements = requiresPermissions?.anyOfGroups
        ? intersection(requiresPermissions.anyOfGroups, user.groups).length > 0
        : true;

      const meetsCustomUserPredicate = requiresPermissions?.customUserPredicate
        ? requiresPermissions.customUserPredicate(user)
        : true;

      if (
        meetsOriginalGroupsRequirements &&
        meetsGroupsRequirements &&
        meetsCustomUserPredicate
      ) {
        next();
      } else {
        Vue.$log.info(
          "Membership in required groups is missing. Redirected to access-denied page."
        );
        next({
          name: "access-denied"
        });
      }
    } else {
      Vue.$log.info("Redirected to login page.");
      const root = to.matched[0];
      next({
        name: root.meta.login ?? "login"
      });
    }
    Vue.$log.info("Router requiresGroupGuard: finish true");
    return true;
  }
  Vue.$log.info("Router requiresGroupGuard: finish: false");
  return false;
}

function getClosestPermissionsRequirement(routeRecords: RouteRecord[]) {
  for (let i = routeRecords.length - 1; i >= 0; i--) {
    const meta = routeRecords[i].meta as RouteMeta | undefined;
    if (meta?.requiresPermissions) {
      return meta.requiresPermissions;
    }
  }
  return undefined;
}
