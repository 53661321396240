import { Auth } from "aws-amplify";
import { ActionContext } from "vuex";
import Vue from "vue";

import config from "@/startup/config";
import { SupGroup, UserViewModel } from "/@/models/UserModel";
import { TeamViewModel } from "/@/models/TeamModel";

import b64 from "base64-js";

import UserTrackingService from "@/helpers/userTrackingService";

import { TeamRepo } from "@/api/auth/repos/TeamRepo";

import { initSentryUser } from "/@/startup/sentryMonitoring";

import { useSlackChannelsStore } from "/@/stores/slackChannels";
import { useSlackUsersStore } from "@/stores/slackUsers";
import { PlanCode, toPlanSubscription } from "@/models/BillingModel";
import { getDisplayRole } from "@/composables/useUser";

import { intersection } from "@/helpers/arrays";

const toUserViewModel = (source: any): UserViewModel => {
  const etcBytes = b64.toByteArray(source.attributes["custom:etc"]);
  const etc = JSON.parse(new TextDecoder("utf-8").decode(etcBytes));
  //  groups are not directly from cognito attributes, but overridden in PreTokenGeneration triggger by the values from "User" table
  const groups =
    (source?.signInUserSession?.accessToken?.payload[
      "cognito:groups"
    ] as SupGroup[]) ?? [];

  const result: UserViewModel = {
    id: source.username,
    uuid: source.attributes?.sub,
    email: source.attributes?.email,
    teamId: source.attributes["custom:team_id"],
    teamName: etc.team_name,
    accessToken: etc.access_token,
    accessScopes: etc.access_scopes
      ? (etc.access_scopes as string).split(",")
      : undefined,
    isSlackAdmin: source.attributes["custom:is_admin"] === "true",
    groups: [...groups],
    originalGroups: [...groups],
    team: null,
    realName: etc.rn,
    displayName: etc.dn,
    image: etc.image_72,
    slackId: (source.username as string).startsWith("Slack_")
      ? (source.username as string).replace("Slack_", "")
      : undefined
  };

  return result;
};

const toTeamViewModel = (source: any): TeamViewModel => {
  const result: TeamViewModel = {
    slackAppInstalled: source.installed || false,
    planSubscriptionId: source.planSubscriptionId || null,
    planSubscription: toPlanSubscription(source.planSubscription),
    lastTrialDay: source.lastTrialDay,
    updatedAt: new Date(source.updatedAt || 0),
    botUserId: source.botUserId,
    agents: source.agents ?? [],
    ticketNumber: source.ticketNumber,
    features: source.features ? JSON.parse(source.features) : {},
    planOffer: source.planOffer ? JSON.parse(source.planOffer) : null
  };

  return result;
};

export interface AuthState {
  user: UserViewModel | null;
  ready: boolean;
  initializingSubscription: boolean;
  initializingSubscriptionAttempts: number | null;
  initializingSubscriptionError: boolean;
}

//let loadChannelsAlreadyInProgress = false;

export default {
  namespaced: true,
  state(): AuthState {
    return {
      user: null,
      ready: false,
      initializingSubscription: false,
      initializingSubscriptionAttempts: null,
      initializingSubscriptionError: false
    };
  },

  actions: {
    /**
     * loadTeam requires user to be authenticated (i.e. authUser successgully performed and user.teamId initialized) and have the app installed.
     * loadTeam called in several situations:
     *  - in authUser action
     *  - on some transactions where up-to-date state is important, e.g. billing (the number of agents is a team property)
     */
    async loadTeam(context: ActionContext<AuthState, any>) {
      if (!context.state.user) {
        throw Error("Not authenticated");
      }
      const dbTeam = await TeamRepo.getById(context.state.user.teamId);
      if (dbTeam) {
        // TODO: clean up setInitializingSubscription and setInitializingSubscriptionError - they are not needed anymore.
        context.commit("setInitializingSubscription", false);
        context.commit("setInitializingSubscriptionError", false);
        const team = toTeamViewModel(dbTeam);
        Vue.$log.debug("setTeam", team);
        context.commit("setTeam", team);
        Vue.$log.debug("auth/loadTeam: ok");
      } else {
        Vue.$log.debug("auth/loadTeam: team not found. App not installed?");
      }
    },
    async pollTeamUntillNewUpdated(
      context: ActionContext<any, any>,
      updatedAt: Date
    ) {
      if (!context.state.user) {
        throw Error("Not authenticated");
      }
      let fetch = true;
      while (fetch) {
        const dbTeam = await TeamRepo.getById(context.state.user.teamId);
        const team = toTeamViewModel(dbTeam);

        if (team.updatedAt > updatedAt) {
          fetch = false;
          Vue.$log.debug("setTeam", team);
          context.commit("setTeam", team);
        } else {
          // wait a sec before fetching again
          Vue.$log.debug("will fetch again...");
          await new Promise(res => {
            setTimeout(res, 1000);
          });
        }
      }
    },
    async authUser(context: ActionContext<AuthState, any>) {
      Vue.$log.debug("auth/authUser");

      if (context.state.ready) return;

      try {
        const { CURRENT_AUTH_USER_MOCK } = await import("./auth.fixtures.user");
        const user = config.USE_AUTH_MOCK
          ? CURRENT_AUTH_USER_MOCK
          : await attemptReadCognitoUser();

        await UserTrackingService.authUser(user);
        Vue.$log.debug("store user data: ", user);
        context.commit("setUser", user);

        // Preload some data "in background" so it will be ready when it's needed
        const { loadChannels } = useSlackChannelsStore();
        const displayRole = getDisplayRole(user);
        if (displayRole == "admin") {
          loadChannels();
        }
        const { loadUsers } = useSlackUsersStore();
        loadUsers();

        await Promise.all([
          context.dispatch("loadTeam"),
          context.dispatch("billing/loadPlans", null, { root: true })
        ]).then(() => {
          Vue.$log.debug("Auth ready, user=", user);
          context.commit("setReady", true);
        });

        if (!context.state.user?.accessToken) {
          throw new Error("Access token is missing");
        }
      } catch (e) {
        await UserTrackingService.authUser(undefined);
        context.commit("setUser", undefined);
        Vue.$log.info("Not authenticated");
      }
    },
    async signOutUser() {
      Vue.$log.debug("auth/signOutUser");
      if (config.RELEASE_ENVIRONMENT == "dev-tmp") {
        await navigator.clipboard.writeText(window.location.origin);
      }
      await Auth.signOut();
    }
  },

  mutations: {
    setUser(state: AuthState, payload: UserViewModel) {
      state.user = payload;
      initSentryUser(state.user);
    },
    setTeam(state: AuthState, payload: TeamViewModel) {
      if (state.user) {
        state.user.team = payload;

        // HACK: remove Suptask groups on plans that don't support them
        // TODO: this should be done in PreTokenGeneration lambda
        if (
          !isPlanEqualOrGreaterThanBasic(
            state.user.team.planSubscription?.plan.code
          )
        ) {
          // note that state.user.originalGroups stays untouched and can be used where plan should be ignored, for example billing
          state.user.groups = intersection(["SlackAdmins"], state.user.groups);
        }

        UserTrackingService.authTeam(state.user);
        initSentryUser(state.user);
      }
    },
    setReady(state: AuthState, payload: boolean) {
      if (state.user) {
        state.ready = payload;
      }
    },
    setInitializingSubscription(state: AuthState, payload: boolean) {
      state.initializingSubscription = payload;
      if (state.initializingSubscription) {
        state.initializingSubscriptionAttempts =
          state.initializingSubscriptionAttempts == null
            ? 1
            : state.initializingSubscriptionAttempts + 1;
      } else {
        state.initializingSubscriptionAttempts = null;
      }
    },
    setInitializingSubscriptionError(state: AuthState, payload: boolean) {
      state.initializingSubscriptionError = payload;
    }
  },

  getters: {
    user(state: AuthState): UserViewModel | null {
      return state.user;
    },
    slackAppInstalled(state: AuthState): boolean {
      return state.user?.team?.slackAppInstalled || false;
    },
    installationCompleted(state: AuthState): boolean {
      return (
        (state.user?.team?.slackAppInstalled ?? false) &&
        (state.user?.team?.ticketNumber ?? 0) > 0
      );
    },
    ready(state: AuthState): boolean {
      return state.ready || false;
    },
    initializingSubscription(state: AuthState): boolean {
      return state.initializingSubscription;
    },
    initializingSubscriptionError(state: AuthState): boolean {
      return state.initializingSubscriptionError;
    }
  }
};

async function attemptReadCognitoUser(): Promise<UserViewModel | null> {
  const cognitoUser = await Auth.currentAuthenticatedUser({
    bypassCache: true
  });
  if (!cognitoUser || !cognitoUser.attributes) {
    throw new Error();
  }

  return toUserViewModel(cognitoUser);
}

function isPlanEqualOrGreaterThanBasic(planCode: PlanCode | undefined) {
  return planCode == "p_basic" || planCode == "p_custom";
}
