


















import { defineComponent } from "@vue/composition-api";
import { openHelpChat } from "/@/helpers/helpCrunch";

export default defineComponent({
  name: "feature-not-available",
  setup() {
    return { openHelpChat };
  }
});
