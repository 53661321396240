import { fetchApiSlackV2 } from "@/helpers/api.slack";
import { SlackMember } from "../models/SlackUser";

export default class UserRepo {
  public static async getList(token: string): Promise<SlackMember[]> {
    // See Slack documentation: https://api.slack.com/methods/users.list
    return await fetchApiSlackV2({
      url: "https://slack.com/api/users.list",
      token,
      itemsField: "members"
    });
  }
}
