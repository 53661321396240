export function intersection<T>(arr1: T[], arr2: T[]) {
  return arr1.filter(itemFromArr1 => arr2.includes(itemFromArr1));
}

/**
 * Returns new array with unique items. Uniqueness defined by property.
 * Keeps the first occurrence by default!
 */
export function getUniqueObjectsByProp<T, K extends keyof T>(
  array: T[],
  prop: K,
  keep: "first" | "last" = "first"
): T[] {
  if (keep == "first") {
    const seen = new Set<T[K]>();
    return array.filter(item => {
      if (seen.has(item[prop])) return false;
      seen.add(item[prop]);
      return true;
    });
  } else {
    return [...new Map(array.map(item => [item[prop], item])).values()];
  }
}
