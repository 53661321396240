import { getCurrentInstance } from "@vue/composition-api";
import { useBreakpoints as useBreakpoinntsVueUse } from "@vueuse/core";
import { getCssVariableValue } from "@/helpers/browser";

export default function useBreakpoints() {
  const vm = getCurrentInstance();
  if (!vm) throw new Error("useBreakpoints must be called in setup() method");

  const widescreen = getCssVariableValue("--bulma-widescreen");
  const breakpoints = useBreakpoinntsVueUse({
    widescreen
  });

  return breakpoints;
}
