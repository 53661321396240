













import { Options, Vue } from "/@/compatibility/vue-class-component";

@Options({
  props: {
    // if to, then router-link is used
    to: [String, Object],
    // if href, then <a> is used
    href: String,
    icon: String,
    iconRight: String,
    iconSize: String,
    target: {
      type: String,
      default: "_self"
    }
  }
})
export default class ALinkButton extends Vue {}
