import Vue from "vue";
import config from "./config";

import VueLogger from "vuejs-logger";
import { ILoggerOptions } from "vuejs-logger/dist/interfaces/logger-options";

import * as Sentry from "@sentry/vue";

/**
 * Initializing as plugin looks like the following:
 *  const loggerPlugin = (VueLogger as unknown) as PluginObject<ILoggerOptions>;
 *  Vue.use<ILoggerOptions>(loggerPlugin, logOptions);
 * But istead of that we hack the original implementation to add "trace" - a lower than "debug" level.
 * Also set log level from config.
 */

export const logLevels = [
  "trace",
  "debug",
  "info",
  "warn",
  "error",
  "fatal"
] as const;
export type LogLevel = typeof logLevels[number];

interface LoggerOptionsExtended extends Omit<ILoggerOptions, "logLevel"> {
  logLevel: LogLevel;
}

const logOptionsExtended: LoggerOptionsExtended = {
  isEnabled: true,
  logLevel: config.LOG_LEVEL as LogLevel,
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true
};

let isComposition = false;

Object.getPrototypeOf(VueLogger).getMethodName = function() {
  const error = new Error();
  if (error.stack === undefined) {
    return "";
  }

  const stackIdx = isComposition ? 5 : 4; // there is one extra call in stack trace added in useLog for composition api.
  let stackTrace = error.stack.split("\n")[stackIdx];
  if (/ /.test(stackTrace)) {
    stackTrace = stackTrace.trim().split(" ")[1];
  }
  if (stackTrace && stackTrace.indexOf(".") > -1) {
    stackTrace = stackTrace.split(".")[1];
  }
  return stackTrace;
};

const extendedVueLogger = Object.getPrototypeOf(VueLogger).initLoggerInstance(
  logOptionsExtended,
  logLevels
);

function captureMessage(level: Sentry.SeverityLevel, args: any[]) {
  if (!config.USE_SENTRY) return;
  Sentry.withScope(scope => {
    scope.setLevel(level);
    const [message, ...restArgs] = args;
    const stringArgs: string[] = [];
    restArgs.forEach(arg => {
      if (arg.captureContext) {
        const { captureContext, ...restProps } = arg;
        const captureContextName =
          typeof captureContext == "string" ? captureContext : "vuejs-logger";
        Sentry.setContext(captureContextName, {
          ...restProps
        });
      }
      if (typeof arg == "string") {
        stringArgs.push(arg);
      }
    });
    if (stringArgs.length > 0) {
      Sentry.setContext("Other log messages", {
        ...stringArgs
      });
    }

    Sentry.captureMessage(message);
  });
}

for (const level of logLevels) {
  const originalFn = extendedVueLogger[level];
  extendedVueLogger[level] = function(...args: any[]) {
    isComposition = false;
    const logArgs = [...args];
    const messageArgs = [...args];
    if (args[0]?.composition) {
      isComposition = true;
      if (args[0].componentName) {
        logArgs[0] = `${args[0].componentName} ${logOptionsExtended.separator} `;
        messageArgs.shift();
        messageArgs.splice(1, 0, {
          captureContext: true,
          component: args[0].componentName
        });
      } else {
        logArgs.shift();
      }
    }
    if (level == "warn" || level == "error" || level == "fatal") {
      captureMessage(level == "warn" ? "warning" : level, messageArgs);
    }
    originalFn(...logArgs);
  };
}

Vue.$log = extendedVueLogger;
Vue.prototype.$log = extendedVueLogger;
