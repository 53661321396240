
































import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  setup() {
    const loading = ref(true);
    function load() {
      loading.value = false;
    }

    return {
      loading,
      load
    };
  },
  props: {
    gif: String,
    video: String
  }
});
