import Vue from "vue";
import { isPromise } from "../promises";

export function log({
  level = "trace",
  message,
  point = "finish"
}: {
  level?: "trace" | "debug" | "info" | "warn";
  message?: string;
  point?: "start" | "finish" | "both";
} = {}) {
  return function(
    target: any, // Function fo static membe; Object
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    const originalMethod = descriptor.value;
    descriptor.value = async function(...args: any) {
      const className =
        typeof target == "function"
          ? // static member
            target.name
          : // instance member
            target.constructor.name;
      const log =
        `${className}.${propertyKey}` + (message ? ` ${message}` : "");
      ["start", "both"].includes(point) && Vue.$log[level](`${log} start`);
      const result = originalMethod.apply(this, args);
      const returnResult = isPromise(result)
        ? await result
        : Promise.resolve(result);
      ["finish", "both"].includes(point) && Vue.$log[level](`${log} finish`);
      return returnResult;
    };
  };
}
