// HelpCrunch init (https://helpcrunch.com/)

import { UserViewModel } from "@/models/UserModel";
import config from "./config";
import Vue from "vue";
import { getIsAccountAdmin } from "@/composables/useUser";

(async function(w: any, d: any) {
  if (!config.USE_HELP_CRUNCH) return;

  w.helpcrunchSettings = {
    organization: "suptask"
  };

  const widgetSrc = `https://${w.helpcrunchSettings.organization}.widget.helpcrunch.com/`;

  w.HelpCrunch = function(...args: any[]) {
    if (w.HelpCrunch.scriptLoadError) return;
    w.HelpCrunch.q.push(args);
  };
  w.HelpCrunch.q = [];
  function r() {
    return new Promise(resolve => {
      const s = document.createElement("script");
      s.async = true;
      s.type = "text/javascript";
      s.onload = () => {
        Vue.$log.debug("HelpCrunch: widget script loaded.");
        w.HelpCrunch.scriptLoadError = false;
        resolve(undefined);
      };
      s.onerror = () => {
        Vue.$log.error(
          "HelpCrunch: error loading widget script, all HelpCrunch calls will be ignored."
        );
        w.HelpCrunch.scriptLoadError = true;
        resolve(undefined);
      };
      s.src = widgetSrc;
      (d.body || d.head).appendChild(s);
    });
  }
  await r();
})(window, document);

if (config.USE_HELP_CRUNCH) {
  const w = window as any;
  w.HelpCrunch("init", w.helpcrunchSettings.organization, {
    appId: config.HELP_CRUNCH_APP_ID
  });
}

export const showHelpCrunchIfConfigured = () => {
  if (!config.USE_HELP_CRUNCH) return;
  const w = window as any;
  w.HelpCrunch("showChatWidget");
};

export const authHelpCrunchUser = (user: UserViewModel | null | undefined) => {
  try {
    if (!config.USE_HELP_CRUNCH) return Promise.resolve();

    const w = window as any;

    if (w.HelpCrunch.scriptLoadError) {
      Vue.$log.error("HelpCrunch: ignore auth.");
      // HelpCrunch problem should not be a stopper, so resolve
      return Promise.resolve();
    }

    if (!config.HELP_CRUNCH_USER_AUTH_ENABLED) {
      return new Promise<void>(resolve => {
        w.HelpCrunch("logout", resolve);
      });
    }

    const hcUser: any = user
      ? {
          user_id: `${user.uuid}--${user.id}`,
          email: user.email,
          name:
            user.realName && user.realName !== ""
              ? user.realName
              : user.displayName,
          company: user.teamName
        }
      : null;

    if (user && hcUser && config.HELP_CRUNCH_USER_AUTH_CUSTOM_DATA_ENABLED) {
      hcUser.custom_data = {
        team_id: user.teamId,
        user_id: user.id,
        is_admin: getIsAccountAdmin(user)
      };
    }

    if (hcUser) {
      Vue.$log.debug(`HelpCrunch: userAuth`, hcUser);
      return new Promise<void>(resolve => {
        w.HelpCrunch("userAuth", hcUser, undefined, resolve);
      });
    } else {
      Vue.$log.debug(`HelpCrunch: logout`);
      return new Promise<void>(resolve => {
        w.HelpCrunch("logout", resolve);
      });
    }
  } catch (e) {
    // Any HelpCrunch problem should not be a stopper, so catch and log error
    Vue.$log.error(`HelpCrunch: error`, e);
  }
};
