import ARouterTabLink from "/@/components/atoms/a-router-tab-link.vue";
import ARouterButtonLink from "/@/components/atoms/a-router-button-link.vue";
import AButton from "/@/components/atoms/a-button.vue";
import ALinkButton from "/@/components/atoms/a-link-button.vue";
import AButtonWithLabel from "/@/components/atoms/a-button-with-label.vue";
import AInput from "/@/components/atoms/a-input.vue";
import AHidden from "/@/components/atoms/a-hidden.vue";
import ASelect from "/@/components/atoms/a-select.vue";
import ASelect2 from "/@/components/atoms/a-select-2.vue";
import ACheckbox from "/@/components/atoms/a-checkbox.vue";
import AIconName from "/@/components/atoms/a-icon-name.vue";
import OModal from "/@/components/organisms/o-modal.vue";
import OModalConfirmation from "/@/components/organisms/o-modal-confirmation.vue";
import OModalConfirmationExitEdit from "/@/components/organisms/o-modal-confirmation-exit-edit.vue";
import OOverlay from "/@/components/organisms/o-overlay.vue";
import OLoomOverlay from "/@/components/organisms/o-loom-overlay.vue";
import OBlockedView from "/@/components/organisms/o-blocked-view.vue";
import OPage from "/@/components/organisms/o-page.vue";
import OWizardModal from "/@/components/organisms/wizard/o-wizard-modal.vue";
import AHint from "/@/components/atoms/a-hint.vue";
import MLoading from "/@/components/molecules/m-loading.vue";
import MLoading2 from "/@/components/molecules/m-loading-2.vue";
import AChannel from "/@/components/atoms/a-channel.vue";
import APlanSwitch from "/@/components/atoms/a-plan-switch.vue";
import OButtonWithConfirmation from "/@/components/organisms/o-button-with-confirmation.vue";
import ALogo from "/@/components/atoms/a-logo.vue";
import MLogoPanel from "/@/components/molecules/m-logo-panel.vue";
import MBox from "@/components/molecules/m-box.vue";
import AIconLabel from "@/components/atoms/a-icon-label.vue";

import CollapsePanel from "/@/components/collapse-panel.vue";
import FeatureNotAvailable from "/@/components/feature-not-available.vue";
import Field from "/@/components/field.vue";
import Banner from "/@/components/banner.vue";
import ModalSimple from "/@/components/modal-simple.vue";
import PageCard from "/@/components/page-card.vue";
import TableHelpButton from "@/components/table-help-button.vue";
import Avatar from "@/components/avatar.vue";
import TicketTag from "@/views/Tickets/components/ticket-tag.vue";
import TicketTags from "@/views/Tickets/components/ticket-tags.vue";

import { ValidationObserver, ValidationProvider } from "vee-validate";

// vue3
// import { App } from "@vue/runtime-core";
// export default {
//   install: (app: App /*, options*/) => {
//     app.component("a-router-tab-link", SRouterTabLink);
//     app.component("a-router-button-link", ARouterButtonLink);
//     app.component("a-button", SButton);
//     app.component("a-input", SInput);
//     ...
//   }
// };

// vue2
import Vue from "vue";
Vue.component("a-router-tab-link", ARouterTabLink);
Vue.component("a-router-button-link", ARouterButtonLink);
Vue.component("a-button", AButton);
Vue.component("a-link-button", ALinkButton);
Vue.component("a-button-with-label", AButtonWithLabel);
Vue.component("a-input", AInput);
Vue.component("a-hidden", AHidden);
Vue.component("a-select", ASelect);
Vue.component("a-select-2", ASelect2);
Vue.component("a-checkbox", ACheckbox);
Vue.component("a-icon-name", AIconName);
Vue.component("o-modal", OModal);
Vue.component("o-modal-confirmation", OModalConfirmation);
Vue.component("o-modal-confirmation-exit-edit", OModalConfirmationExitEdit);
Vue.component("o-overlay", OOverlay);
Vue.component("o-loom-overlay", OLoomOverlay);
Vue.component("o-blocked-view", OBlockedView);
Vue.component("o-page", OPage);
Vue.component("o-wizard-modal", OWizardModal);
Vue.component("a-hint", AHint);
Vue.component("m-loading", MLoading);
Vue.component("m-loading-2", MLoading2);
Vue.component("a-channel", AChannel);
Vue.component("a-plan-switch", APlanSwitch);
Vue.component("o-button-with-confirmation", OButtonWithConfirmation);
Vue.component("a-logo", ALogo);
Vue.component("m-logo-panel", MLogoPanel);
Vue.component("m-box", MBox);
Vue.component("a-icon-label", AIconLabel);

Vue.component("collapse-panel", CollapsePanel);
Vue.component("feature-not-available", FeatureNotAvailable);
Vue.component("field", Field);
Vue.component("banner", Banner);
Vue.component("modal-simple", ModalSimple);
Vue.component("page-card", PageCard);
Vue.component("table-help-button", TableHelpButton);
Vue.component("avatar", Avatar);

Vue.component("validation-observer", ValidationObserver);
Vue.component("validation-provider", ValidationProvider);

Vue.component("ticket-tag", TicketTag);
Vue.component("ticket-tags", TicketTags);
