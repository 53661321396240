















import { defineComponent, ref } from "@vue/composition-api";
export default defineComponent({
  name: "banner",
  props: {
    /**
     * Name is used to identify the banner so it can be dismissed. If undefined, then the banner is not dismissable, hence no X-button shown
     */
    name: {
      type: String,
      required: false
    }
  },
  setup({ name }) {
    const localStorageKey = name
      ? `user.preferences.dismissedBanner.${name}`
      : undefined;

    const localStorageDismissedBanner = localStorageKey
      ? localStorage.getItem(localStorageKey) !== null
      : false;

    const isShowBanner = ref(!localStorageDismissedBanner);
    const canClose = ref(name !== undefined);

    const closeBanner = () => {
      if (!localStorageKey) return;
      isShowBanner.value = false;
      localStorage.setItem(localStorageKey, "");
    };

    return {
      isShowBanner,
      canClose,
      closeBanner
    };
  }
});
