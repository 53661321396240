

















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "field",
  props: {
    label: { type: String, required: false },
    help: String
  }
});
