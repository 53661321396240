















import { Options, Vue } from "/@/compatibility/vue-class-component";

@Options({
  props: {
    to: String,
    external: Boolean,
    exactOnly: Boolean
  }
})
export default class ARouterTabLink extends Vue {}
