import Vue from "vue";
import VueRouter from "vue-router";

import * as Sentry from "@sentry/vue";

import config from "/@/startup/config";
import { UserViewModel } from "@/models/UserModel";

export default function initSentryMonitoring(router: VueRouter): void {
  if (!config.USE_SENTRY) {
    Vue.$log.warn("Sentry is switched off");
    return;
  }

  Vue.$log.info("Initializing Sentry");
  Sentry.init({
    Vue,
    dsn: config.SENTRY_DSN,
    environment: config.RELEASE_ENVIRONMENT,
    // release should match the value set up in SentryWebpackPlugin if it's used
    release: config.RELEASE,
    integrations: [
      Sentry.browserTracingIntegration({
        router
      }),
      Sentry.replayIntegration({
        blockAllMedia: false
      })
    ],
    tracePropagationTargets: [
      "localhost",
      "app-dev.suptask.com",
      "app.suptask.com",
      /^\//
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

export function initSentryUser(user: UserViewModel | null | undefined): void {
  if (!config.USE_SENTRY) return;
  Sentry.setUser(
    user
      ? {
          id: user.id,
          uuid: user.uuid,
          teamId: user.teamId
        }
      : null
  );
  Sentry.setTag("user.teamId", user?.teamId);
}
