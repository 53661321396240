import { ListPlanSubscriptionsQuery } from "@/API";

type DbPlanSubscriptionsList = Exclude<
  ListPlanSubscriptionsQuery["listPlanSubscriptions"],
  null | undefined
>;
type DbPlanSubscriptions = Exclude<
  DbPlanSubscriptionsList["items"],
  null | undefined
>;
export type DbPlanSubscription = Exclude<DbPlanSubscriptions[number], null>;

export interface PlansViewModel {
  free: PlanSubscription;
  paidLight: {
    daily: PlanSubscription | null;
    monthly: PlanSubscription;
    yearly: PlanSubscription;
  };
  paidBasic: {
    daily: PlanSubscription | null;
    monthly: PlanSubscription;
    yearly: PlanSubscription;
  };
}

export type BillingPeriod = "d" | "m" | "y";

export const periodMap: Record<BillingPeriod, string> = {
  d: "day",
  m: "month",
  y: "year"
};

export interface PlanSubscription {
  id: string;
  internalDescription?: string | null;
  billingPeriod?: BillingPeriod | null;
  billingPeriodPriceUsd?: number | null;
  billingPeriodPriceLabel?: string | null;
  /**
   * Custom plan; shows a (custom) label if plan is custom.
   */
  isCustom: boolean;
  isFree: boolean;
  isTrial: boolean;
  public: boolean;
  minAgents?: number;
  plan: Plan;
}

export function toPlanSubscription(s: DbPlanSubscription) {
  if (!s?.plan) {
    throw new Error("");
  }
  const res: PlanSubscription = {
    id: s?.id,
    plan: {
      id: s?.plan?.id,
      code: s?.plan?.code as PlanCode,
      name: s?.plan?.name,
      formsLimit: s?.plan?.formsLimit || null,
      customFieldsLimit: s?.plan?.customFieldsLimit || null
    },
    billingPeriod: s?.billingPeriod as BillingPeriod,
    billingPeriodPriceUsd: s?.billingPeriodPriceUsd,
    billingPeriodPriceLabel: s?.billingPeriodPriceLabel,
    isCustom: s?.isCustom ?? false,
    isFree: s?.isFree ?? false,
    isTrial: s?.isTrial ?? false,
    public: s?.public ?? false,
    minAgents: s?.minAgents ?? undefined
  };
  return res;
}

export enum PlanCodeEnum {
  p_free = 0,
  p_light = 10,
  p_basic = 20,
  p_custom = 100
}
export type PlanCode = keyof typeof PlanCodeEnum;

export interface Plan {
  id: string;
  code: PlanCode;
  name: string;
  formsLimit: number | null;
  customFieldsLimit: number | null;
}

export const subscriptionPlanIds: {
  free: string;
  paidLightDaily: string; // TODO: for test purposes only
  paidLightMonthly: string;
  paidLightYearly: string;
  paidBasicDaily: string; // TODO: for test purposes only
  paidBasicMonthly: string;
  paidBasicYearly: string;
} = {
  free: "ps_free",
  paidLightDaily: "ps_light_q_d", // TODO: for test purposes only
  paidLightMonthly: "ps_light_q_m",
  paidLightYearly: "ps_light_q_y",
  paidBasicDaily: "ps_basic_d", // TODO: for test purposes only
  paidBasicMonthly: "ps_basic_q_m",
  paidBasicYearly: "ps_basic_q_y"
};

// Paddle API 2.0 sub-format
export interface RefSubscription {
  // deleted - after subscription is cancelled
  state: "active" | "past_due" | "trialling" | "deleted" | "paused";
  payment_information: {
    card_type: string;
    expiry_date: string;
    last_four_digits: string;
    payment_method: "card";
  };
  last_payment?: {
    amount: number;
    currency: string;
    date: string;
  };
  next_payment?: {
    amount: number;
    currency: string;
    date: string;
  };
  user_email: string;
  paused_at?: string;
  paused_from?: string;
  paused_reason?: string;
  // url to update payment method/card
  update_url: string;
  quantity?: number;
}

export interface RefPayment {
  id: number;
  amount: number;
  currency: string;
  payout_date: string;
  is_paid: 0 | 1;
  receipt_url: string;
}

export enum SubscriptionSuptaskStatusEnum {
  Unknown,
  Active,
  CancellationRequested
}
