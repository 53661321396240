import NoMenuLayout from "../views/_layout/NoMenu.vue";
import WidgetLayout from "../views/_layout/Widget.vue";

// vue3
//import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
//const routes: Array<RouteRecordRaw> = [

// vue2
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import userRoute from "./routes/user";
import adminRoutes from "./routes/admin";

import store from "/@/store";
import { adminGroups, UserViewModel } from "@/models/UserModel";
import { intersection } from "@/helpers/arrays";

import pinia from "/@/stores";
import { useAccountStore } from "/@/stores/account";

import UserTrackingService from "@/helpers/userTrackingService";

Vue.use(VueRouter);
const routes: Array<RouteConfig> = [
  {
    path: "/",
    beforeEnter: async (to, from, next) => {
      const user = store.getters["auth/user"] as UserViewModel;
      const canUseSlackAdminRoute =
        intersection(adminGroups.slackAdmins, user.groups).length > 0;
      Vue.$log.debug(
        "Router beforeEnter /, canUseAdminRoute ",
        canUseSlackAdminRoute
      );

      const accountStore = useAccountStore(pinia);
      await accountStore.initInstallationState();

      if (to.query["installed"] === "1" && canUseSlackAdminRoute) {
        UserTrackingService.trackAppInstalled();
        Vue.$log.debug("Redirect after the bot installation");
        next({ name: "get-started.wizard" });
        return;
      }
      next({
        path:
          canUseSlackAdminRoute && !accountStore.installationCompleted
            ? "/get-started"
            : "/tickets"
      });
    },
    meta: { requiresAuth: true }
  },
  userRoute,
  ...adminRoutes,
  {
    path: "/",
    component: NoMenuLayout,
    children: [
      {
        name: "login",
        path: "/login",
        component: () =>
          import(/* webpackChunkName: "public" */ "/@/views/Login.vue"),
        meta: { requiresAuth: false }
      },
      {
        name: "access-denied",
        path: "/access-denied",
        component: () =>
          import(/* webpackChunkName: "public" */ "/@/views/AccessDenied.vue"),
        meta: { requiresAuth: false }
      },
      {
        name: "slack-error",
        path: "/token-error",
        component: () =>
          import(/* webpackChunkName: "public" */ "/@/views/TokenError.vue"),
        meta: { requiresAuth: false }
      },
      {
        name: "slack-error.token_revoked",
        path: "/token-revoked",
        component: () =>
          import(/* webpackChunkName: "public" */ "/@/views/TokenError.vue"),
        props: {
          error: "token_revoked"
        },
        meta: { requiresAuth: false }
      },
      {
        name: "slack-error.token_expired",
        path: "/token-expired",
        component: () =>
          import(/* webpackChunkName: "public" */ "/@/views/TokenError.vue"),
        props: {
          error: "token_expired"
        },
        meta: { requiresAuth: false }
      },
      {
        // login form zendesk
        name: "zendesk.login",
        path: "/widgets/zendesk/login",
        component: () =>
          import(
            /* webpackChunkName: "widgets" */ "/@/views/Widgets/ZendeskLogin.vue"
          ),
        meta: { requiresAuth: false }
      }
    ]
  },
  {
    // public views
    path: "/public",
    component: NoMenuLayout,
    children: [
      {
        path: "auth-dm",
        component: () =>
          import(/* webpackChunkName: "public" */ "/@/views/Public/AuthDm.vue")
      }
    ],
    meta: { requiresAuth: false }
  },
  {
    path: "/widgets",
    component: WidgetLayout,
    children: [
      {
        name: "zendesk",
        path: "zendesk",
        component: () =>
          import(
            /* webpackChunkName: "widgets" */ "/@/views/Widgets/Zendesk.vue"
          )
      }
    ],
    meta: { requiresAuth: true, login: "zendesk.login" }
  },
  {
    // start zendesk login
    name: "zendesk.start-login",
    path: "/widgets/zendesk/start-login",
    component: () =>
      import(
        /* webpackChunkName: "widgets" */ "../views/Widgets/ZendeskStartLogin.vue"
      ),
    meta: { requiresAuth: false }
  },
  {
    // start zendesk logout
    name: "zendesk.start-logout",
    path: "/widgets/zendesk/start-logout",
    component: () =>
      import(
        /* webpackChunkName: "widgets" */ "../views/Widgets/ZendeskStartLogout.vue"
      ),
    meta: { requiresAuth: false }
  },
  {
    // auth
    path: "/auth/:system",
    redirect: route => {
      const system = route.params.system;
      const action = route.query.action;
      const actionRouteNameDict: Record<string, string> = {
        setup_wizard: "get-started.wizard",
        setup_inbox: "inboxes.new"
      };

      const hasAction = Object.prototype.hasOwnProperty.call(
        actionRouteNameDict,
        action as string
      );

      if (system == "slack" && hasAction) {
        const { code, context } = route.query;
        return {
          name: actionRouteNameDict[action as string],
          query: {
            code,
            context
          }
        };
      }
      // TODO: redirect to error?
      return {};
    },
    meta: { requiresAuth: false }
  },
  {
    path: "*",
    redirect: "/"
  }
];

// vue3
// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// });

// vue2
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

import requiresAuthGuard, { routeRequiresAuth } from "./guards/requiresAuth";
import requiresPermissiosGuard from "./guards/requiresPermissions";

router.beforeEach(async (to, from, next) => {
  Vue.$log.info("Router beforeEach", to.fullPath);
  if (await requiresAuthGuard(to, from, next)) {
    if (await requiresPermissiosGuard(to, from, next)) {
      return;
    }
    return;
  }
  next();
});

router.afterEach(async to => {
  Vue.$log.info("Router afterEach", to.fullPath);
  UserTrackingService.trackNavigation({
    requiresAuth: routeRequiresAuth(to),
    fullPath: to.fullPath
  });
});
