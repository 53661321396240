











































import { Options, Vue } from "/@/compatibility/vue-class-component";

@Options<OButtonWithConfirmation>({
  props: {
    confirmationTitle: String,
    confirmationBody: String,
    "cancel-button": {
      type: String,
      default: "Cancel"
    },
    "action-button": {
      type: String,
      default: "OK"
    },
    "alternative-button": {
      type: String
    },
    "action-button-danger": Boolean,
    primary: Boolean,
    "fa-icon": String,
    buttonClass: String,
    loading: Boolean,
    disabled: Boolean
  }
})
export default class OButtonWithConfirmation extends Vue {
  confirmationTitle!: string;
  confirmationBody!: string;
  cancelButton!: string;
  actionButton!: string;
  alternativeButton!: string;
  actionButtonDanger!: boolean;
  primary!: boolean;
  faIcon!: string;
  buttonClass!: string;
  loading!: boolean;
  disabled!: boolean;

  showDialog = false;
}
