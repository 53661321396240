






























import { computed, defineComponent } from "@vue/composition-api";
import useLog from "@/composables/_useLog";
import { useStoreGetters as useAuthGetters } from "/@/store/modules/auth.use";
import { useStoreGetters as useBillingGetters } from "/@/store/modules/Billing.use";
import useAuthReady from "@/composables/useAuthReady";

import { Feature } from "@/models/features";

export default defineComponent({
  name: "page",
  props: {
    feature: { type: String as () => Feature, required: false },
    contentLoadingMessage: { type: String, default: "Loading..." },
    isContentLoading: Boolean
  },
  setup(props, { emit }) {
    const $log = useLog();
    $log.debug("setup()");

    const { ready, slackAppInstalled } = useAuthGetters();
    const { featureFlags } = useBillingGetters();

    const isFeatureAvailable = computed(() => {
      return props.feature ? featureFlags.value[props.feature] : true;
    });

    useAuthReady(
      async () => {
        $log.debug("emit 'load'");
        emit("load", { slackAppInstalled, isFeatureAvailable });
      },
      null,
      true
    );

    return { ready, slackAppInstalled, isFeatureAvailable };
  },
  emits: ["load"]
});
