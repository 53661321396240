

































import { defineComponent } from "@vue/composition-api";
import { ASelectProps } from "./BaseSelect";
import { ValidationProvider } from "vee-validate";

export default defineComponent({
  props: {
    ...ASelectProps,
    selectClass: String
  },
  components: {
    ValidationProvider
  }
});
