import { RouteConfig } from "vue-router";

import MainLayout from "../../views/_layout/Main.vue";
import BlankLayout from "/@/views/_layout/Blank.vue";

const userRoute: RouteConfig = {
  path: "/",
  component: MainLayout,
  children: [
    {
      path: "/tickets",
      component: BlankLayout,
      children: [
        {
          path: "",
          name: "tickets",
          component: () =>
            import(
              /* webpackChunkName: "tickets" */ "/@/views/Tickets/Tickets.vue"
            )
        },
        {
          path: ":ticketId",
          name: "tickets.ticket-id",
          component: () =>
            import(
              /* webpackChunkName: "tickets" */ "/@/views/Tickets/Tickets.vue"
            ),
          props: true
        },
        {
          path: "/tickets/views/:viewId",
          name: "tickets.view-id",
          component: () =>
            import(
              /* webpackChunkName: "tickets" */ "/@/views/Tickets/Tickets.vue"
            )
        }
      ]
    }
  ],
  meta: { requiresAuth: true }
};

export default userRoute;
