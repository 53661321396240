























import { Options } from "/@/compatibility/vue-class-component";
import AButton from "./a-button.vue";

@Options({
  props: {
    label: [String],
    emptyLabel: Boolean
  }
})
export default class AButtonWithLabel extends AButton {}
