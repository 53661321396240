import Vue from "vue";
import { isPromise } from "../promises";

export function catchAndLog() {
  return function(
    target: any,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    const originalMethod = descriptor.value;
    descriptor.value = async function(...args: any) {
      try {
        const result = originalMethod.apply(this, args);
        return isPromise(result) ? await result : Promise.resolve(result);
      } catch (e) {
        Vue.$log.error(`${target.name}: ${propertyKey} error`, e);
      }
    };
  };
}
