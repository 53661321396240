import { Ref } from "@vue/composition-api";
import { templateRef as originalTemplateRef } from "@vueuse/core";

/**
 * Wrapper of the original vueuse's templateRef, so it's easy to cast to any type
 * vueuse's templateRef is useful because it doesn't require to return ref from setup()
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function templateRef<T>(key: string, initialValue?: T | null) {
  return (originalTemplateRef(key) as unknown) as Readonly<Ref<T>>;
}
