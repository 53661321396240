



















import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "a-tag",
  props: {
    icon: { type: String, required: false },
    label: { type: String, required: false },
    tooltip: { type: String, required: false },
    loading: { type: Boolean, required: false },
    error: { type: Boolean, required: false }
  },
  setup(props) {
    const displayIcon = computed(() => {
      if (props.loading) return "circle-notch";
      if (props.error) return "exclamation-triangle";
      return props.icon;
    });

    const displayLabel = computed(() => {
      if (props.loading) return "Loading...";
      if (props.error) return "Error";
      return props.label;
    });

    const displayTooltip = computed(() => {
      if (!props.tooltip) return undefined;
      if (props.loading) return "Loading...";
      if (props.error) return "Error";
      return props.tooltip;
    });

    return {
      displayIcon,
      displayLabel,
      displayTooltip
    };
  }
});
