import { computed } from "@vue/composition-api";
import { useStoreGetters as useAuthGetters } from "/@/store/modules/auth.use";
import { intersection } from "@/helpers/arrays";
import { adminGroups, UserViewModel } from "@/models/UserModel";
import { TeamViewModel } from "@/models/TeamModel";

export type DisplayRole = "user" | "admin";

export function getDisplayRole(user: UserViewModel | null) {
  let res: DisplayRole = "user";

  if (intersection(adminGroups.accountAdmins, user?.groups ?? []).length > 0) {
    res = "admin";
  }

  return res;
}

export function getIsAccountAdmin(user: UserViewModel | null) {
  return intersection(adminGroups.accountAdmins, user?.groups ?? []).length > 0;
}

/**
 * Returns if user is an agent in any respond channel
 */
export function getIsUserAnAgent(
  user: UserViewModel | null,
  team: TeamViewModel | null | undefined
) {
  return team?.agents.includes(user?.id.split("_")[1] ?? "") ?? false;
}

export default function useUser() {
  const { user } = useAuthGetters();
  return {
    user,
    displayRole: computed(() => getDisplayRole(user.value)),
    isAccountAdmin: computed(() => getIsAccountAdmin(user.value)),
    isUserAnAgent: computed(() =>
      getIsUserAnAgent(user.value, user.value?.team)
    )
  };
}
