




































































import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "page-card",
  props: {
    header: String,
    headerIcon: String,
    variant: {
      type: String as () => "default" | "collapsible" | "button",
      default: "default"
    },
    disabled: Boolean,
    /**
     * Has close button (for default variant)
     */
    closeable: Boolean,
    /**
     * Is active (for button variant)
     */
    active: Boolean,
    /**
     * Is open (for collapsible variant)
     */
    open: { type: Boolean, default: true }
  },
  setup(props) {
    return {
      tag: computed(() =>
        props.variant == "collapsible" ? "b-collapse" : "div"
      )
    };
  },
  emits: ["click", "close"]
});
