















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "o-modal-confirmation-exit-edit"
});
