import { requestAmplifyRestApi } from "@/helpers/api.rest";
import { RefPayment, RefSubscription } from "@/models/BillingModel";

export default class BillingRepo {
  /**
   * Gets subscription info from Paddle
   */
  public static async getRefSubscription(): Promise<RefSubscription | null> {
    const result = await requestAmplifyRestApi<RefSubscription>(
      "get",
      "billing",
      `/subscription`
    );
    return result ?? null;
  }

  /**
   * Gets payments history from Paddle
   */
  public static async getRefPayments(): Promise<RefPayment[]> {
    const result = await requestAmplifyRestApi<RefPayment[]>(
      "get",
      "billing",
      `/payments`
    );
    return result ?? [];
  }

  public static async getPayLink(
    planSubscriptionId: string
  ): Promise<string | undefined> {
    const result = await requestAmplifyRestApi<string>(
      "get",
      "billing",
      `/pay-link/${planSubscriptionId}`
    );
    return result;
  }

  public static async getOfferPayLink(): Promise<string | undefined> {
    const result = await requestAmplifyRestApi<string>(
      "get",
      "billing",
      `/offer-pay-link`
    );
    return result;
  }

  public static async changeSubscriptionPlan(
    newPlanSubscriptionId: string
  ): Promise<string | undefined> {
    const result = await requestAmplifyRestApi<string>(
      "post",
      "billing",
      `/change-subscription-plan`,
      { planSubscriptionId: newPlanSubscriptionId }
    );
    return result;
  }
}
