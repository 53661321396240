






























































import { Options, Vue } from "/@/compatibility/vue-class-component";
import templateRef from "@/composables/_templateRef";
import { ValidationObserver } from "vee-validate";

export function useModal(modalRef: string) {
  const modal = templateRef<{
    validationObserver: InstanceType<typeof ValidationObserver>;
  }>(modalRef);
  return modal;
}

@Options({
  props: {
    title: String,
    "cancel-on-esc": {
      type: Boolean,
      default: true
    },
    "cancel-button": {
      type: String,
      default: "Cancel"
    },
    "alternative-button": {
      type: String
    },
    "action-button": {
      type: String,
      default: "OK"
    },
    "action-button-danger": Boolean,
    "navigation-disabled": Boolean,
    fixedHeight: {
      type: Boolean,
      default: false
    },
    floatWidth: {
      type: Boolean,
      default: false
    },
    actionButtonFocused: Boolean,
    noPadding: Boolean,
    validate: {
      type: Boolean,
      default: false
    },
    loading: Boolean
  },
  setup(props, { emit }) {
    const validationObserver = templateRef<
      InstanceType<typeof ValidationObserver>
    >("validationObserver");

    async function action() {
      if (props.validate && !(await validationObserver.value?.validate())) {
        return;
      }
      emit("action");
    }

    return {
      validationObserver,
      action
    };
  }
})
export default class OModal extends Vue {
  $refs!: {
    actionButton: Vue;
  };
  cancelOnEsc!: boolean;
  actionButton!: string;
  actionButtonFocused!: boolean;
  navigationDisabled!: boolean;
  title!: string;
  cancelButton!: string;
  alternativeButton!: string;
  actionButtonDanger!: boolean;
  fixedHeight!: boolean;
  floatWidth!: boolean;
  noPadding!: boolean;
  validate!: boolean;
  loading!: boolean;
  action!: () => void;

  mounted() {
    if (this.actionButton && this.actionButtonFocused) {
      (this.$refs.actionButton.$el as HTMLElement).focus();
    }
    document.addEventListener("keyup", e => {
      const KEY_CODE_ESC = 27;
      if (
        (e.target as any).localName != "body" ||
        e.keyCode !== KEY_CODE_ESC ||
        !this.cancelOnEsc
      )
        return;
      this.$emit("cancel");
    });
  }

  onModalBackgroundClick() {
    if (this.navigationDisabled || !this.cancelOnEsc) return;
    this.$emit("cancel");
  }
}
