import Vue from "vue";
import { Auth } from "aws-amplify";
import axios, { Method, AxiosError } from "axios";
import { ApiError } from "@/models/Errors";

import { handleMirageError } from "@/helpers/errors";

import SupResponse from "/@/models/SuptaskBotApiResponse";
export const requestApiSuptaskBot = async <T>(
  method: Method,
  url: string,
  jwtToken: string,
  data: unknown | undefined = undefined,
  headers: Record<string, string> = {}
) => {
  try {
    const res = await axios.request<T>({
      method,
      headers: {
        Authorization: "AWS " + jwtToken,
        ...headers
      },
      url,
      data
    });

    return res.data;
  } catch (e) {
    if (handleMirageError(e, Vue.$log)) {
      return;
    }

    // TODO: handle axiosError.response?.status == 500 etc?
    const axiosError = e as AxiosError<SupResponse>;
    const errors = axiosError.response?.data.errors ?? [];

    if (errors.length > 0) {
      Vue.$log.error("Suptask bot API errors:", ...errors, {
        captureContext: "API refId",
        refId: axiosError.response?.data.refId
      });
    }

    throw new ApiError(
      "Suptask bot API error",
      errors,
      axiosError.response?.status,
      axiosError.response?.data.refId
    );
  }
};

export async function getJwtToken() {
  const cognitoUserSession = await Auth.currentSession().then(cred => {
    return cred;
  });
  const accessToken = cognitoUserSession.getAccessToken();
  return accessToken.getJwtToken();
}
