































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "a-select-2-tag",
  props: {
    optionsIdPropertyName: { type: String, required: true },
    optionsLabelPropertyName: { type: String, required: true },
    optionsIconPropertyName: { type: String, required: false },
    optionsClassPropertyName: { type: String, required: false },
    optionsCanDeletePropertyName: { type: String, required: false },
    optionIcon: { type: String, required: false },
    value: { type: Object, required: true }
  },
  setup() {
    //
  }
});
