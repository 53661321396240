











interface IconName {
  icon?: string;
  name: string;
}

import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "a-icon-name",
  props: {
    value: { type: Object as () => IconName, required: true },
    inline: Boolean
  }
});
