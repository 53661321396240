

















import { Options, Vue } from "/@/compatibility/vue-class-component";
import { ValidationProvider } from "vee-validate";

@Options({
  name: "a-hidden",
  props: {
    required: Boolean,
    value: String,
    customErrorMessages: Object as () => Record<string, string>
  },
  components: {
    ValidationProvider
  }
})
export default class AHidden extends Vue {}
