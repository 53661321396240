import auth, { AuthState } from "./modules/auth";
import fields, { FieldsState } from "./modules/Fields";
import forms, { FormsState } from "./modules/Forms";
import billing, { BillingState } from "./modules/Billing";
import integrations, { IntegrationsState } from "./modules/Integrations";

// vue3
// import { createStore } from "vuex";
// export default createStore({

// vue2
import Vuex from "vuex";
import Vue from "vue";
Vue.use(Vuex);

export interface RootState {
  auth: AuthState;
  fields: FieldsState;
  forms: FormsState;
  billing: BillingState;
  integrations: IntegrationsState;
}

export default new Vuex.Store({
  state: undefined,
  mutations: {},
  actions: {},
  modules: { fields, forms, billing, auth, integrations }
});
