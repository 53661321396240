import { computed } from "@vue/composition-api";
import { useStoreGetters as useAuthGetters } from "/@/store/modules/auth.use";

export default function usePlan() {
  const { user } = useAuthGetters();

  const planName = computed(() => {
    return user.value?.team?.planSubscription?.plan.name;
  });

  return {
    planName
  };
}
