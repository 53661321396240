import { API, graphqlOperation } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api";

export const fetchList = async <TItem>(
  graphqlQuery: unknown,
  itemsField: string,
  variables: any = {},
  limit = 500,
  maxCalls = 10
) => {
  const items: Array<TItem> = [];
  let nextToken: string | null = null;
  let callsCount = 0;

  do {
    callsCount++;
    const response = (await API.graphql(
      graphqlOperation(graphqlQuery, { ...variables, limit, nextToken })
    )) as GraphQLResult<any>;

    if (response.errors) {
      // TODO:
      throw new Error();
    }

    items.push(...response.data[itemsField].items);
    nextToken = response.data[itemsField].nextToken;
  } while (nextToken !== null && callsCount < maxCalls);

  if (nextToken !== null) {
    // not all items has been fetched due to maxCalls limit
    // TODO handle, but for now throw exception
    throw new Error("Too many items to fetch.");
  }

  return items;
};
