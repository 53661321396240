import { GithubIntegration } from "../models/GithubIntegration";
import { getJwtToken, requestApiSuptaskBot } from "@/helpers/api.bot";
import config from "/@/startup/config";

const apiUrl = `${config.SUPTASK_API}/integration`;

type CreateRequest = Required<Omit<GithubIntegration, "id">>;
type UpdateRequest = Required<GithubIntegration>;

export class GithubIntegrationRepo {
  public static async get(): Promise<GithubIntegration[]> {
    const jwtToken = await getJwtToken();
    const response = await requestApiSuptaskBot<any>(
      "GET",
      `${apiUrl}/github`,
      jwtToken
    );
    return response.data;
  }

  public static async getById(id: string): Promise<GithubIntegration> {
    const jwtToken = await getJwtToken();
    const response = await requestApiSuptaskBot<any>(
      "GET",
      `${apiUrl}/github/${id}`,
      jwtToken
    );
    return response.data;
  }

  public static async post(request: CreateRequest): Promise<any> {
    const jwtToken = await getJwtToken();
    const response = await requestApiSuptaskBot<any>(
      "POST",
      `${apiUrl}/github`,
      jwtToken,
      request
    );
    return response.data;
  }

  public static async put(request: UpdateRequest) {
    const jwtToken = await getJwtToken();
    const { id, ...requestToSend } = request;
    await requestApiSuptaskBot<any>(
      "PUT",
      `${apiUrl}/github/${id}`,
      jwtToken,
      requestToSend
    );
  }

  public static async delete(id: string) {
    const jwtToken = await getJwtToken();
    await requestApiSuptaskBot<any>(
      "DELETE",
      `${apiUrl}/github/${id}`,
      jwtToken
    );
  }
}
