









































import { Options, Vue } from "/@/compatibility/vue-class-component";
import {
  NotificationLevelEnum,
  NotificationModel,
  listenUiNotifications,
  pullDelayedUiNotification
} from "/@/models/NotificationModel";

const hideInfoNotificationAfterMs = 3000;

@Options<ONotificator>({})
export default class ONotificator extends Vue {
  // the first one is the latest one!
  notifications: Array<NotificationModel> = [];
  NotificationLevelEnum = NotificationLevelEnum;
  visible = true;
  detailsVisible = false;

  created() {
    // subscribe
    listenUiNotifications((payload: unknown) => {
      this.onNotificationEvent(payload);
    });
    // show delayed
    const delayedNotification = pullDelayedUiNotification();
    if (delayedNotification) this.onNotificationEvent(delayedNotification);
  }

  get visibleNotifications() {
    if (this.notifications.length == 0) return [];
    return this.notifications.slice(0, 1);
  }

  get latestNotification() {
    if (this.notifications.length == 0) return null;
    return this.notifications[0];
  }

  onNotificationEvent(payload: any) {
    this.notifications.unshift(payload.data);
    // clean info notification after timeout
    if (
      payload.data.level == NotificationLevelEnum.Info &&
      hideInfoNotificationAfterMs
    ) {
      setTimeout(() => {
        if (this.notifications.length == 0) return;
        const idx = this.notifications.indexOf(payload.data);
        if (idx > -1) {
          this.notifications.splice(idx, 1);
        }
      }, hideInfoNotificationAfterMs);
    }
  }

  dismissLatest() {
    if (this.notifications.length == 0) return;
    this.notifications.shift();
    this.detailsVisible = false;
  }
}
