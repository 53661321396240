














































































import { Options, Vue } from "/@/compatibility/vue-class-component";

@Options({
  name: "a-input",
  props: {
    required: Boolean,
    rules: Object,
    placeholder: String,
    label: String,
    help: String,
    value: String,
    textarea: Boolean,
    validationMode: {
      type: String as () => "agressive" | "passive" | "lazy" | "eager",
      default: "aggressive"
    },
    /**
     * Transformed to ValidationProvider's vid property
     */
    validationId: {
      type: String,
      required: false
    },
    textareaRows: {
      type: Number,
      default: 4
    },
    focused: Boolean,
    /**
     * Prepend text addon to show is as Bulma's element in "has-addons" fields
     * Not for textarea
     */
    prependAddon: String,
    disabled: {
      type: Boolean,
      required: false
    }
  }
})
export default class AInput extends Vue {
  $refs!: {
    textareaRef: HTMLElement;
    inputRef: HTMLInputElement;
  };
  rules!: any;
  textarea!: boolean;
  disabled!: boolean;
  placeholder!: string;
  label!: string;
  help!: string;
  value!: string;
  validationMode!: string;
  validationId!: string;
  required!: boolean;
  prependAddon!: string;
  focused!: boolean;
  textareaRows!: number;

  inputFocused = false;

  focus() {
    this.$nextTick(() => {
      if (this.textarea) {
        this.$refs.textareaRef.focus();
      } else {
        this.$refs.inputRef.focus();
        this.$refs.inputRef.select();
      }
    });
  }
}
