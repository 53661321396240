import { computed, ref } from "@vue/composition-api";
import Vue from "vue";
import { defineStore } from "pinia";
import store from "/@/store";

import UserRepo from "@/api/slack/repos/UserRepo";
import { SlackMember } from "@/api/slack/models/SlackUser";
import { differenceInSeconds } from "date-fns";

export const useSlackUsersStore = defineStore("slack-users", () => {
  // Would be better to use log and vuex like this, but it doesn't work when called from router or from vuex store
  // const $log = useLog();
  // const { user } = useAuthGetters();

  const allUsers = ref<SlackMember[]>();
  let loadedTs: Date | undefined = undefined;

  /**
   * load users from slack if wasn't loaded yet, or was loaded last time more than 60 sec ago
   */
  async function loadUsers() {
    const accessToken = store.state.auth.user?.accessToken;
    if (!accessToken) return;
    if (loadedTs && differenceInSeconds(new Date(), loadedTs) <= 60) {
      Vue.$log.debug(
        "slackUsersStore skip loadUsers as it was done less than 60 sec ago"
      );
      return;
    }
    Vue.$log.debug("slackUsersStore loadUsers start");
    allUsers.value = await UserRepo.getList(accessToken);
    loadedTs = new Date();
    Vue.$log.debug("slackUsersStore loadUsers finish");
  }

  const usersMap = computed(() => {
    return (allUsers.value ?? []).reduce(
      (map: Record<string, SlackMember>, obj) => {
        map[obj.id] = obj;
        return map;
      },
      {}
    );
  });

  const SLACKBOT_ID = "USLACKBOT";
  const activeHumanUsers = computed(() =>
    (allUsers.value ?? []).filter(
      u => !u.deleted && !u.is_bot && !u.is_app_user && u.id != SLACKBOT_ID
    )
  );

  return {
    loadUsers,
    /**
     * All users as is, not filtered
     */
    users: allUsers,
    /**
     * All active human users, i.e. not deleted, not bots and not calling app users
     */
    activeHumanUsers,
    usersMap
  };
});
