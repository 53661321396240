/**
 * Promise wrapper to reject promise if it wasn't resolved in time.
 * source: https://advancedweb.hu/how-to-add-timeout-to-a-promise-in-javascript/
 * @param promise - promise
 * @param timeoutMs - timeout in ms
 * @param exception - exception object to be thrown
 * @returns
 */
export function timeoutPromise<T>(
  promise: Promise<T>,
  timeoutMs: number,
  exception?: any
) {
  let timer: any;
  return Promise.race([
    promise,
    new Promise<T>((_r, rej) => (timer = setTimeout(rej, timeoutMs, exception)))
  ]).finally(() => clearTimeout(timer));
}

export function sleep(ms: number) {
  return new Promise<void>(resolve => setTimeout(resolve, ms));
}

export function isPromise(object: any): object is Promise<any> {
  return object && Promise.resolve(object) === object;
}
