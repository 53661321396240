import Vue from "vue";
import { ActionContext } from "vuex";

import { API } from "aws-amplify";

import { listPublicPlanSubscriptions } from "@/graphql/custom-queries";
import { fetchList } from "@/helpers/api.graphql";

import {
  DbPlanSubscription,
  PlansViewModel,
  PlanSubscription,
  toPlanSubscription,
  subscriptionPlanIds,
  RefSubscription,
  RefPayment,
  PlanCode
} from "/@/models/BillingModel";
import BillingRepo from "@/api/billing/repos/BillingRepo";
import { UserViewModel } from "/@/models/UserModel";
import { FeatureFlags } from "@/models/features";

import { SupError } from "/@/models/Errors";

import config from "@/startup/config";
import { PlanOffer } from "@/models/TeamModel";

function toPlansViewModel(
  planSubscriptions: Array<PlanSubscription> | null
): PlansViewModel | null {
  if (planSubscriptions == null) return null;

  const getSPlanById = (id: string): PlanSubscription => {
    const res = planSubscriptions.filter(ps => ps.id === id)[0];
    if (!res) {
      throw new Error("");
    }
    return res;
  };

  const result: PlansViewModel = {
    free: getSPlanById(subscriptionPlanIds.free),
    paidLight: {
      daily: config.REAL_PAYMENTS
        ? null
        : getSPlanById(subscriptionPlanIds.paidLightDaily),
      monthly: getSPlanById(subscriptionPlanIds.paidLightMonthly),
      yearly: getSPlanById(subscriptionPlanIds.paidLightYearly)
    },
    paidBasic: {
      daily: config.REAL_PAYMENTS
        ? null
        : getSPlanById(subscriptionPlanIds.paidBasicDaily),
      monthly: getSPlanById(subscriptionPlanIds.paidBasicMonthly),
      yearly: getSPlanById(subscriptionPlanIds.paidBasicYearly)
    }
  };

  return result;
}

function toPlans(
  dbPlanSubscriptions: DbPlanSubscription[]
): Array<PlanSubscription> | null {
  const res = dbPlanSubscriptions.map(s => {
    return toPlanSubscription(s);
  });
  return res || null;
}

export interface BillingState {
  loading: number;
  plans?: Array<PlanSubscription> | null;
  plansViewModel?: PlansViewModel | null;
  subscription?: RefSubscription | null;
  payments?: Array<RefPayment> | null;
  isExternalCheckoutLoading: boolean;
  isExternalCheckoutLoadingError: boolean;
  isExternalPaymentMethodLoading: boolean;
}

export default {
  namespaced: true,
  state(): BillingState {
    return {
      loading: 0,
      plans: null,
      subscription: null,
      payments: null,
      isExternalCheckoutLoading: false,
      isExternalCheckoutLoadingError: false,
      isExternalPaymentMethodLoading: false
    };
  },

  actions: {
    async loadPlans(context: ActionContext<BillingState, unknown>) {
      Vue.$log.debug("Action: loadPlans");

      context.commit("startLoading");

      const respItems = await fetchList<DbPlanSubscription>(
        listPublicPlanSubscriptions,
        "listPlanSubscriptions"
      );

      const plans = toPlans(respItems);
      Vue.$log.debug("setPlans", plans);
      context.commit("setPlans", plans);
      context.commit("finishLoading");

      const plansViewModel = toPlansViewModel(plans);
      Vue.$log.debug("setPlansViewModel", plansViewModel);
      context.commit("setPlansViewModel", plansViewModel);
    },
    async loadSubscription(context: ActionContext<BillingState, unknown>) {
      Vue.$log.debug("Action: loadSubscription start");

      const hasRefSubscription = context.getters
        .hasCurrentPlanRefSubscription as boolean;
      if (!hasRefSubscription) {
        Vue.$log.debug(
          "Action: current plan doesn't have a ref subscription. Set subscription to null"
        );
        context.commit("setSubscription", null);
        return;
      }

      context.commit("startLoading");

      let refSubscription = null;
      try {
        refSubscription = await BillingRepo.getRefSubscription();
      } catch {
        throw new SupError("Could not load payment details.", true);
      } finally {
        Vue.$log.debug(
          "Action: loadSubscription finish, res:",
          refSubscription
        );
        context.commit("setSubscription", refSubscription);
        context.commit("finishLoading");
      }
    },
    async loadPayments(context: ActionContext<BillingState, unknown>) {
      Vue.$log.debug("Action: loadPayments");

      try {
        const refPayments = await BillingRepo.getRefPayments();
        context.commit("setPayments", refPayments);
      } catch {
        throw new SupError("Could not load payments history.", true);
      }
    },
    async changeSubscriptionPlan(
      context: ActionContext<BillingState, unknown>,
      newPsId: string
    ) {
      context.commit("startLoading");
      try {
        const res = await BillingRepo.changeSubscriptionPlan(newPsId);
        Vue.$log.debug(res);
      } finally {
        context.commit("finishLoading");
      }
    },
    async requestCancellation(context: ActionContext<BillingState, unknown>) {
      context.commit("startLoading");
      await API.post("billing", "/pause", {});
      context.commit("finishLoading");
    },
    async revokeCancellationRequest(
      context: ActionContext<BillingState, unknown>
    ) {
      context.commit("startLoading");
      await API.post("billing", "/resume", {});
      context.commit("finishLoading");
    },
    async isExternalCheckoutLoading(
      context: ActionContext<BillingState, unknown>,
      value = false
    ) {
      context.commit("setIsExternalCheckoutLoading", value);
    },
    async isExternalCheckoutLoadingError(
      context: ActionContext<BillingState, unknown>,
      value = false
    ) {
      context.commit("setIsExternalCheckoutLoadingError", value);
    },
    async isExternalPaymentMethodLoading(
      context: ActionContext<BillingState, unknown>,
      value = false
    ) {
      context.commit("setIsExternalPaymentMethodLoading", value);
    }
  },

  mutations: {
    startLoading: (state: BillingState) => state.loading++,
    finishLoading: (state: BillingState) => state.loading--,
    setPlans(state: BillingState, payload: Array<PlanSubscription>) {
      state.plans = payload;
    },
    setPlansViewModel(state: BillingState, payload: PlansViewModel) {
      state.plansViewModel = payload;
    },
    setSubscription(state: BillingState, payload: RefSubscription) {
      state.subscription = payload;
    },
    setPayments(state: BillingState, payload: Array<RefPayment>) {
      state.payments = payload;
    },
    setIsExternalCheckoutLoading(state: BillingState, payload: boolean) {
      state.isExternalCheckoutLoading = payload;
    },
    setIsExternalCheckoutLoadingError(state: BillingState, payload: boolean) {
      state.isExternalCheckoutLoadingError = payload;
    },
    setIsExternalPaymentMethodLoading(state: BillingState, payload: boolean) {
      state.isExternalPaymentMethodLoading = payload;
    }
  },

  getters: {
    isLoading(state: BillingState) {
      return state.loading > 0;
    },

    plans(state: BillingState): Array<PlanSubscription> | null {
      return state.plans || null;
    },

    plansViewModel(state: BillingState): PlansViewModel | null {
      return state.plansViewModel || null;
    },

    subscription(state: BillingState): RefSubscription | null {
      return state.subscription || null;
    },

    payments(state: BillingState): Array<RefPayment> | null {
      return state.payments || null;
    },

    paymentsByDateDesc(state: BillingState): Array<RefPayment> | null {
      return (
        state.payments?.sort((a, b) => {
          if (a.payout_date < b.payout_date) return 1;
          if (a.payout_date > b.payout_date) return -1;
          return -(a.id - b.id);
        }) || null
      );
    },

    currentPlan(
      state: BillingState,
      getters: unknown,
      rootState: unknown,
      rootGetters: Record<string, unknown>
    ): PlanSubscription | null {
      const user = rootGetters["auth/user"] as UserViewModel;
      if (!state.plans || !user.team) return null;
      const res = user.team.planSubscription;
      if (!res) {
        throw new SupError("Problems reading subscription plan's details.");
      }
      return res;
    },
    planOffer(
      state: BillingState,
      getters: unknown,
      rootState: unknown,
      rootGetters: Record<string, unknown>
    ): PlanOffer | null {
      const user = rootGetters["auth/user"] as UserViewModel;
      return user.team?.planOffer ?? null;
    },
    isExternalCheckoutLoading(state: BillingState): boolean {
      return state.isExternalCheckoutLoading;
    },
    isExternalCheckoutLoadingError(state: BillingState): boolean {
      return state.isExternalCheckoutLoadingError;
    },
    isExternalPaymentMethodLoading(state: BillingState): boolean {
      return state.isExternalPaymentMethodLoading;
    },
    hasCurrentPlanRefSubscription(state: BillingState, getters: any): boolean {
      return !!getters.currentPlan && !getters.currentPlan.isFree;
    },
    featureFlags(state: BillingState, getters: any): FeatureFlags {
      const plan = getters.currentPlan as PlanSubscription;
      return {
        reports: isPlanEqualOrGreaterThanBasic(plan.plan.code),
        integrations: isPlanEqualOrGreaterThanBasic(plan.plan.code),
        admins: isPlanEqualOrGreaterThanBasic(plan.plan.code),
        dashboard: isPlanEqualOrGreaterThanBasic(plan.plan.code),
        tickets: isPlanEqualOrGreaterThanBasic(plan.plan.code),
        inboxes: true,
        autoCreateChannels: isPlanEqualOrGreaterThanBasic(plan.plan.code),
        accountSettings: isPlanEqualOrGreaterThanBasic(plan.plan.code),
        automations: isPlanEqualOrGreaterThanBasic(plan.plan.code),
        email: isPlanEqualOrGreaterThanCustom(plan.plan.code),
        jiraIntegration: isPlanEqualOrGreaterThanCustom(plan.plan.code)
      };
    }
  }
};

function isPlanEqualOrGreaterThanBasic(planCode: PlanCode) {
  return planCode == "p_basic" || planCode == "p_custom";
}
function isPlanEqualOrGreaterThanCustom(planCode: PlanCode) {
  return planCode == "p_custom";
}
