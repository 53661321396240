import { ApiError, SlackApiError, SupError } from "@/models/Errors";
import { Log } from "vuejs-logger";
import { dispatchUiNotification } from "@/models/NotificationModel";
import { AxiosError } from "axios";

/**
 * If error is ApiError: logs, alerts and returns true.
 * If not ApiError returns false.
 */
export function handleApiError(error: any, log?: Log): boolean {
  const className = error.constructor.name;
  if (error instanceof ApiError && className == ApiError.name) {
    if (log) {
      log.error({ error });
    }

    if (
      !error.httpStatus ||
      (error.httpStatus >= 500 && error.httpStatus < 600) ||
      !error.errors ||
      error.errors.length == 0
    ) {
      dispatchUiNotification.error(
        "An unknown API error happened.",
        error.refId
      );
    } else if (
      error.httpStatus >= 400 &&
      error.httpStatus < 500 &&
      error.errors[0]
    ) {
      dispatchUiNotification.error(error.errors[0], error.refId);
    }
    return true;
  }
  return false;
}

/**
 * If error is SupError: logs, alerts and returns true.
 * If not SupError returns false.
 */
export function handleSupError(error: any, log?: Log): boolean {
  const supError = toSupError(error);
  if (supError) {
    if (log) {
      log.error({ supError });
    }
    if (supError.isMessageUserFriendly) {
      dispatchUiNotification.error(supError.message);
    }
    return true;
  }
  return false;
}

/**
 * If error is SlackApiError: logs, alerts and returns true.
 * If not SlackApiError returns false.
 */
export function handleSlackApiError(error: any, log?: Log): boolean {
  const slackApiError = toSlackApiError(error);
  if (slackApiError) {
    if (log) {
      log.error({ slackApiError });
    }
    dispatchUiNotification.error("Slack API error", slackApiError.error);
    return true;
  }
  return false;
}

export function handleMirageError(error: any, log?: Log): boolean {
  if (error.message && error.message.startsWith("Mirage:")) {
    if (log) {
      log.error("MirageError", { error });
    }
    if (process.env.NODE_ENV !== "development") {
      throw new SupError("Mirage JS only allowed in development mode");
    }
    return true;
  }
  return false;
}

/**
 * Returns SupError or undefined if error is not SupError
 */
export function toSupError(error: any): SupError | undefined {
  const className = error.constructor.name;
  if (error instanceof SupError && className == SupError.name) {
    return error as SupError;
  }
  return undefined;
}

export function toApiError(error: any): ApiError {
  const axiosError = error as AxiosError<any>;
  const errors = [axiosError.response?.data.message];
  return new ApiError(
    "Suptask REST API error",
    errors,
    axiosError.response?.status
  );
}

export function toSlackApiError(error: any): SlackApiError | undefined {
  const className = error.constructor.name;
  if (error instanceof SlackApiError && className == SlackApiError.name) {
    return error as SlackApiError;
  }
  return undefined;
}
