import { computed } from "@vue/composition-api";
import { useStoreGetters as useAuthGetters } from "/@/store/modules/auth.use";

import { expiresInDays, expiresInDaysSeverityClass } from "@/helpers/dates";
import { useInterval } from "@vueuse/core";

import differenceInCalendarDays from "date-fns/differenceInCalendarDays";

export default function useTrial() {
  const { user } = useAuthGetters();

  const effectCounter = useInterval(60 * 1000);

  const trialExpired = computed(() => {
    // use `effectCounter` only to recompute on interval
    if (effectCounter.value < 0) return;

    const isTrial = user.value?.team?.planSubscription?.isTrial;
    const lastTrialDayStr = user.value?.team?.lastTrialDay;
    if (!isTrial || !lastTrialDayStr) return undefined;

    const lastTrialDay = new Date(lastTrialDayStr);
    const diff = differenceInCalendarDays(lastTrialDay, new Date());
    return diff < 0;
  });

  /**
   * Returns "expires in N days" or "expires today" or "expired N days ago"
   */
  const trialExpiresInDays = computed(() => {
    // use `effectCounter` only to recompute on interval
    if (effectCounter.value < 0) return;

    const isTrial = user.value?.team?.planSubscription?.isTrial;
    const lastTrialDayStr = user.value?.team?.lastTrialDay;
    if (!isTrial || !lastTrialDayStr) return undefined;

    const lastTrialDay = new Date(lastTrialDayStr);
    return expiresInDays(lastTrialDay).str;
  });

  /**
   * Returns { num: N, str: "N days" } or { num: 0, str: "today" }
   */
  const trialValidDays = computed(() => {
    // use `effectCounter` only to recompute on interval
    if (effectCounter.value < 0) return;

    const isTrial = user.value?.team?.planSubscription?.isTrial;
    const lastTrialDayStr = user.value?.team?.lastTrialDay;
    if (!isTrial || !lastTrialDayStr) return undefined;

    const lastTrialDay = new Date(lastTrialDayStr);
    return expiresInDays(lastTrialDay, false, false);
  });

  /**
   * Returns css severity class depending on how soon trial expires
   */
  const trialExpiresSeverityClass = computed(() => {
    // use `effectCounter` only to recompute on interval
    if (effectCounter.value < 0) return;

    const isTrial = user.value?.team?.planSubscription?.isTrial;
    const lastTrialDayStr = user.value?.team?.lastTrialDay;
    if (!isTrial || !lastTrialDayStr) return undefined;

    const lastTrialDay = new Date(lastTrialDayStr);

    return expiresInDaysSeverityClass(lastTrialDay, 7, 14);
  });

  return {
    trialExpired,
    trialExpiresInDays,
    trialValidDays,
    trialExpiresSeverityClass
  };
}
