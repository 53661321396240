import { getCurrentInstance, watch, shallowRef } from "@vue/composition-api";
import { Route } from "vue-router";

export default function useRouter() {
  const vm = getCurrentInstance();
  if (!vm) throw new Error("useRouter must be called in setup() method");

  const route = shallowRef<Route>(vm.proxy.$route);

  watch(
    () => vm.proxy.$route,
    r => {
      route.value = r as Route;
    }
  );

  return { route, router: vm.proxy.$router };
}
