import {
  computed,
  getCurrentInstance,
  WritableComputedRef
} from "@vue/composition-api";

/**
 * Helper to wrap a component property into a `ref` (technically - `computed`).
 * This is needed to implement a "two-way binding" for a property.
 *
 * Why you need this? In short: mutating a prop directly considered as an anti-pattern.
 *
 * Example: consider a child component with a property aProperty and you need to change it's value.
 * If you do it directly, you get the following warning:
 * `[Vue warn]: Avoid mutating a prop directly since the value will be overwritten whenever the parent component re-renders. Instead, use a data or computed property based on the prop's value. Prop being mutated: "aProperty"`
 *
 * Solution:
 * 1. Wrap the aProperty into an aPropertyState computed with getter returning the value
 *  and setter emitting the new value in the pattern of `update:aProperty`.
 * Alternative a):
 *    const aPropertyState = computed({
 *      get() {
 *        return props.aProperty
 *      },
 *      set(newValue) {
 *        context.emit('update:aProperty', newValue)
 *      }
 *    })
 * Alternative b), using usePropState:
 *    const aPropertyState = usePropState(props, 'aProperty')
 *
 * then use this aPropertyState computed in the child component and mutate it.
 * 2. Set the prop in parent component and handle `update:aProperty` event:
 * Directly:
 *    <child-component :aProperty="val" update:aProperty="val = $event"></child-component>
 * Or using vue .sync syntax sugar:
 *    <child-component :aProperty.sync="val"></child-component>
 * See more examples:
 *  o-input-emails.vue
 *
 * @param props - reactive props property of the component
 * @param propName - property name
 * @returns Computed for the props[propName] property emitting `update:${propName}`
 */
export default function usePropState<T>(
  props: any,
  propName: string
): WritableComputedRef<T> {
  const vm = getCurrentInstance();
  if (!vm) throw new Error("useRouter must be called in setup() method");

  return computed({
    get() {
      return props[propName] as T;
    },
    set(value) {
      vm.proxy.$emit(`update:${propName}`, value);
    }
  });
}
