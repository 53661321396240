import { Amplify, Auth } from "aws-amplify";
import AwsExports from "/@/aws-exports";

function withoutTrailingSlash(str: string | undefined) {
  if (!str) return "";
  return str.replace(/\/$/, "");
}

let redirectSignInUrl = process.env.VUE_APP_REDIRECT_SIGN_IN;
let redirectSignOutUrl = process.env.VUE_APP_REDIRECT_SIGN_OUT;
const currentUrl = window.location.origin + window.location.pathname;
if (
  withoutTrailingSlash(currentUrl) ==
  withoutTrailingSlash(process.env.VUE_APP_REDIRECT_SIGN_IN_WIDGET_ZENDESK)
) {
  redirectSignInUrl = process.env.VUE_APP_REDIRECT_SIGN_IN_WIDGET_ZENDESK;
  redirectSignOutUrl = process.env.VUE_APP_REDIRECT_SIGN_OUT_WIDGET_ZENDESK;
}

const exports = AwsExports as any;
exports.oauth.redirectSignIn = redirectSignInUrl;
exports.oauth.redirectSignOut = redirectSignOutUrl;
exports.oauth.domain = process.env.VUE_APP_OAUTH_HOSTED_UI_DOMAIN;
exports.oauth.responseType = "code"; // required, so it fetches token after getting the code!
Amplify.configure(exports);

// Use ID token instead of access token in API calls to enable owner auth using teamId
Amplify.configure({
  API: {
    /* eslint-disable */
    graphql_headers: async () => {
      const session = await Auth.currentSession();
      return {
        Authorization: session.getIdToken().getJwtToken()
      };
    }
  }
});
