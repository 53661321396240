























import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "a-checkbox",
  props: {
    label: String,
    value: Boolean,
    trueValue: { type: Boolean, default: true },
    falseValue: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  emits: ["input"]
});
