/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createTeam = /* GraphQL */ `mutation CreateTeam(
  $input: CreateTeamInput!
  $condition: ModelTeamConditionInput
) {
  createTeam(input: $input, condition: $condition) {
    botUserId
    id
    teamId
    installed
    ticketNumber
    planSubscriptionId
    planSubscription {
      id
      planId
      plan {
        id
        name
        formsLimit
        customFieldsLimit
        code
        createdAt
        updatedAt
        __typename
      }
      internalDescription
      billingPeriod
      billingPeriodPriceUsd
      billingPeriodPriceLabel
      isCustom
      isFree
      isTrial
      minAgents
      public
      createdAt
      updatedAt
      __typename
    }
    lastTrialDay
    iiZendesk
    agents
    autoCreateChannels
    features
    planOffer
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTeamMutationVariables,
  APITypes.CreateTeamMutation
>;
export const updateTeam = /* GraphQL */ `mutation UpdateTeam(
  $input: UpdateTeamInput!
  $condition: ModelTeamConditionInput
) {
  updateTeam(input: $input, condition: $condition) {
    botUserId
    id
    teamId
    installed
    ticketNumber
    planSubscriptionId
    planSubscription {
      id
      planId
      plan {
        id
        name
        formsLimit
        customFieldsLimit
        code
        createdAt
        updatedAt
        __typename
      }
      internalDescription
      billingPeriod
      billingPeriodPriceUsd
      billingPeriodPriceLabel
      isCustom
      isFree
      isTrial
      minAgents
      public
      createdAt
      updatedAt
      __typename
    }
    lastTrialDay
    iiZendesk
    agents
    autoCreateChannels
    features
    planOffer
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTeamMutationVariables,
  APITypes.UpdateTeamMutation
>;
export const deleteTeam = /* GraphQL */ `mutation DeleteTeam(
  $input: DeleteTeamInput!
  $condition: ModelTeamConditionInput
) {
  deleteTeam(input: $input, condition: $condition) {
    botUserId
    id
    teamId
    installed
    ticketNumber
    planSubscriptionId
    planSubscription {
      id
      planId
      plan {
        id
        name
        formsLimit
        customFieldsLimit
        code
        createdAt
        updatedAt
        __typename
      }
      internalDescription
      billingPeriod
      billingPeriodPriceUsd
      billingPeriodPriceLabel
      isCustom
      isFree
      isTrial
      minAgents
      public
      createdAt
      updatedAt
      __typename
    }
    lastTrialDay
    iiZendesk
    agents
    autoCreateChannels
    features
    planOffer
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTeamMutationVariables,
  APITypes.DeleteTeamMutation
>;
export const createQueue = /* GraphQL */ `mutation CreateQueue(
  $input: CreateQueueInput!
  $condition: ModelQueueConditionInput
) {
  createQueue(input: $input, condition: $condition) {
    format
    id
    integrationChannels
    name
    teamId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateQueueMutationVariables,
  APITypes.CreateQueueMutation
>;
export const updateQueue = /* GraphQL */ `mutation UpdateQueue(
  $input: UpdateQueueInput!
  $condition: ModelQueueConditionInput
) {
  updateQueue(input: $input, condition: $condition) {
    format
    id
    integrationChannels
    name
    teamId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateQueueMutationVariables,
  APITypes.UpdateQueueMutation
>;
export const deleteQueue = /* GraphQL */ `mutation DeleteQueue(
  $input: DeleteQueueInput!
  $condition: ModelQueueConditionInput
) {
  deleteQueue(input: $input, condition: $condition) {
    format
    id
    integrationChannels
    name
    teamId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteQueueMutationVariables,
  APITypes.DeleteQueueMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    agentQueues
    id
    scope
    teamId
    token_type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    agentQueues
    id
    scope
    teamId
    token_type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    agentQueues
    id
    scope
    teamId
    token_type
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createTicket = /* GraphQL */ `mutation CreateTicket(
  $input: CreateTicketInput!
  $condition: ModelTicketConditionInput
) {
  createTicket(input: $input, condition: $condition) {
    archived
    assignee
    createdAt
    createdBy
    description
    formId
    form {
      id
      createdAt
      createdBy
      displayChannels
      hidden
      fields
      name
      enabledInDm
      queueId
      queue {
        format
        id
        integrationChannels
        name
        teamId
        createdAt
        updatedAt
        __typename
      }
      teamId
      emailPartitionKey
      email
      updatedAt
      updatedBy
      __typename
    }
    formValues
    id
    messageResponderChannel
    messageResponderId
    messageRequesterChannel
    messageRequesterId
    priority
    queueId
    queue {
      format
      id
      integrationChannels
      name
      teamId
      createdAt
      updatedAt
      __typename
    }
    requesterId
    requester {
      agentQueues
      id
      scope
      teamId
      token_type
      createdAt
      updatedAt
      __typename
    }
    status
    team_archived_hash
    teamTicketId
    teamId
    team {
      botUserId
      id
      teamId
      installed
      ticketNumber
      planSubscriptionId
      planSubscription {
        id
        planId
        internalDescription
        billingPeriod
        billingPeriodPriceUsd
        billingPeriodPriceLabel
        isCustom
        isFree
        isTrial
        minAgents
        public
        createdAt
        updatedAt
        __typename
      }
      lastTrialDay
      iiZendesk
      agents
      autoCreateChannels
      features
      planOffer
      createdAt
      updatedAt
      __typename
    }
    teamName
    ticketNumber
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTicketMutationVariables,
  APITypes.CreateTicketMutation
>;
export const updateTicket = /* GraphQL */ `mutation UpdateTicket(
  $input: UpdateTicketInput!
  $condition: ModelTicketConditionInput
) {
  updateTicket(input: $input, condition: $condition) {
    archived
    assignee
    createdAt
    createdBy
    description
    formId
    form {
      id
      createdAt
      createdBy
      displayChannels
      hidden
      fields
      name
      enabledInDm
      queueId
      queue {
        format
        id
        integrationChannels
        name
        teamId
        createdAt
        updatedAt
        __typename
      }
      teamId
      emailPartitionKey
      email
      updatedAt
      updatedBy
      __typename
    }
    formValues
    id
    messageResponderChannel
    messageResponderId
    messageRequesterChannel
    messageRequesterId
    priority
    queueId
    queue {
      format
      id
      integrationChannels
      name
      teamId
      createdAt
      updatedAt
      __typename
    }
    requesterId
    requester {
      agentQueues
      id
      scope
      teamId
      token_type
      createdAt
      updatedAt
      __typename
    }
    status
    team_archived_hash
    teamTicketId
    teamId
    team {
      botUserId
      id
      teamId
      installed
      ticketNumber
      planSubscriptionId
      planSubscription {
        id
        planId
        internalDescription
        billingPeriod
        billingPeriodPriceUsd
        billingPeriodPriceLabel
        isCustom
        isFree
        isTrial
        minAgents
        public
        createdAt
        updatedAt
        __typename
      }
      lastTrialDay
      iiZendesk
      agents
      autoCreateChannels
      features
      planOffer
      createdAt
      updatedAt
      __typename
    }
    teamName
    ticketNumber
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTicketMutationVariables,
  APITypes.UpdateTicketMutation
>;
export const deleteTicket = /* GraphQL */ `mutation DeleteTicket(
  $input: DeleteTicketInput!
  $condition: ModelTicketConditionInput
) {
  deleteTicket(input: $input, condition: $condition) {
    archived
    assignee
    createdAt
    createdBy
    description
    formId
    form {
      id
      createdAt
      createdBy
      displayChannels
      hidden
      fields
      name
      enabledInDm
      queueId
      queue {
        format
        id
        integrationChannels
        name
        teamId
        createdAt
        updatedAt
        __typename
      }
      teamId
      emailPartitionKey
      email
      updatedAt
      updatedBy
      __typename
    }
    formValues
    id
    messageResponderChannel
    messageResponderId
    messageRequesterChannel
    messageRequesterId
    priority
    queueId
    queue {
      format
      id
      integrationChannels
      name
      teamId
      createdAt
      updatedAt
      __typename
    }
    requesterId
    requester {
      agentQueues
      id
      scope
      teamId
      token_type
      createdAt
      updatedAt
      __typename
    }
    status
    team_archived_hash
    teamTicketId
    teamId
    team {
      botUserId
      id
      teamId
      installed
      ticketNumber
      planSubscriptionId
      planSubscription {
        id
        planId
        internalDescription
        billingPeriod
        billingPeriodPriceUsd
        billingPeriodPriceLabel
        isCustom
        isFree
        isTrial
        minAgents
        public
        createdAt
        updatedAt
        __typename
      }
      lastTrialDay
      iiZendesk
      agents
      autoCreateChannels
      features
      planOffer
      createdAt
      updatedAt
      __typename
    }
    teamName
    ticketNumber
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTicketMutationVariables,
  APITypes.DeleteTicketMutation
>;
export const createForm = /* GraphQL */ `mutation CreateForm(
  $input: CreateFormInput!
  $condition: ModelFormConditionInput
) {
  createForm(input: $input, condition: $condition) {
    id
    createdAt
    createdBy
    displayChannels
    hidden
    fields
    name
    enabledInDm
    queueId
    queue {
      format
      id
      integrationChannels
      name
      teamId
      createdAt
      updatedAt
      __typename
    }
    teamId
    emailPartitionKey
    email
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFormMutationVariables,
  APITypes.CreateFormMutation
>;
export const updateForm = /* GraphQL */ `mutation UpdateForm(
  $input: UpdateFormInput!
  $condition: ModelFormConditionInput
) {
  updateForm(input: $input, condition: $condition) {
    id
    createdAt
    createdBy
    displayChannels
    hidden
    fields
    name
    enabledInDm
    queueId
    queue {
      format
      id
      integrationChannels
      name
      teamId
      createdAt
      updatedAt
      __typename
    }
    teamId
    emailPartitionKey
    email
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFormMutationVariables,
  APITypes.UpdateFormMutation
>;
export const deleteForm = /* GraphQL */ `mutation DeleteForm(
  $input: DeleteFormInput!
  $condition: ModelFormConditionInput
) {
  deleteForm(input: $input, condition: $condition) {
    id
    createdAt
    createdBy
    displayChannels
    hidden
    fields
    name
    enabledInDm
    queueId
    queue {
      format
      id
      integrationChannels
      name
      teamId
      createdAt
      updatedAt
      __typename
    }
    teamId
    emailPartitionKey
    email
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFormMutationVariables,
  APITypes.DeleteFormMutation
>;
export const createField = /* GraphQL */ `mutation CreateField(
  $input: CreateFieldInput!
  $condition: ModelFieldConditionInput
) {
  createField(input: $input, condition: $condition) {
    id
    createdAt
    createdBy
    dataSource
    dataSourceType
    label
    name
    teamId
    uiType
    multi
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFieldMutationVariables,
  APITypes.CreateFieldMutation
>;
export const updateField = /* GraphQL */ `mutation UpdateField(
  $input: UpdateFieldInput!
  $condition: ModelFieldConditionInput
) {
  updateField(input: $input, condition: $condition) {
    id
    createdAt
    createdBy
    dataSource
    dataSourceType
    label
    name
    teamId
    uiType
    multi
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFieldMutationVariables,
  APITypes.UpdateFieldMutation
>;
export const deleteField = /* GraphQL */ `mutation DeleteField(
  $input: DeleteFieldInput!
  $condition: ModelFieldConditionInput
) {
  deleteField(input: $input, condition: $condition) {
    id
    createdAt
    createdBy
    dataSource
    dataSourceType
    label
    name
    teamId
    uiType
    multi
    updatedAt
    updatedBy
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFieldMutationVariables,
  APITypes.DeleteFieldMutation
>;
export const createPlan = /* GraphQL */ `mutation CreatePlan(
  $input: CreatePlanInput!
  $condition: ModelPlanConditionInput
) {
  createPlan(input: $input, condition: $condition) {
    id
    name
    formsLimit
    customFieldsLimit
    code
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePlanMutationVariables,
  APITypes.CreatePlanMutation
>;
export const updatePlan = /* GraphQL */ `mutation UpdatePlan(
  $input: UpdatePlanInput!
  $condition: ModelPlanConditionInput
) {
  updatePlan(input: $input, condition: $condition) {
    id
    name
    formsLimit
    customFieldsLimit
    code
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePlanMutationVariables,
  APITypes.UpdatePlanMutation
>;
export const deletePlan = /* GraphQL */ `mutation DeletePlan(
  $input: DeletePlanInput!
  $condition: ModelPlanConditionInput
) {
  deletePlan(input: $input, condition: $condition) {
    id
    name
    formsLimit
    customFieldsLimit
    code
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePlanMutationVariables,
  APITypes.DeletePlanMutation
>;
export const createPlanSubscription = /* GraphQL */ `mutation CreatePlanSubscription(
  $input: CreatePlanSubscriptionInput!
  $condition: ModelPlanSubscriptionConditionInput
) {
  createPlanSubscription(input: $input, condition: $condition) {
    id
    planId
    plan {
      id
      name
      formsLimit
      customFieldsLimit
      code
      createdAt
      updatedAt
      __typename
    }
    internalDescription
    billingPeriod
    billingPeriodPriceUsd
    billingPeriodPriceLabel
    isCustom
    isFree
    isTrial
    minAgents
    public
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePlanSubscriptionMutationVariables,
  APITypes.CreatePlanSubscriptionMutation
>;
export const updatePlanSubscription = /* GraphQL */ `mutation UpdatePlanSubscription(
  $input: UpdatePlanSubscriptionInput!
  $condition: ModelPlanSubscriptionConditionInput
) {
  updatePlanSubscription(input: $input, condition: $condition) {
    id
    planId
    plan {
      id
      name
      formsLimit
      customFieldsLimit
      code
      createdAt
      updatedAt
      __typename
    }
    internalDescription
    billingPeriod
    billingPeriodPriceUsd
    billingPeriodPriceLabel
    isCustom
    isFree
    isTrial
    minAgents
    public
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePlanSubscriptionMutationVariables,
  APITypes.UpdatePlanSubscriptionMutation
>;
export const deletePlanSubscription = /* GraphQL */ `mutation DeletePlanSubscription(
  $input: DeletePlanSubscriptionInput!
  $condition: ModelPlanSubscriptionConditionInput
) {
  deletePlanSubscription(input: $input, condition: $condition) {
    id
    planId
    plan {
      id
      name
      formsLimit
      customFieldsLimit
      code
      createdAt
      updatedAt
      __typename
    }
    internalDescription
    billingPeriod
    billingPeriodPriceUsd
    billingPeriodPriceLabel
    isCustom
    isFree
    isTrial
    minAgents
    public
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePlanSubscriptionMutationVariables,
  APITypes.DeletePlanSubscriptionMutation
>;
