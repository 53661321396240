import { v4 as uuidv4 } from "uuid";
import * as Sentry from "@sentry/browser";

export class ContextTracer implements ContextTracer {
  private readonly name: string;
  public readonly uid: string;

  constructor({ name, uid }: { name: string; uid?: string }) {
    this.name = name;
    this.uid = uid ?? uuidv4();
  }

  public write({
    message,
    details,
    level = "debug"
  }: {
    message: string;
    details?: string;
    level?: Sentry.SeverityLevel;
  }) {
    Sentry.withScope(scope => {
      scope.setLevel(level);
      scope.setTag("context.uid", this.uid);
      scope.setTag("context.name", this.name);
      scope.setContext("context-tracer-details", {
        "x-message": message,
        "x-details": details
      });
      scope.setFingerprint(["ContextTracer", this.name, message]);
      Sentry.captureMessage(`ContextTracer:${this.name}:${message}`);
    });
  }
}
