import Vue from "vue";

// Register a global custom directive called `v-focus`
Vue.directive("focus", {
  // When the bound element is inserted into the DOM...
  inserted: function(el, value) {
    // Focus the element
    if (value.value) {
      el.focus();
      const elInput = el as HTMLInputElement;

      elInput?.select?.();
    }
  }
});

/**
 * v-visible directive transforms the directive's value to the 'visibility' style
 */
Vue.directive("visible", function(el, binding) {
  el.style.visibility = binding.value ? "visible" : "hidden";
});
