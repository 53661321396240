













































import { defineComponent, ref, watch } from "@vue/composition-api";
import { useStoreGetters as useAuthGetters } from "/@/store/modules/auth.use";
import useUser from "@/composables/useUser";
import RoundButton from "/@/components/round-icon.vue";
import DropdownMenuItem from "/@/components/dropdown-menu-item.vue";
import useRouter from "/@/composables/_useRouter";

const dropdownWrapperRef = ref(null);
import { onClickOutside } from "@vueuse/core";
import { NavItem } from "@/core/navigation";

export default defineComponent({
  name: "settings-menu",
  components: {
    RoundButton,
    DropdownMenuItem
  },
  props: {
    navItem: {
      type: Object as () => NavItem,
      required: false
    }
  },
  setup(props) {
    const { user } = useAuthGetters();
    const settingsDropdownActive = ref(false);
    const { displayRole } = useUser();

    const buttonBg = ref("transparent");

    const router = useRouter();

    const toggleDropdown = () => {
      settingsDropdownActive.value = !settingsDropdownActive.value;
    };

    onClickOutside(dropdownWrapperRef, () => {
      settingsDropdownActive.value = false;
    });

    watch(
      [settingsDropdownActive, () => router.route.value.fullPath],
      ([newSettingsDropdownActive, newRote]) => {
        buttonBg.value =
          newSettingsDropdownActive ||
          (props.navItem && newRote.startsWith(props.navItem.path))
            ? "var(--s-color-bg-selected)"
            : "transparent";
      },
      { immediate: true }
    );

    return {
      user,
      settingsDropdownActive,
      displayRole,
      toggleDropdown,
      dropdownWrapperRef,
      buttonBg
    };
  }
});
