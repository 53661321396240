import Vue from "vue";
import { SupError } from "@/models/Errors";
import { TeamRepo } from "../repos/TeamRepo";
import { AutoCreateChannels } from "../models/AutoCreateChannels";
import { FormViewModel } from "@/models/FormModel";

export default class TeamService {
  public static async getAutoCreateChannels(
    teamId: string
  ): Promise<AutoCreateChannels> {
    Vue.$log.debug("TeamService.getAutoCreateChannels()");
    const dbTeam = await TeamRepo.getById(teamId);
    if (!dbTeam) {
      throw new SupError(
        "Could not load autocreate channels: team not found",
        true
      );
    }

    return dbTeam?.autoCreateChannels
      ? JSON.parse(dbTeam?.autoCreateChannels)
      : {};
  }

  /**
   * Updates auto create aspects for existing form:
   *  - adds/updates corresponding records in Team.autoCreateChannels if form is auto-create
   *  - deletes corresponding records in Team.autoCreateChannels form is not auto-create anymore
   */
  public static async saveAutoCreateExistingForm(
    teamId: string,
    form: FormViewModel,
    queueId: string
  ): Promise<void> {
    Vue.$log.debug("TeamService.saveAutoCreateExistingForm() start");
    if (form.id == undefined) {
      throw new SupError(
        "Could not save autocreate channels: form not saved",
        true
      );
    }
    if (!form.requestChannels) {
      throw new SupError(
        "Could not save autocreate channels: form doesn't have Channels",
        true
      );
    }
    const autoCreateChannels = await TeamService.getAutoCreateChannels(teamId);
    // auto-create entries without the current form
    const keepEntries = Object.entries(autoCreateChannels).filter(
      pair => pair[1].formId != form.id
    );
    // auto-create entries to save (nothing if the form is not auto-create anymore)
    const newEntries = form.autoCreate
      ? form.requestChannels.map(requestChannelId => {
          return [requestChannelId, { formId: form.id as string, queueId }];
        })
      : [];

    const newAutoCreateChannels = Object.fromEntries([
      ...keepEntries,
      ...newEntries
    ]);

    const putTeamData: any = {
      id: teamId,
      autoCreateChannels: JSON.stringify(newAutoCreateChannels)
    };

    Vue.$log.debug(
      "TeamService.saveAutoCreateExistingForm() put Team.autoCreateChannels = ",
      putTeamData.autoCreateChannels
    );

    await TeamRepo.put(putTeamData);
  }

  /**
   * Deletes auto create aspects (records in Team.autoCreateChannels) for the form being deleted
   */
  public static async saveAutoCreateDeletedForm(
    teamId: string,
    formId: string
  ): Promise<void> {
    Vue.$log.debug("TeamService.saveAutoCreateDeletedForm() start");

    const autoCreateChannels = await TeamService.getAutoCreateChannels(teamId);
    // auto-create entries without the current form
    const keepEntries = Object.entries(autoCreateChannels).filter(
      pair => pair[1].formId != formId
    );

    const newAutoCreateChannels = Object.fromEntries([...keepEntries]);

    const putTeamData: any = {
      id: teamId,
      autoCreateChannels: JSON.stringify(newAutoCreateChannels)
    };

    Vue.$log.debug(
      "TeamService.saveAutoCreateDeletedForm() put Team.autoCreateChannels = ",
      putTeamData.autoCreateChannels
    );

    await TeamRepo.put(putTeamData);
  }
}
