







import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup() {
    const copyrightYear = new Date().getFullYear();

    return { copyrightYear };
  }
});
