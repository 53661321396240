




import Vue from "vue";
import { defineComponent, ref } from "@vue/composition-api";
import useRouter from "/@/composables/_useRouter";

export default defineComponent({
  setup() {
    const ready = ref(false);
    const { router } = useRouter();
    router.onReady(async () => {
      ready.value = true;
      showSplash(false);
    });

    Vue.$log.info("App: setup()");

    function showSplash(show: boolean) {
      const htmlElement = document.documentElement;
      if (show) {
        htmlElement.classList.add("loading");
      } else {
        htmlElement.classList.remove("loading");
      }
    }

    return {
      ready
    };
  }
});
