<template>
  <div class="left-menu__wrapper" v-if="visibleSubItems.length > 0">
    <p class="left-menu__text">{{ mainRouteLabel }}</p>
    <ul class="left-menu">
      <a-router-tab-link
        v-for="route in visibleSubItems"
        :key="route.path"
        :to="route.path"
        :external="route.target == '_blank'"
        class="left-menu__item"
      >
        <font-awesome-icon v-if="route.icon" :icon="route.icon" />
        {{ route.label }}
        <font-awesome-icon
          v-if="route.target == '_blank'"
          size="sm"
          icon="external-link-alt"
        />
      </a-router-tab-link>
    </ul>
  </div>
  <div v-else></div>
</template>

<script>
import { computed, defineComponent, ref } from "@vue/composition-api";
import useRouter from "/@/composables/_useRouter";

export default defineComponent({
  name: "o-left-menu",
  props: {
    items: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  setup(props) {
    const { items } = props;
    const router = useRouter();
    const route = computed(() => router.route.value.fullPath);
    const mainRouteLabel = ref("");
    const themePath = "/theme";
    const settingsPath = "/account";

    const visibleItems = items.filter(item => {
      if (
        item.path.startsWith(themePath) ||
        item.path.startsWith(settingsPath)
      ) {
        return true;
      }

      return !item.hidden;
    });
    const visibleSubItems = computed(() => {
      const findedRoute = visibleItems.find(item =>
        route.value.startsWith(item.path)
      );

      if (findedRoute) {
        mainRouteLabel.value = findedRoute.label;
        return findedRoute.children?.filter(item => !item.hidden) ?? [];
      }

      return [];
    });

    return {
      visibleSubItems,
      mainRouteLabel
    };
  }
});
</script>

<style lang="scss" scoped>
.left-menu__wrapper {
  max-width: 240px;
  width: 100%;
  margin-right: 32px;
  padding: 32px 0 1.75rem;
  border-right: 1px solid var(--s-color-border-dropdown);
}

.left-menu {
  margin-top: 16px;
}

.left-menu__text {
  @extend .s-text-title-s;
  padding-left: 12px;
  color: var(--s-color-text-passive);
}

.left-menu__item::v-deep {
  a {
    padding: 8px 16px 8px 12px;
    display: flex;
    align-items: center;
    gap: 0 8px;
    color: var(--s-color-text);
    text-decoration: none;
    @extend .s-text-label-l;
  }

  &.is-active {
    a {
      color: var(--s-color-left-menu-link--active);
    }
  }
}

@media screen and (max-width: $width-to-show-burger) {
  .left-menu__wrapper {
    display: none;
  }
}
</style>
