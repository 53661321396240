import Vue from "vue";
import axios, { AxiosError, AxiosResponse, CanceledError } from "axios";
import qs from "qs";
import { SlackApiError, SupError, SlackErrorMessage } from "@/models/Errors";
import { sleep } from "@/helpers/promises";
import router from "@/router";

interface SlackResponse {
  ok: boolean;
  error?: SlackErrorMessage;
  response_metadata?: {
    next_cursor: string;
  };
}

let fetchApiSlackCallId = 0;

const abortController = new AbortController();

export const fetchApiSlack = async <T>(
  url: string,
  token: string,
  data: any,
  itemsField: string,
  limit = 500,
  maxCalls = 300,
  totalLimit = 5000,
  intermediateResultCallback?: (items: T[], allLoaded: boolean) => void,
  sleepBeforeNextPageMs = 3000 // 3 sec corresponds to Web API Tier 2, see https://api.slack.com/apis/rate-limits
) => {
  fetchApiSlackCallId++;

  const items: Array<T> = [];
  let nextCursor: string | null | undefined = "";
  let callsCount = 0;
  let rateLimitRetriesCount = 0;

  Vue.$log.debug(
    `fetchApiSlack ${fetchApiSlackCallId}. Url: ${url}. Start fetching.`
  );

  do {
    callsCount++;

    if (nextCursor) {
      // pause all consecutive paging calls
      // as recommended https://api.slack.com/docs/rate-limits
      await sleep(sleepBeforeNextPageMs);
    }

    const dataWithTokenAndCursor: unknown = {
      token,
      ...data,
      limit,
      cursor: nextCursor
    };

    try {
      const response = await axios.post<SlackResponse>(
        url,
        qs.stringify(dataWithTokenAndCursor),
        {
          signal: abortController.signal
        }
      );

      if (response.data.ok) {
        const intermediateItems = (response.data as any)[itemsField];
        Vue.$log.debug(
          `fetchApiSlack ${fetchApiSlackCallId}. Call number ${callsCount}. Fetched ${intermediateItems.length} items`
        );

        items.push(...intermediateItems);
        nextCursor = response.data.response_metadata?.next_cursor;
        intermediateResultCallback?.(intermediateItems, !nextCursor);
      } else {
        if (response.data.error) {
          const handled = handleSlackApiError(response);
          if (!handled) {
            throw new SlackApiError(response.data.error);
          }
        } else {
          throw new SupError("Unknown error in fetchApiSlack", false);
        }
      }
    } catch (e) {
      if (axios.isCancel(e)) {
        const axiosCanceledError = e as CanceledError<any>;
        Vue.$log.debug(
          `Canceled call to ${axiosCanceledError.config?.url}`,
          axiosCanceledError
        );
      } else {
        const axiosError = e as AxiosError<SlackResponse>;
        if (await sleepIfRateLimit(axiosError, rateLimitRetriesCount)) {
          rateLimitRetriesCount++;
          continue;
        }
        throw e;
      }
    }
  } while (
    nextCursor !== "" &&
    callsCount < maxCalls &&
    items.length < totalLimit
  );

  if (nextCursor !== "") {
    // not all items has been fetched due to maxCalls or totalLimit
    // Log error about that and pretend all good.
    // TODO: There must be better messaging, or diferent approach with limits
    Vue.$log.error(
      `Too many items to fetch. Stopped after ${items.length} items - only them will be available.`
    );
  }

  Vue.$log.debug(
    `fetchApiSlack ${fetchApiSlackCallId}. Url: ${url}. Finished fetching.`
  );

  return items;
};

async function sleepIfRateLimit(
  axiosError: AxiosError<SlackResponse>,
  rateLimitRetriesCount: number
): Promise<boolean> {
  if (
    axiosError.response &&
    !axiosError.response.data.ok &&
    axiosError.response.data.error === "ratelimited"
  ) {
    const recommendedRetryAfterSec = parseInt(
      axiosError.response.headers["retry-after"]
    );
    if (!isNaN(recommendedRetryAfterSec)) {
      let retryAfterSec = recommendedRetryAfterSec; // 30 sec
      switch (rateLimitRetriesCount) {
        case 0:
          retryAfterSec = Math.floor(recommendedRetryAfterSec / 3); // 10 sec
          break;
        case 1:
          retryAfterSec = Math.floor((recommendedRetryAfterSec / 3) * 2); // 20 sec
          break;
      }

      Vue.$log.debug(
        `fetchApiSlack. Reached rate limit (${rateLimitRetriesCount}). Will retry in ${retryAfterSec} seconds.`
      );
      await sleep(retryAfterSec * 1000);
      Vue.$log.debug(`fetchApiSlack. Retry after rate limit.`);
      return true;
    }
  }
  return false;
}

export const fetchApiSlackV2 = async <T>({
  url,
  token,
  data = {},
  itemsField,
  limit = 500,
  maxCalls = 300,
  totalLimit = 5000,
  intermediateResultCallback = undefined,
  sleepBeforeNextPageMs = 3000
}: {
  url: string;
  token: string;
  data?: any;
  itemsField: string;
  limit?: number;
  maxCalls?: number;
  totalLimit?: number;
  intermediateResultCallback?: (items: T[], allLoaded: boolean) => void;
  sleepBeforeNextPageMs?: number;
}) => {
  return await fetchApiSlack(
    url,
    token,
    data,
    itemsField,
    limit,
    maxCalls,
    totalLimit,
    intermediateResultCallback,
    sleepBeforeNextPageMs
  );
};

/**
 * Handle slack error.
 * Returns true if could handle or false if could not.
 */
function handleSlackApiError(response: AxiosResponse<SlackResponse, any>) {
  const slackError = response.data.error;
  switch (response.data.error) {
    case "token_revoked":
    case "token_expired":
      Vue.$log.debug(
        `Slack error: ${slackError} when calling ${response.config.url}. Navigate to slack error page. All calls to Slack will be canceled.`
      );
      abortController.abort(); // abort all slack calls at the moment
      router.push({ name: `slack-error.${slackError}` });
      break;
    default:
      return false;
  }
  return true;
}
