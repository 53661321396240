export enum ChannelTypeEnum {
  Unknown,
  Channel,
  Group
}

export interface ChannelSlackModel {
  id: string;
  name: string;
  is_private: boolean;
}

export interface ChannelViewModel {
  id: string;
  name: string;
  private: boolean;
  icon?: string;
}

// Model for "All channels" item user could choose as form's Channel.
// Now this feature is deprecated, so user can't choose this option,
// But we stil support already existing forms with this option
// For more details see #344
export const allChannelsItem: ChannelViewModel = {
  id: "SUP_ALL_CHANNELS",
  name: "All channels",
  private: false,
  icon: "check-double"
};

export const channelPrivacyTextIcons = new Map([
  [false, "#"],
  [true, "🔒"]
]);

export const channelPrivacyFontAwesomeIcons = new Map([
  [false, "hashtag"],
  [true, "lock"]
]);

export function slackModelToViewModel(
  model: ChannelSlackModel
): ChannelViewModel {
  return {
    id: model.id,
    name: model.name,
    private: model.is_private,
    icon: channelPrivacyFontAwesomeIcons.get(model.is_private) || ""
  };
}
