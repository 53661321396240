







import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    height: { type: String, default: "100%" }
  }
});
