


























import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
import "/@/assets/styles/main.scss";

import Vue from "vue";
import { defineComponent } from "@vue/composition-api";
import useNavigation from "@/composables/useNavigation";

import OHeader from "/@/components/organisms/o-header.vue";
import ONotificator from "/@/components/organisms/o-notificator.vue";
import MFooter from "/@/components/molecules/m-footer.vue";
import OLeftMenu from "@/components/organisms/o-left-menu.vue";

import { showHelpCrunchIfConfigured } from "/@/startup/helpCrunch";

import useTheme from "@/composables/useTheme";

export default defineComponent({
  setup() {
    Vue.$log.info("Main layout: setup()");

    useTheme();
    showHelpCrunchIfConfigured();

    return {
      navigationRoot: useNavigation().root
    };
  },
  components: {
    OHeader,
    ONotificator,
    MFooter,
    OLeftMenu
  }
});
