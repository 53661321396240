import config from "../startup/config";
import Vue from "vue";

export function openHelpChat() {
  Vue.$log.info("HelpCrunch: openChat");
  const w = window as any;
  if (w.HelpCrunch) {
    w.HelpCrunch("openChat");
  }
}

export function showHelpWidget(show: boolean) {
  const w = window as any;
  if (w.HelpCrunch) {
    w.HelpCrunch(show ? "showChatWidget" : "hideChatWidget");
  }
}

export function updateCustomUserDataTeam(teamData: {
  plan_name: string;
  is_paying: boolean;
  is_trial: boolean;
  is_agent: boolean;
}) {
  if (!config.USE_HELP_CRUNCH) return;
  Vue.$log.debug(`HelpCrunch: updateCustomUserDataTeam`, teamData);
  const w = window as any;
  w.HelpCrunch("updateUserData", teamData);
}

export function updateCustomUserDataGetStarted(getStartedUserData: {
  info_agents_str?: string;
  info_employees_str?: string;
  info_teams?: string;
  info_usecases?: string;
  info_usecases_other?: string;
}) {
  if (!config.USE_HELP_CRUNCH) return;
  Vue.$log.debug(
    `HelpCrunch: updateCustomUserDataGetStarted`,
    getStartedUserData
  );
  const w = window as any;
  w.HelpCrunch("updateUserData", getStartedUserData);
}

export const trackEventGetStarted = (getStartedEvent: {
  status: "success" | "error";
  agents?: string;
  employees?: string;
  teams?: string;
  usecases?: string;
  usecases_other?: string;
}) => {
  if (!config.USE_HELP_CRUNCH) return;
  Vue.$log.debug(`HelpCrunch: trackEventGetStarted`, getStartedEvent);
  const w = window as any;
  w.HelpCrunch("trackEvent", "Get Started", getStartedEvent);
};
