export function clearQueryIfParamExists(param: string) {
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get(param);
  if (code) {
    history.replaceState(
      {},
      "",
      location.protocol + "//" + location.host + location.pathname
    );
  }
}

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

/**
 * broadcastMessage can be used to send messages between tabs
 */

const BROADCAST_MESSAGE_DEFAULT_CHANNEL = "suptask/broadcast";
const listeners: Array<(message: string) => void> = [];

window.addEventListener("storage", (ev: StorageEvent) => {
  if (ev.key != BROADCAST_MESSAGE_DEFAULT_CHANNEL || !ev.newValue) return;
  const message = JSON.parse(ev.newValue);
  for (const listener of listeners) {
    listener(message);
  }
});

export function broadcastMessage(message: string) {
  localStorage.setItem(
    BROADCAST_MESSAGE_DEFAULT_CHANNEL,
    JSON.stringify(message)
  );
  localStorage.removeItem(BROADCAST_MESSAGE_DEFAULT_CHANNEL);
}

export function addBroadcastingListener(listener: (message: string) => void) {
  listeners.push(listener);
}

// function for loading scripts asynchronously
export function loadScript(src: string) {
  return new Promise((resolve, reject) => {
    const s = document.createElement("script");
    let r = false;
    s.type = "text/javascript";
    s.src = src;
    s.async = true;
    s.onerror = function(err) {
      reject(err);
    };
    s.onload = function() {
      // console.log(this.readyState); // uncomment this line to see which ready states are called.
      if (!r) {
        r = true;
        resolve(undefined);
      }
    };
    (s as any).onreadystatechange = s.onload;

    const t = document.getElementsByTagName("script")[0];
    if (t && t.parentElement) {
      t.parentElement.insertBefore(s, t);
    } else {
      reject("Error during loading external script.");
    }
  });
}

/**
 * Returns CssVariable by it's name in the given element.
 * If element is undefined, then searches in :root element
 */
export function getCssVariableValue(name: string, element?: Element) {
  return getComputedStyle(element ?? document.documentElement)
    .getPropertyValue(name)
    .valueOf()
    .trim();
}
