import { computed } from "@vue/composition-api";
import { useStoreGetters as useAuthGetters } from "/@/store/modules/auth.use";

import { intersection } from "@/helpers/arrays";
import { adminGroups } from "@/models/UserModel";

export default function useClaims() {
  const { user } = useAuthGetters();

  const canManageBilling = computed(
    () =>
      intersection(user.value?.groups ?? [], adminGroups.billingAdmins).length >
      0
  );

  return {
    canManageBilling
  };
}
