/**
 * Userpilot (https://userpilot.com/)
 */

import { log, catchAndLog } from "@/helpers/decorators";

import config from "/@/startup/config";
import { Userpilot } from "userpilot";
import Vue from "vue";

import { UserViewModel } from "@/models/UserModel";
import { getIsAccountAdmin, getIsUserAnAgent } from "@/composables/useUser";

import { GetStartedTrack } from "./userTrackingService";

export default class UserpilotHelper {
  private static _instance: UserpilotHelper;
  private token: string;
  private initialized = false;
  private codeDictionary = {
    automations: "u23yDIJAdF",
    integrations: "fXWH8iEQKF",
    email: "IC9lnAZTGe"
  };

  private constructor(token: string) {
    this.token = token;
  }

  public static get instance(): UserpilotHelper | undefined {
    if (!UserpilotHelper._instance && config.USERPILOT_TOKEN) {
      UserpilotHelper._instance = new UserpilotHelper(config.USERPILOT_TOKEN);
    }
    return UserpilotHelper._instance;
  }

  @catchAndLog()
  public init() {
    if (!this.initialized) {
      this.log("initialize");
      Userpilot.initialize(this.token);
      this.initialized = true;
    }
  }

  @log()
  @catchAndLog()
  public reload() {
    this.init();
    Userpilot.reload();
  }

  @log()
  @catchAndLog()
  public suppress() {
    Userpilot.suppress();
  }

  @log()
  @catchAndLog()
  public trigger(key: keyof typeof this.codeDictionary) {
    const code = this.codeDictionary[key];
    if (code) {
      Userpilot.trigger(code);
    }
  }

  @log()
  @catchAndLog()
  public unsuppress() {
    Userpilot.unsuppress();
  }

  @log({ point: "both" })
  @catchAndLog()
  public authUser(user: UserViewModel | null | undefined) {
    this.init();
    if (user) {
      const userid = this.getUserId(user);
      const sendData = {
        name:
          user.realName && user.realName !== ""
            ? user.realName
            : user.displayName,
        email: user.email,
        company: {
          id: user.teamId, // Required, used to identify the company
          name: user.teamName
        },
        // Additional properties
        is_admin: getIsAccountAdmin(user)
      };
      this.log(`authUser: identify ${userid} with user data`, sendData);
      Userpilot.identify(userid, sendData);
    } else {
      this.log("authUser: identify anonymous");
      Userpilot.anonymous();
    }
  }

  @log({ point: "both" })
  @catchAndLog()
  public authTeam(user: UserViewModel | null | undefined) {
    this.init();
    if (user && user.team) {
      const userid = this.getUserId(user);
      const teamData = {
        company_plan_name: user?.team?.planSubscription?.plan.name ?? "N/A",
        company_is_paying: !(user?.team?.planSubscription?.isFree ?? true),
        company_is_trial: user?.team?.planSubscription?.isTrial === true
      };
      const sendData = {
        // keep company data on the compay level
        company: {
          id: user.teamId, // Required, used to identify the company
          ...teamData
        },
        is_agent: getIsUserAnAgent(user ?? null, user?.team),
        // also keep company data on user level
        ...teamData
      };
      this.log(`authTeam: identify ${userid} with team data`, sendData);
      Userpilot.identify(userid, sendData);
    }
  }

  @log({ point: "both" })
  @catchAndLog()
  public trackGetStartedComplete(
    user: UserViewModel | null | undefined,
    data: GetStartedTrack
  ) {
    this.init();

    const getStartedData = {
      get_started_status: data.status,
      get_started_teams: data.teams,
      get_started_team: data.team,
      get_started_use_cases: data.useCases,
      get_started_use_case: data.useCase,
      get_started_other_use_case_description: data.otherUseCaseDescription
    };

    // update user auth
    if (user) {
      const userid = this.getUserId(user);
      const sendData = {
        // keep getStartedData data on the compay level
        company: {
          id: user.teamId, // Required, used to identify the company,
          ...getStartedData
        },
        // also keep getStartedData data on user level
        ...getStartedData
      };
      this.log(
        `trackGetStartedComplete: identify ${userid} with get started`,
        sendData
      );
      Userpilot.identify(userid, sendData);
    }

    // track custom event
    this.track("getStartedComplete", getStartedData);
  }

  @log()
  @catchAndLog()
  public track(event: string, data?: Record<string, any>) {
    this.init();
    this.log(`track: ${event}`, data);
    Userpilot.track(event, data);
  }

  private log(message: string, data?: any) {
    Vue.$log.trace(`UserpilotHelper.${message}`, data);
  }

  private getUserId(user: UserViewModel) {
    return `${user.uuid}--${user.id}`;
  }
}
