import { log, catchAndLog } from "@/helpers/decorators";

import Plausible from "plausible-tracker";

export default class PlausibleHelper {
  private static initialized = false;
  private static trackEvent: ReturnType<typeof Plausible>["trackEvent"];

  @log()
  @catchAndLog()
  public static init() {
    if (!this.initialized) {
      const { trackEvent } = Plausible({
        domain: "suptask.com",
        trackLocalhost: process.env.NODE_ENV == "development"
      });
      this.trackEvent = trackEvent;
      this.initialized = true;
    }
  }

  @log()
  @catchAndLog()
  public static trackPurchase() {
    this.init();
    this.trackEvent("purchase");
  }

  @log()
  @catchAndLog()
  public static trackGetStartedComplete() {
    this.init();
    this.trackEvent("getStartedComplete");
  }

  @log()
  @catchAndLog()
  public static trackAppInstalled() {
    this.init();
    this.trackEvent("appInstall");
  }
}
