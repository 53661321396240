







import { computed, defineComponent } from "@vue/composition-api";
import { useSlackChannelsStore } from "/@/stores/slackChannels";
import { ChannelViewModel } from "/@/models/ChannelModel";

export default defineComponent({
  name: "a-channel",
  props: {
    id: { type: String, required: false },
    value: { type: Object as () => ChannelViewModel, required: false },
    hideIcon: { type: Boolean, default: false },
    hideTooltip: { type: Boolean, default: false }
  },
  setup(props) {
    const channelsStore = useSlackChannelsStore();

    const channel = computed(() => {
      if (props.value) return props.value;
      if (!props.id) throw Error; // TODO handle
      return channelsStore.getChannelById(props.id);
    });

    const tooltip = computed(() => {
      return props.hideTooltip ? undefined : channel.value.name;
    });

    const showIcon = computed(() => {
      return !props.hideIcon && channel.value.icon;
    });

    return {
      channel,
      tooltip,
      showIcon
    };
  }
});
