








import { defineComponent } from "@vue/composition-api";
import TicketTag from "./ticket-tag.vue";
import { Ticket } from "../models/Ticket";

export default defineComponent({
  name: "ticket-tags",
  components: {
    TicketTag
  },
  props: {
    row: { type: Object as () => Ticket, required: true }
  }
});
