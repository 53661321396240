import { customAlphabet } from "nanoid";

export function addTrailingIfNotEndsWith(str: string, trailing: string) {
  if (!str.endsWith(trailing)) {
    str += trailing;
  }
  return str;
}

export function capitalizeFirstLetter(str: string) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getInitials(name: string) {
  const initials = name
    .replace(/[^a-zA-Z\s]/g, "")
    .split(" ")
    .map(n => n[0])
    .join("");
  // Show max 3 initials
  return initials.slice(0, 3);
}

export function getPluralFormWithNumber(
  [zeroForm, singularForm, pluralForm]: string[],
  num: number,
  placeholder = "{num}"
) {
  let resultForm = pluralForm;

  switch (num) {
    case 0:
      resultForm = zeroForm;
      break;
    case 1:
      resultForm = singularForm;
      break;
  }
  return resultForm.replace(placeholder, num.toString());
}

/**
 * Generates a unique name by appending incremental numeric suffixes to the given base name.
 * Checks against an array of existing names to ensure uniqueness.
 */
export function getUniqueName(
  baseName: string,
  existingNames: string[],
  suffixSeparator = " ",
  startIncrementalSuffix = 1
) {
  let newName = baseName;
  let suffix = startIncrementalSuffix;
  while (existingNames.includes(newName)) {
    newName = `${baseName}${suffixSeparator}${suffix}`;
    suffix++;
  }
  return newName;
}

/**
 * Generates a unique name by appending a nano-suffix to the given base name.
 * Checks against an array of existing names to ensure uniqueness.
 * If against an array of existing names is undefined, then returns a nano-suffixed name
 */
export function getUniqueNameNano(
  baseName: string,
  existingNames: string[] | undefined,
  suffixSeparator = " ",
  nanoLength = 4
) {
  let newName = baseName;
  const checkNames = existingNames ? existingNames : [baseName];
  while (checkNames.includes(newName)) {
    const nanoid = customAlphabet("1234567890bcdfhj", nanoLength);
    newName = `${baseName}${suffixSeparator}${nanoid()}`;
  }
  return newName;
}

/**
 * Joins string array. Example: ["a", "b", "c"] => "a, b and c"
 * @param arr - array to join
 * @param separator - separator to use
 * @param lastSeparator - use this separator for joining last element
 */
export function join(arr: string[], separator: string, lastSeparator?: string) {
  if (arr.length <= 1) return arr.join("");
  return (
    arr.slice(0, -1).join(separator) +
    (lastSeparator ?? separator) +
    arr[arr.length - 1]
  );
}

/**
 * Returns how many alphaumeric characters are in the string
 */
export function getAlphanumericCount(str: string) {
  const alphanumericCharacters = str.match(/[\p{L}\p{N}]/gu);
  return alphanumericCharacters?.length ?? 0;
}

export function truncateString(input: string, maxLength: number): string {
  if (input.length > maxLength) {
    return input.slice(0, maxLength).trim() + "...";
  }
  return input;
}
