import { ChannelViewModel } from "@/models/ChannelModel";

export function channelsSortFn(a: ChannelViewModel, b: ChannelViewModel) {
  if (a.private == b.private) {
    return a.name.localeCompare(b.name);
  }

  if (a.private == false) {
    return -1;
  } else {
    return 1;
  }
}

export function normalizeChannelName(name: string) {
  return name
    .trim()
    .replace(/([^\p{L}\p{N}_])/gu, "-") // Turn all but alphanumerics and underscores to hyphens
    .toLowerCase();
}
