import { ActionContext } from "vuex";

import { UserViewModel } from "/@/models/UserModel";

import { AuthState } from "./auth";

interface Getters {
  slackAppInstalled: (state: any) => boolean;
  ready: (state: any) => boolean;
  user: (state: any) => UserViewModel | null;
  initializingSubscription: (state: any) => boolean;
  initializingSubscriptionError: (state: any) => boolean;
}

interface Actions {
  authUser: (ctx: ActionContext<any, any>, payload: any) => Promise<void>;
  signOutUser: (ctx: ActionContext<any, any>) => Promise<void>;
  loadTeam: (ctx: ActionContext<any, any>) => Promise<void>;
}

import { createNamespacedHelpers } from "vuex-composition-helpers";
const { useGetters, useActions } = createNamespacedHelpers<
  AuthState,
  Getters,
  Actions,
  any
>("auth");

export function useStoreGetters() {
  return useGetters([
    "ready",
    "slackAppInstalled",
    "user",
    "initializingSubscription",
    "initializingSubscriptionError"
  ]);
}

export function useStoreActions() {
  return useActions(["authUser", "signOutUser", "loadTeam"]);
}
