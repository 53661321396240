var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{key:_vm.value[_vm.optionsIdPropertyName],staticClass:"multiselect__tag"},[(
      (_vm.optionsIconPropertyName && _vm.value[_vm.optionsIconPropertyName]) ||
        _vm.optionIcon
    )?_c('font-awesome-icon',{attrs:{"icon":_vm.optionsIconPropertyName && _vm.value[_vm.optionsIconPropertyName]
        ? _vm.value[_vm.optionsIconPropertyName]
        : _vm.optionIcon}}):_vm._e(),_c('span',{class:_vm.optionsClassPropertyName ? _vm.value[_vm.optionsClassPropertyName] : '',domProps:{"textContent":_vm._s(_vm.value[_vm.optionsLabelPropertyName])}}),(
      _vm.optionsCanDeletePropertyName === undefined
        ? true
        : _vm.value[_vm.optionsCanDeletePropertyName]
    )?_c('i',{staticClass:"multiselect__tag-icon",attrs:{"tabindex":"1"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.$emit('remove', _vm.value)},"mousedown":function($event){$event.preventDefault();return _vm.$emit('remove', _vm.value)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }