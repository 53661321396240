














import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "table-header-help-button",
  props: {
    tooltip: String
  }
});
