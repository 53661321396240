<template>
  <div id="s-main-container" class="height-100">
    <section id="s-app" class="section">
      <section id="s-view">
        <router-view />
      </section>
      <m-footer></m-footer>
    </section>
    <div class="notifications">
      <o-notificator></o-notificator>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import { defineComponent } from "@vue/composition-api";

import OHeader from "/@/components/organisms/o-header.vue";
import ONotificator from "/@/components/organisms/o-notificator.vue";

import MFooter from "/@/components/molecules/m-footer.vue";

import useTheme from "@/composables/useTheme";

export default defineComponent({
  setup() {
    Vue.$log.info("Widget layout: setup()");

    useTheme();
  },
  components: {
    OHeader,
    ONotificator,
    MFooter
  }
});
</script>

<style lang="scss" scoped>
#s-app.section {
  padding: 1.5rem;
}
</style>
