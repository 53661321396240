import { fetchApiSlack, fetchApiSlackV2 } from "@/helpers/api.slack";
import { ChannelSlackModel } from "@/models/ChannelModel";
import axios from "axios";
import qs from "qs";

export interface ChannelResponse {
  ok: boolean;
  channel: ChannelSlackModel;
  error?: string;
}

export default class ChannelRepo {
  /**
   * Use this directly method with caution!
   * Usually you should not use it directly, but instead use slackChannels store to access channels, which includes such aspects like eventual channels caching
   */
  public static async get(
    token: string,
    types: ("public_channel" | "private_channel")[] = [
      "public_channel",
      "private_channel"
    ],
    intermediateResultCallback?: (
      items: ChannelSlackModel[],
      allLoaded: boolean
    ) => void
  ): Promise<ChannelSlackModel[]> {
    const slackChannels = await fetchApiSlackV2<ChannelSlackModel>({
      // See Slack documentation: https://api.slack.com/methods/conversations.list
      url: "https://slack.com/api/conversations.list",
      token,
      data: {
        types: types.length == 0 ? undefined : types.join(","),
        // eslint-disable-next-line
        exclude_archived: true
      },
      itemsField: "channels",
      limit: 1000,
      totalLimit: 15000,
      intermediateResultCallback
    });

    return slackChannels;
  }

  /**
   * Use this directly method with caution!
   * Usually you should not use it directly, but instead use slackChannels store to create channels, which includes such aspects like eventual channels caching
   */
  public static async post(
    token: string,
    name: string,
    isPrivate = false
  ): Promise<ChannelResponse> {
    const data = {
      token,
      name,
      // eslint-disable-next-line
      is_private: isPrivate
    };
    const response = await axios.post<ChannelResponse>(
      // See Slack documentation: https://api.slack.com/methods/conversations.create
      "https://slack.com/api/conversations.create",
      qs.stringify(data)
    );

    return response.data;
  }

  public static async invite(
    token: string,
    channelId: string,
    userIds: string[]
  ): Promise<ChannelResponse> {
    const data = {
      token,
      channel: channelId,
      users: userIds.join(",")
    };
    const response = await axios.post<ChannelResponse>(
      // See Slack documentation: https://api.slack.com/methods/conversations.invite
      "https://slack.com/api/conversations.invite",
      qs.stringify(data)
    );

    return response.data;
  }

  /**
   * Get ids of all members of the channel
   */
  public static async memberIds(
    token: string,
    channelId: string
  ): Promise<string[]> {
    const slackMembers = await fetchApiSlack<string>(
      // See Slack documentation: https://api.slack.com/methods/conversations.members
      "https://slack.com/api/conversations.members",
      token,
      {
        token,
        channel: channelId
      },
      "members"
    );

    return slackMembers;
  }
}
