






import { defineComponent, computed } from "@vue/composition-api";
import { getInitials } from "/@/helpers/string";

export default defineComponent({
  name: "avatar",
  props: {
    name: { type: String, default: "" },
    url: { type: String, required: false },
    isBig: { type: Boolean, default: true },
    bgColor: { type: String, default: "transparent" }
  },
  setup(props) {
    const styles = computed(() => ({
      "--bg-image": props.url ? `url(${props.url})` : undefined,
      "--bg-color": props.bgColor,
      "--size": props.isBig ? "64px" : "40px",
      "--padding": props.isBig ? "20px 0" : "8px 0"
    }));

    const initials = computed(() => {
      return props.url ? "" : getInitials(props.name);
    });

    return { styles, initials };
  }
});
