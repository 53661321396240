import { BillingState } from "./Billing";
import {
  PlanSubscription,
  PlansViewModel,
  RefPayment,
  RefSubscription
} from "@/models/BillingModel";
import { PlanOffer } from "@/models/TeamModel";
import { FeatureFlags } from "@/models/features";

interface Getters {
  currentPlan: (state: any) => PlanSubscription | null;
  planOffer: (state: any) => PlanOffer | null;
  featureFlags: (state: any) => FeatureFlags;
  paymentsByDateDesc: (state: any) => RefPayment[];
  plansViewModel: (state: any) => PlansViewModel;
  isExternalCheckoutLoading: (state: any) => boolean;
  isExternalCheckoutLoadingError: (state: any) => boolean;
  subscription: (state: any) => RefSubscription;
}

import { createNamespacedHelpers } from "vuex-composition-helpers";
const { useState, useGetters, useActions } = createNamespacedHelpers<
  BillingState,
  Getters,
  any,
  any
>("billing");

export function useStoreState() {
  return useState([]);
}

export function useStoreGetters() {
  return useGetters([
    "currentPlan",
    "planOffer",
    "featureFlags",
    "plansViewModel",
    "paymentsByDateDesc",
    "isExternalCheckoutLoading",
    "isExternalCheckoutLoadingError",
    "subscription"
  ]);
}

export function useStoreActions() {
  return useActions([
    "loadPlans",
    "loadSubscription",
    "loadPayments",
    "revokeCancellationRequest"
  ]);
}
