












































































import { Options, Vue } from "/@/compatibility/vue-class-component";
import WizardNavigation from "./o-wizard-modal.navigation.vue";
import { ValidationObserver } from "vee-validate";
import { timeoutPromise } from "/@/helpers/promises";
import { SupError } from "@/models/Errors";

export interface ValidatableStep {
  validateStep: () => Promise<boolean>;
}

export interface WizardStep {
  label: string; // label in nav
  modalTitle?: string; // overrides the modal title
  modalHeadSlot?: string;
  actionButton?: string | null; // overrides action button, if null - no action button is shown
  cancelButton?: string | null; // overrides cancel button, if null - no cancel button is shown
  slot: string;
  footerSlot?: string;
  showNavigation?: boolean;
  validateAtParent?: boolean;
  disableAllNavigationLinks?: boolean; // if true then all steps navigation links are disabled
  disableNavigationLink?: boolean; // if true then current navigation link is disabled
  warning?: string; // if set, then a warning icon is shown in the navigation link
}

export interface WizardModel {
  steps: Array<WizardStep>;
  currentStepIndex: number;
}

import { createGlobalState } from "@vueuse/core";
import { ref } from "@vue/composition-api";
interface GlobalWizardState {
  stepLoading: boolean;
}
export const useGlobalWizardState = createGlobalState(() => {
  const res = ref({ stepLoading: false });
  return res;
});

const WizardModalBase = Vue.extend({
  props: {
    title: String,
    cancelOnEsc: {
      type: Boolean,
      default: true
    },
    floatWidth: {
      type: Boolean,
      default: false
    },
    fixedHeight: {
      type: Boolean,
      default: true
    },
    showNavigation: {
      type: Boolean,
      default: true
    },
    prevStepButtonLabel: {
      type: String,
      default: "Back"
    },
    nextStepButtonLabel: {
      type: String,
      default: "Next"
    },
    finalActionButtonLabel: {
      type: String,
      default: "Create"
    },
    model: Object as () => WizardModel,
    showExitConfirmation: Boolean,
    allNavStepsAvailable: Boolean,
    actionButtonFocused: Boolean,
    alwaysReadyToAction: Boolean,
    loading: Boolean
  }
});

@Options<OWizardModal>({
  components: {
    WizardNavigation,
    ValidationObserver
  },
  setup() {
    const globalWizardState = useGlobalWizardState();
    globalWizardState.value.stepLoading = false;
    return { globalWizardState };
  }
})
export default class OWizardModal extends WizardModalBase {
  $refs!: {
    form: InstanceType<typeof ValidationObserver>;
  };
  globalWizardState!: GlobalWizardState;

  cancel() {
    this.$log.debug("emit: cancel");
    this.$emit("cancel");
  }
  back() {
    if (this.model.currentStepIndex > 0) {
      this.$log.debug("Go to previous step.");
      this.model.currentStepIndex--;
    }
  }
  async action() {
    const valid = await this.validateCurrentStep();
    if (valid) {
      if (this.alwaysReadyToAction || this.isFinalStep) {
        this.$log.debug("emit: action");
        this.$emit("action");
      } else {
        this.$log.debug("Go to next step.");
        this.model.currentStepIndex++;
      }
    } else {
      this.$log.debug("Step form not valid.");
    }
  }

  async navigateToNextStepIfValidUntilStop(stopAt: number) {
    const valid = await this.validateCurrentStep();
    if (!valid) {
      return;
    }
    this.$log.debug(`Navigate to step ${this.model.currentStepIndex + 1}.`);
    this.model.currentStepIndex++;
    if (this.model.currentStepIndex < stopAt) {
      this.$nextTick(() => {
        this.navigateToNextStepIfValidUntilStop(stopAt);
      });
    }
  }

  async validateCurrentStep() {
    let valid: boolean;
    if (this.currentStep.validateAtParent) {
      valid = await this.validateStepAtParent();
    } else {
      valid = await this.$refs.form.validate();
    }
    if (valid) {
      this.$set(this.currentStep, "warning", undefined);
    } else {
      this.$set(this.currentStep, "warning", "Invalid step");
      this.$log.debug(`Step ${this.model.currentStepIndex} not valid.`);
    }
    return valid;
  }

  async navClick(stepIndex: number) {
    if (this.globalWizardState.stepLoading) return;
    if (stepIndex > this.model.currentStepIndex) {
      // move forward step by stepwith validation
      await this.navigateToNextStepIfValidUntilStop(stepIndex);
    } else {
      if (stepIndex < this.model.currentStepIndex) {
        // always clear the warning if move backward
        this.$set(this.currentStep, "warning", undefined);
      }
      // move backward directly to required step without validation
      this.$log.debug(`Navigate to step ${stepIndex}.`);
      this.model.currentStepIndex = stepIndex;
    }
  }
  submit() {
    this.$log.debug("Form submit event.");
  }

  get currentStep(): WizardStep {
    return this.model.steps[this.model.currentStepIndex] as WizardStep;
  }
  get currentSlotName() {
    return this.currentStep.slot;
  }
  get currentFooterSlotName() {
    return this.currentStep.footerSlot;
  }
  get currentModalHeadSlotName() {
    return this.currentStep.modalHeadSlot;
  }
  get cancelButtonLabel() {
    if (this.currentStep.cancelButton !== undefined) {
      return this.currentStep.cancelButton;
    }
    return "Cancel";
  }
  get backButtonLabel() {
    if (this.isFirstStep || this.currentStep.disableAllNavigationLinks) {
      return undefined;
    }
    return this.prevStepButtonLabel;
  }
  get actionButtonLabel() {
    if (this.alwaysReadyToAction) {
      return this.finalActionButtonLabel;
    }
    if (this.currentStep.actionButton !== undefined) {
      return this.currentStep.actionButton;
    }

    return this.isFinalStep
      ? this.finalActionButtonLabel
      : this.nextStepButtonLabel;
  }
  get isFirstStep() {
    return this.model.currentStepIndex == 0;
  }
  get isFinalStep() {
    return this.model.currentStepIndex == this.model.steps.length - 1;
  }
  get actualTitle() {
    if (
      (this.currentStep.modalHeadSlot &&
        this.$slots[this.currentStep.modalHeadSlot]) ||
      this.$slots.head
    ) {
      return undefined;
    }

    if (this.currentStep.modalTitle !== undefined) {
      return this.currentStep.modalTitle;
    }
    return this.title;
  }

  private async validateStepAtParent() {
    const promise = new Promise<boolean>((resolve, reject) => {
      this.$emit("validateStep", this.currentStep, resolve, reject);
    });

    const timeoutError = Symbol();
    try {
      return await timeoutPromise<boolean>(promise, 1000, timeoutError);
    } catch (e) {
      if (e === timeoutError) {
        throw new SupError(
          "Not resolved promise for validating step at parent."
        );
      } else {
        throw e;
      }
    }
  }
}
