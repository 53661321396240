



















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    icon: { type: String, required: true },
    label: { type: String, required: true },
    /**
     * Specify path for internal navigation so item will have active style if matches router's path
     */
    path: { type: String, required: false },
    /**
     * Add external icon if true
     */
    external: { type: Boolean, required: false }
  }
});
