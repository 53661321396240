import { useStoreGetters as useAuthGetters } from "/@/store/modules/auth.use";
import { whenever } from "@vueuse/core";
import useRouter from "/@/composables/_useRouter";

export default function useAuthReady(
  callback: () => Promise<void>,
  failedRedirect: string | undefined | null = "../",
  callbackEvenIfSlackAppNotInstalled = false
) {
  const { slackAppInstalled, ready } = useAuthGetters();
  const { router } = useRouter();

  whenever(
    ready,
    async () => {
      let doRedirect = false;
      if (callbackEvenIfSlackAppNotInstalled || slackAppInstalled.value) {
        try {
          await callback();
        } catch (e) {
          doRedirect = true;
        }
      } else {
        doRedirect = true;
      }

      if (doRedirect && failedRedirect) {
        await router.push({ path: failedRedirect });
      }
    },
    { immediate: true }
  );
}
