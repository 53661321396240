



























import { defineComponent } from "@vue/composition-api";
import { useStoreGetters as useAuthGetters } from "/@/store/modules/auth.use";

export default defineComponent({
  setup() {
    const { user } = useAuthGetters();

    return { user };
  }
});
