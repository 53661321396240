












import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
import "/@/assets/styles/main.scss";

import Vue from "vue";

import ONotificator from "/@/components/organisms/o-notificator.vue";

import { showHelpCrunchIfConfigured } from "/@/startup/helpCrunch";

import { defineComponent } from "@vue/composition-api";

import useTheme from "@/composables/useTheme";

export default defineComponent({
  setup() {
    Vue.$log.info("NoMenu layout: setup()");

    useTheme();
    showHelpCrunchIfConfigured();
  },
  components: {
    ONotificator
  }
});
