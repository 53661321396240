const config = {
  LOG_LEVEL: process.env.NODE_ENV === "development" ? "debug" : "info",
  RELEASE: process.env.VUE_APP_RELEASE,
  RELEASE_ENVIRONMENT:
    process.env.VUE_APP_FORCE_RELEASE_ENVIRONMENT ??
    process.env.VUE_APP_RELEASE_ENVIRONMENT,
  USE_SENTRY: process.env.NODE_ENV !== "development",
  USE_API_MOCK:
    process.env.NODE_ENV === "development" &&
    process.env.VUE_APP_USE_API_MOCK === "true",
  USE_AUTH_MOCK:
    process.env.NODE_ENV === "development" &&
    process.env.VUE_APP_USE_AUTH_MOCK === "true",

  PADDLE_VENDOR: process.env.VUE_APP_PADDLE_VENDOR
    ? parseInt(process.env.VUE_APP_PADDLE_VENDOR)
    : undefined,
  REAL_PAYMENTS: process.env.VUE_APP_REAL_PAYMENTS === "true",
  BILLING_UNDER_CONSTRUCTION:
    process.env.VUE_APP_BILLING_UNDER_CONSTRUCTION === "true",
  BILLING_USE_PAGE_V1: process.env.VUE_APP_BILLING_USE_PAGE_V1 === "true",

  USE_HELP_CRUNCH:
    (process.env.VUE_APP_HELP_CRUNCH_APPLICATION_SECRET ?? "") !== "",
  HELP_CRUNCH_APPLICATION_SECRET:
    process.env.VUE_APP_HELP_CRUNCH_APPLICATION_SECRET,
  HELP_CRUNCH_APP_ID: process.env.VUE_APP_HELP_CRUNCH_APP_ID,
  HELP_CRUNCH_USER_AUTH_ENABLED:
    process.env.VUE_APP_HELP_CRUNCH_USER_AUTH_ENABLED === "true",
  HELP_CRUNCH_USER_AUTH_CUSTOM_DATA_ENABLED:
    process.env.VUE_APP_HELP_CRUNCH_USER_AUTH_CUSTOM_DATA_ENABLED === "true",

  REDIRECT_AUTH_DM: process.env.VUE_APP_REDIRECT_AUTH_DM,
  REDIRECT_AUTH_SLACK: process.env.VUE_APP_REDIRECT_AUTH_SLACK,
  SLACK_APP_ID: process.env.VUE_APP_SLACK_APP_ID,
  SLACK_CLIENT_ID: process.env.VUE_APP_SLACK_CLIENT_ID,
  SUPTASK_API: process.env.VUE_APP_SUPTASK_API,
  SUPTASK_API_DASHBOARD: process.env.VUE_APP_SUPTASK_API_DASHBOARD,
  SUPTASK_API_TICKETS: process.env.VUE_APP_SUPTASK_API_TICKETS,

  SENTRY_DSN: process.env.VUE_APP_SENTRY_DSN,
  USERPILOT_TOKEN: process.env.VUE_APP_USERPILOT_TOKEN
};

window.__debug.version = config.RELEASE;
window.__debug.releaseEnv = config.RELEASE_ENVIRONMENT;
window.__debug.env = {
  REDIRECT_SIGN_IN: process.env.VUE_APP_REDIRECT_SIGN_IN,
  REDIRECT_SIGN_OUT: process.env.VUE_APP_REDIRECT_SIGN_OUT
};
/**
 * Quick re-config for dev purposes, keep commented out when committing!
 */
// config.USE_API_MOCK = true;
// config.USE_AUTH_MOCK = true;
// config.LOG_LEVEL = "trace";
// config.USE_SENTRY = true;
// config.SUPTASK_API_DASHBOARD = "http://localhost:4004";
// config.SUPTASK_API_TICKETS = "http://localhost:4004";

export default config;
