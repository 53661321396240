
















import { Options } from "/@/compatibility/vue-class-component";
import { AButtonProps } from "./BaseButton";

@Options
export default class ARouterButtonLink extends AButtonProps {}
