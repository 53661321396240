import { getIsUserAnAgent } from "@/composables/useUser";
import { intersection } from "@/helpers/arrays";
import { SupGroup, UserViewModel, adminGroups } from "@/models/UserModel";

export interface NavItem {
  key?: "settings";
  label: string;
  path: string;
  target?: "_blank";
  exactOnly?: boolean;
  hidden?: boolean;
  hiddenMobile?: boolean;
  icon?: string;
  children?: NavItem[];
  requires?: {
    anyOfGroups?: SupGroup[];
    anyOfOriginalGroups?: SupGroup[];
    /**
     * customUserPredicate combined then with groups requirements with AND
     */
    customUserPredicate?: (user: UserViewModel | null) => boolean;
  };
}

export const navItems: NavItem[] = [
  {
    label: "Get Started",
    path: "/get-started",
    hidden: true,
    hiddenMobile: true,
    requires: {
      anyOfGroups: adminGroups.accountAdmins
    },
    children: []
  },
  {
    label: "Dashboard",
    path: "/dashboard",
    requires: {
      /**
       * Dashboard is available to account admins and agents
       */
      customUserPredicate: user =>
        !!user &&
        (intersection(user.groups, adminGroups.accountAdmins).length > 0 ||
          getIsUserAnAgent(user, user.team))
    },
    children: []
  },
  {
    label: "Tickets",
    path: "/tickets",
    children: []
  },
  {
    label: "Inboxes",
    path: "/inboxes",
    requires: {
      anyOfGroups: adminGroups.accountAdmins
    }
  },
  {
    label: "Automations",
    path: "/automations",
    requires: {
      anyOfGroups: adminGroups.accountAdmins
    },
    children: []
  },
  {
    label: "Theme",
    path: "/theme",
    hidden: true,
    hiddenMobile: true,
    requires: {
      anyOfGroups: adminGroups.accountAdmins
    },
    children: [
      {
        label: "Typography",
        path: "/theme"
      },
      {
        label: "Buttons",
        path: "/theme/buttons"
      },
      {
        label: "Form",
        path: "/theme/form"
      },
      {
        label: "Dialog",
        path: "/theme/dialog"
      },
      {
        label: "Table",
        path: "/theme/table"
      },
      {
        label: "Miscellaneous",
        path: "/theme/misc"
      },
      {
        label: "Full",
        path: "/theme/full"
      }
    ]
  },
  {
    label: "Support & Videos",
    path: "https://support.suptask.com",
    target: "_blank",
    hidden: true,
    hiddenMobile: false,
    children: [
      {
        label: "Explore All Videos",
        path: "https://www.youtube.com/channel/UCEvCDlJGO2bLwASqGFQTtqg",
        target: "_blank",
        icon: "video"
      },
      {
        label: "Explore All Support Articles",
        path: "https://support.suptask.com",
        target: "_blank",
        icon: "file-lines"
      }
    ]
  },
  {
    key: "settings",
    label: "Settings",
    path: "/account",
    hidden: true,
    hiddenMobile: false,
    requires: {
      anyOfOriginalGroups: adminGroups.allAdmins
    },
    children: [
      {
        label: "Subscriptions & Billing",
        path: "/account/billing",
        icon: "credit-card",
        requires: {
          anyOfOriginalGroups: adminGroups.billingAdmins
        }
      },
      {
        label: "Users & Permissions",
        path: "/account/users",
        icon: "users",
        requires: {
          anyOfGroups: adminGroups.accountAdmins
        }
      },
      {
        label: "Reports",
        path: "/account/reports",
        icon: "file-export",
        requires: {
          anyOfGroups: adminGroups.accountAdmins
        }
      },
      {
        label: "Integrations",
        path: "/account/integrations",
        icon: "puzzle-piece",
        requires: {
          anyOfGroups: adminGroups.accountAdmins
        }
      },
      {
        label: "Account Settings",
        path: "/account/settings",
        icon: "wrench",
        requires: {
          anyOfGroups: adminGroups.accountAdmins
        }
      },
      {
        label: "Innovation Hub",
        path: "//www.suptask.com/innovation-hub",
        target: "_blank",
        icon: "flask",
        requires: {
          anyOfGroups: adminGroups.accountAdmins
        }
      }
    ]
  }
];
