import "/@/startup/window";
import "/@/helpers/decorators/log";

import App from "/@/App.vue";
import store from "/@/store";
import pinia from "/@/stores";
import router from "/@/router";

// vue2
import "/@/startup/compositionApi";
import "/@/startup/vuejs-logger";
import "/@/startup/errorHandler";
import "/@/startup/validation";
import "/@/startup/globalComponents";
import "/@/startup/directives";
import "/@/startup/icons";
import "/@/startup/amplify";
import "/@/startup/helpCrunch";
import "/@/startup/floatingVue";
import "/@/startup/buefy";
import "/@/startup/portalVue";
import Vue from "vue";
import config from "/@/startup/config";

import initSentryMonitoring from "/@/startup/sentryMonitoring";
import UserTrackingService from "@/helpers/userTrackingService";

async function init() {
  // init api server mock
  if (config.USE_API_MOCK) {
    await (
      await import(/* webpackChunkName: "api-mock" */ "/@/startup/mockapi")
    ).default();
  }

  initSentryMonitoring(router);

  Vue.config.productionTip = false;

  new Vue({
    store,
    pinia,
    router,
    render: h => h(App)
  }).$mount("#s-root");

  UserTrackingService.startApplication();
}

init();

// vue3
// import globalComponents from "/@/startup/globalComponents";
// import icons from "/@/startup/icons";
// import { createApp } from "vue";
// const app = createApp(App)
//   .use(store)
//   .use(router)
//   .use(globalComponents)
//   .use(icons)
//   .mount("#s-root");
