import Vue from "vue";
import { API } from "aws-amplify";
import { AxiosError } from "axios";
import { ApiError } from "@/models/Errors";

import { handleMirageError } from "@/helpers/errors";

import SupRestApiResponse from "/@/models/SuptaskRestApiResponse";

export const requestAmplifyRestApi = async <T>(
  method: "get" | "post" | "put" | "patch" | "del",
  apiName: "suptaskRestApi" | "billing",
  path: string,
  data: unknown | undefined = undefined
) => {
  try {
    const res: SupRestApiResponse<T> = await API[method].apply(API, [
      apiName,
      path,
      data
        ? {
            body: data
          }
        : {}
    ]);
    return res.response;
  } catch (e) {
    if (handleMirageError(e, Vue.$log)) {
      return;
    }

    const axiosError = e as AxiosError<SupRestApiResponse<T>>;
    const errors = axiosError.response?.data.message
      ? [axiosError.response?.data.message]
      : [];

    if (errors.length > 0) {
      Vue.$log.error("Suptask REST API errors:", ...errors);
    }

    throw new ApiError(
      "Suptask REST API error",
      errors,
      axiosError.response?.status,
      axiosError.response?.data?.refId,
      axiosError.response?.data?.formErrors
    );
  }
};
