







































import { defineComponent } from "@vue/composition-api";

import useClaims from "/@/composables/useClaims";
import usePlan from "/@/composables/usePlan";
import useUser from "@/composables/useUser";
import useTrial from "/@/composables/useTrial";

export default defineComponent({
  props: {
    headerView: { type: Boolean, default: false }
  },

  setup() {
    const { canManageBilling } = useClaims();
    const { planName } = usePlan();
    const { displayRole } = useUser();
    const { trialExpiresInDays, trialExpiresSeverityClass } = useTrial();
    return {
      canManageBilling,
      planName,
      displayRole,
      trialExpiresInDays,
      trialExpiresSeverityClass
    };
  }
});
