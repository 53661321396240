




































































































import { defineComponent, ref, watch } from "@vue/composition-api";
import {
  useStoreGetters as useAuthGetters,
  useStoreActions as useAuthActions
} from "/@/store/modules/auth.use";
import useTheme from "@/composables/useTheme";

import usePlan from "/@/composables/usePlan";
import useTrial from "/@/composables/useTrial";
import MPlanBillingLink from "/@/components/molecules/m-plan-billing-link.vue";
import useUser from "@/composables/useUser";
import { onClickOutside } from "@vueuse/core";

export default defineComponent({
  components: {
    MPlanBillingLink
  },
  setup() {
    const { user } = useAuthGetters();
    const { displayRole } = useUser();
    const { signOutUser } = useAuthActions();
    const userDropdownActive = ref(false);
    const avatarBg = ref("transparent");
    const dropdownWrapperRef = ref(null);

    const { planName } = usePlan();
    const { trialExpiresInDays, trialExpiresSeverityClass } = useTrial();

    const toggleDropdown = () => {
      userDropdownActive.value = !userDropdownActive.value;
    };

    watch(userDropdownActive, newValue => {
      avatarBg.value = newValue ? "var(--s-color-bg-selected)" : "transparent";
    });

    onClickOutside(dropdownWrapperRef, () => {
      userDropdownActive.value = false;
    });

    const { lightMode } = useThemeSwitch();

    return {
      user,
      displayRole,
      signOutUser,
      userDropdownActive,
      lightMode,
      planName,
      trialExpiresInDays,
      trialExpiresSeverityClass,
      avatarBg,
      toggleDropdown,
      dropdownWrapperRef
    };
  }
});

function useThemeSwitch() {
  const { theme } = useTheme();
  const lightMode = ref(theme.value === "light");
  watch(lightMode, newValue => {
    theme.value = newValue ? "light" : "dark";
  });

  return {
    lightMode
  };
}
