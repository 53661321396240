import { computed } from "@vue/composition-api";
import { defineStore } from "pinia";

import store from "/@/store";
import { UserViewModel } from "@/models/UserModel";

export const useAccountStore = defineStore("account", () => {
  // Would be better to use vuex like this, but it doesn't work when called from router or from vuex store
  // const { loadCount } = useFormsActions();
  // const { user } = useAuthGetters();
  // const { count } = useFormsGetters();

  async function initInstallationState() {
    await store.dispatch("forms/loadCount");
  }

  const slackAppInstalled = computed(() => {
    const user = store.getters["auth/user"] as UserViewModel;
    return user?.team?.slackAppInstalled;
  });

  const installationCompleted = computed(() => {
    const count = store.getters["forms/count"] as number | null;
    const user = store.getters["auth/user"] as UserViewModel;

    const hasForms = (count ?? 0) > 0;
    const hasTickets = (user?.team?.ticketNumber ?? 0) > 0;

    return slackAppInstalled.value && (hasForms || hasTickets);
  });

  return {
    initInstallationState,
    slackAppInstalled,
    installationCompleted
  };
});
