

























import { defineComponent, ref } from "@vue/composition-api";

export interface SyncConfirmModal {
  open: (title?: string, message?: string) => Promise<boolean>;
}
export default defineComponent({
  props: {
    title: String,
    cancelButton: { type: String, default: "Cancel" },
    actionButton: { type: String, default: "Ok" }
  },
  setup() {
    const visible = ref(false);
    const openTitle = ref<string>();
    const openMessage = ref<string>();
    let resolve: (value: boolean | PromiseLike<boolean>) => void;

    function open(title?: string, message?: string) {
      openTitle.value = title;
      openMessage.value = message;
      visible.value = true;
      return new Promise<boolean>(_resolve => {
        resolve = _resolve;
      });
    }

    function confirm() {
      resolve(true);
      visible.value = false;
      openTitle.value = undefined;
      openMessage.value = undefined;
    }

    function cancel() {
      resolve(false);
      visible.value = false;
      openTitle.value = undefined;
      openMessage.value = undefined;
    }

    return {
      visible,
      confirm,
      cancel,
      open,
      openTitle,
      openMessage
    };
  }
});
