import { PropType } from "@vue/composition-api";

export const ASelectProps = {
  placeholder: {
    type: String as PropType<string>,
    required: false
  },
  label: {
    type: String as PropType<string>,
    required: false
  },
  subLabel: {
    type: String as PropType<string>,
    required: false
  },
  options: {
    type: Array as PropType<{ value: string; label: string }[]>,
    required: false
  },
  value: {
    type: [String, Number, Array],
    required: false
  },
  required: {
    type: Boolean,
    required: false
  },
  disabled: {
    type: Boolean,
    required: false
  }
};
