import { computed, reactive, ref } from "@vue/composition-api";
import { defineStore, StateTree } from "pinia";
import Vue from "vue";

import {
  ChannelSlackModel,
  ChannelTypeEnum,
  ChannelViewModel,
  slackModelToViewModel
} from "@/models/ChannelModel";
import ChannelRepo from "@/api/slack/repos/ChannelRepo";

import store from "/@/store";
import { ContextTracer } from "@/helpers/monitoring";
import { channelsSortFn } from "@/helpers/channels";
import { getUniqueNameNano } from "@/helpers/string";

const SKIP_API_FETCH_ELAPSED_MS_THRESHOLD = 5 * 1000;
const CHANNEL_NOT_FOUND_LABEL = "(deleted or not accessible for your user)";

interface State {
  user: string | null;
  privateChannels: ChannelViewModel[];
  publicChannels: ChannelViewModel[];
  privateLoading: boolean;
  loading: boolean;
  hasError: boolean;
  loadedCount: number;
  privateLoaded: boolean;
  allLoaded: boolean;
  elapsedMs: number | null;
  privateFetchedTs: string | null; // ISO UTC
  fetchedTs: string | null; // ISO UTC
}

export const useSlackChannelsStore = defineStore(
  "slack-channels",
  () => {
    const state = reactive<State>({
      user: store.state.auth.user?.uuid ?? null,
      privateChannels: [],
      publicChannels: [],
      privateLoading: false,
      loading: false,
      hasError: false,
      loadedCount: 0,
      privateLoaded: false,
      allLoaded: false,
      elapsedMs: null,
      privateFetchedTs: null,
      fetchedTs: null
    });

    const loadChannelsInProgress = ref(false);

    function _resetState(type: "private" | "all" = "all") {
      state.user = store.state.auth.user?.uuid ?? null;
      state.loadedCount = 0;
      if (type == "all") state.elapsedMs = null;
      state.privateFetchedTs = null;
      if (type == "all") state.fetchedTs = null;
      state.hasError = false;
      state.privateLoading = true;
      state.loading = true;
      state.privateLoaded = false;
      state.allLoaded = false;
    }

    const cached = computed(
      () =>
        !state.hasError &&
        state.privateFetchedTs !== null &&
        state.fetchedTs !== null &&
        state.elapsedMs !== null &&
        state.elapsedMs > SKIP_API_FETCH_ELAPSED_MS_THRESHOLD
    );

    const privateReady = computed(
      () => !state.hasError && !state.privateLoading
    );
    const ready = computed(() => !state.hasError && !state.loading);

    async function loadChannels(
      forceApiFetch = false,
      type: "private" | "all" = "all"
    ) {
      if (!forceApiFetch && cached.value) {
        Vue.$log.debug(
          "slack-channels store. Skip loading channels. Reason: using cache."
        );
        return;
      }

      if (loadChannelsInProgress.value) {
        Vue.$log.debug(
          "slack-channels store. Skip loading channels. Reason: already in progress."
        );
        return;
      }

      Vue.$log.debug("slack-channels store. Run loading channels.");
      loadChannelsInProgress.value = true;

      try {
        const accessToken = store.state.auth.user?.accessToken;
        if (!accessToken) {
          throw new Error("Access token is missing");
        }

        _resetState(type);

        const startedMs = performance.now();

        const resultPrivate = await ChannelRepo.get(
          accessToken,
          ["private_channel"],
          (items: ChannelSlackModel[], allLoaded) => {
            state.loadedCount = state.loadedCount + items.length;
            state.privateLoaded = allLoaded;
          }
        );
        state.privateChannels = resultPrivate
          .map(slackModelToViewModel)
          .sort(channelsSortFn);
        state.privateLoading = false;
        Vue.$log.debug(
          `slack users.conversations private, ${Math.floor(
            performance.now() - startedMs
          )} ms.`,
          resultPrivate
        );
        state.privateFetchedTs = new Date().toISOString();

        if (type == "all") {
          const resultPublic = await ChannelRepo.get(
            accessToken,
            ["public_channel"],
            (items: ChannelSlackModel[], allLoaded) => {
              state.loadedCount = state.loadedCount + items.length;
              state.allLoaded = allLoaded;
            }
          );
          state.publicChannels = resultPublic
            .map(slackModelToViewModel)
            .sort(channelsSortFn);
          Vue.$log.debug(
            `slack users.conversations public, ${Math.floor(
              performance.now() - startedMs
            )} ms.`,
            resultPublic
          );
          state.elapsedMs = Math.floor(performance.now() - startedMs);
          state.fetchedTs = new Date().toISOString();
        }
      } catch (e) {
        Vue.$log.error("Could not load channels from Slack:", e);
        state.hasError = true;
        state.privateChannels = [];
        state.publicChannels = [];
        throw e;
      } finally {
        loadChannelsInProgress.value = false;
        state.privateLoading = false;
        state.loading = false;
      }
    }

    const channelsAll = computed(() => [
      ...state.publicChannels,
      ...state.privateChannels
    ]);

    const channelsAllIndex = computed(() =>
      channelsAll.value.reduce<Record<string, ChannelViewModel>>(
        (res, item) => {
          return { ...res, [item.id]: item };
        },
        {}
      )
    );

    /**
     * If not found by id, the result will be a dummy object with `notFoundLabel` if it's not null, otherwise the result is undefined
     */
    function getChannelById(
      id: string,
      notFoundLabel: string | null = CHANNEL_NOT_FOUND_LABEL
    ) {
      return (
        channelsAllIndex.value[id] ??
        (notFoundLabel
          ? {
              id: id,
              name: `${id} ${notFoundLabel}`,
              type: ChannelTypeEnum.Group,
              private: true
            }
          : undefined)
      );
    }

    async function createChannel(
      token: string,
      name: string,
      isPrivate = false
    ): Promise<{ channel?: ChannelViewModel; error?: string }> {
      const response = await ChannelRepo.post(token, name, isPrivate);
      let channelViewModel: ChannelViewModel | undefined = undefined;
      if (response.ok) {
        channelViewModel = _storeChannel(response.channel);
      } else {
        if (response.error == "name_taken") {
          const newName = getUniqueNameNano(name, undefined, "-");
          return await createChannel(token, newName, isPrivate);
        }
      }

      return {
        channel: channelViewModel,
        error: response.error
      };
    }

    function _storeChannel(channel: ChannelSlackModel): ChannelViewModel {
      const channelViewModel = slackModelToViewModel(channel);
      const newChannels = [
        ...(channelViewModel.private
          ? state.privateChannels
          : state.publicChannels),
        channelViewModel
      ].sort(channelsSortFn);

      if (channelViewModel.private) {
        state.privateChannels = newChannels;
      } else {
        state.publicChannels = newChannels;
      }

      return channelViewModel;
    }

    return {
      state,
      channelsAll,
      channelsPrivate: computed(() => state.privateChannels),
      cached,
      privateReady,
      ready,
      loadChannels,
      getChannelById,
      createChannel
    };
  },
  {
    persist: {
      storage: localStorage,
      afterRestore: ctx => {
        Vue.$log.debug(
          `slack-channels store. Just restored '${ctx.store.$id}'`
        );
      },
      serializer: {
        serialize: (value: StateTree) => {
          const valueAsState = value as { state?: State };
          if (
            valueAsState.state &&
            valueAsState.state.hasError === false &&
            (valueAsState.state.elapsedMs ?? 0) >
              SKIP_API_FETCH_ELAPSED_MS_THRESHOLD
          ) {
            const contextTracer = new ContextTracer({
              name: "FetchSlackChannels"
            });
            contextTracer.write({
              message: "Long",
              details: `Private channels count: ${valueAsState.state.privateChannels.length},   public chanels count: ${valueAsState.state.publicChannels.length}, elapsed ms: ${valueAsState.state.elapsedMs}`
            });

            return JSON.stringify(value);
          } else {
            Vue.$log.debug(
              `slack-channels store. Invalid state or no need to cache. Nothing sent to storage.`
            );
            return JSON.stringify({});
          }
        },
        deserialize: (value: string) => {
          const result = JSON.parse(value) as { state?: State };
          if (
            result.state &&
            // state.channels is a legacy member. if exists then we don't restore such state
            !(result.state as any).channels &&
            result.state.hasError === false &&
            store.state.auth.user?.uuid &&
            result.state.user === store.state.auth.user?.uuid
          ) {
            return result;
          } else {
            Vue.$log.debug(
              `slack-channels store. Invalid or empty persistent state. Nothing sent to restore from storage.`
            );
            return {};
          }
        }
      }
    }
  }
);
