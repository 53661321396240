import { GitlabIntegration } from "../models/GitlabIntegration";
import { getJwtToken, requestApiSuptaskBot } from "@/helpers/api.bot";
import config from "/@/startup/config";

const apiUrl = `${config.SUPTASK_API}/integration`;

type CreateRequest = Required<Omit<GitlabIntegration, "id">>;
type UpdateRequest = Required<GitlabIntegration>;

export class GitlabIntegrationRepo {
  public static async get(): Promise<GitlabIntegration[]> {
    const jwtToken = await getJwtToken();
    const response = await requestApiSuptaskBot<any>(
      "GET",
      `${apiUrl}/gitlab`,
      jwtToken
    );
    return response.data;
  }

  public static async getById(id: string): Promise<GitlabIntegration> {
    const jwtToken = await getJwtToken();
    const response = await requestApiSuptaskBot<any>(
      "GET",
      `${apiUrl}/gitlab/${id}`,
      jwtToken
    );
    return response.data;
  }

  public static async post(request: CreateRequest): Promise<any> {
    const jwtToken = await getJwtToken();
    const response = await requestApiSuptaskBot<any>(
      "POST",
      `${apiUrl}/gitlab`,
      jwtToken,
      request
    );
    return response.data;
  }

  public static async put(request: UpdateRequest) {
    const jwtToken = await getJwtToken();
    const { id } = request;
    await requestApiSuptaskBot<any>(
      "PUT",
      `${apiUrl}/gitlab/${id}`,
      jwtToken,
      request
    );
  }

  public static async delete(id: string) {
    const jwtToken = await getJwtToken();
    await requestApiSuptaskBot<any>(
      "DELETE",
      `${apiUrl}/gitlab/${id}`,
      jwtToken
    );
  }
}
