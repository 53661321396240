import Vue from "vue";
import { PiniaVuePlugin } from "pinia";

Vue.use(PiniaVuePlugin);

import { createPinia } from "pinia";
import { createPersistedState } from "pinia-plugin-persistedstate";

const pinia = createPinia();
pinia.use(
  createPersistedState({
    key: id => `suptask.com-store-${id}`
  })
);
export default pinia;
