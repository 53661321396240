import { TeamViewModel } from "./TeamModel";

export type SupGroup =
  /**
   * SlackAdmins can administer their spaces in Suptask.
   * Users who are Workspace Admins / Owners in their Slack workspaces automatically get into SlackAdmins group.
   */
  | "SlackAdmins"
  /**
   * SuptaskAdmins can administer their spaces in Suptask.
   * Similar to SlackAdmins, but with limitations in features that require them to have admin rights in Slack (for instance Get Started).
   * This group is introduced to grans Suptask Admin rights to those who are not Slack Admin in their Slack workspaces
   * Assigned manually.
   */
  | "SuptaskAdmins"
  /**
   * BillingAdmins have access to the Subscriptions & Billing  page.
   */
  | "BillingAdmins";

export const adminGroups = {
  allAdmins: ["SlackAdmins", "SuptaskAdmins", "BillingAdmins"] as SupGroup[],
  accountAdmins: ["SlackAdmins", "SuptaskAdmins"] as SupGroup[],
  slackAdmins: ["SlackAdmins"] as SupGroup[],
  suptaskAdmins: ["SuptaskAdmins"] as SupGroup[],
  billingAdmins: ["SlackAdmins", "SuptaskAdmins", "BillingAdmins"] as SupGroup[]
};

export interface UserViewModel {
  /**
   * Cognito username, example: "Slack_W07QCRPA4"
   */
  id: string;
  /**
   * Cognito UUID
   */
  uuid: string;
  email: string;
  teamId: string;
  isSlackAdmin: boolean;
  /**
   * Groups, can be overridden according to the logic (for example plans)
   */
  groups: SupGroup[];
  /**
   * Original groups, not overridden
   */
  originalGroups: SupGroup[];
  teamName: string;
  accessToken: string;
  accessScopes?: string[];
  team: TeamViewModel | null;
  realName: string;
  displayName: string;
  image?: string;
  /**
   * Slack user id, example: U01A0ABCDEF
   */
  slackId?: string;
}
