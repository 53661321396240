





















import { defineComponent, ref, onMounted } from "@vue/composition-api";

export default defineComponent({
  setup() {
    const visible = ref(false);

    function on() {
      visible.value = true;
    }

    onMounted(() => {
      document.addEventListener("keyup", e => {
        const KEY_CODE_ESC = 27;
        if (e.keyCode !== KEY_CODE_ESC) return;
        visible.value = false;
      });
    });

    return {
      visible,
      on
    };
  }
});
