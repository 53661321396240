export class SupError extends Error {
  isMessageUserFriendly: boolean;
  errorObj: unknown;

  constructor(
    message: string,
    isMessageUserFriendly = false,
    errorObj: unknown = null
  ) {
    super(message);
    this.name = "SupError";
    this.isMessageUserFriendly = isMessageUserFriendly;
    this.errorObj = errorObj;

    // typescript requires set self protopype explicitly
    Object.setPrototypeOf(this, SupError.prototype);
  }
}

export class ConfigError extends SupError {}

export class ApiError extends SupError {
  errors: string[];
  httpStatus?: number;
  refId?: string;
  /**
   * Errors related to specific form fields, for example:
   *   {
   *     name: "Object with the same name already exists"
   *   }
   * Such records can be used then in vee-validate's ValidationObserver.setErrors().
   * Make sure that the keys of the errors object match the provider's vid or name props.
   * https://vee-validate.logaretm.com/v3/advanced/server-side-validation.html#setting-errors-manually
   */
  formErrors?: Record<string, string | string[]>;

  constructor(
    message: string,
    errors: string[],
    httpStatus?: number,
    refId?: string,
    formErrors?: Record<string, string[]>
  ) {
    super(message);
    this.name = "ApiError";
    this.errors = errors;
    this.httpStatus = httpStatus;
    this.refId = refId;
    this.formErrors = formErrors;

    // typescript requires set self protopype explicitly
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

export type SlackTokenErrorMessage = "token_revoked" | "token_expired";
export type SlackErrorMessage = string | SlackTokenErrorMessage;

export class SlackApiError extends Error {
  error: SlackErrorMessage;

  constructor(error: SlackErrorMessage) {
    super(error);
    this.name = "SlackApiError";
    this.error = error;

    // typescript requires set self protopype explicitly
    Object.setPrototypeOf(this, SlackApiError.prototype);
  }
}
