































import { Options, Vue } from "/@/compatibility/vue-class-component";

@Options({
  props: {
    display: {
      type: String,
      default: "page"
    },
    tooltip: String,
    question: Boolean,
    "fa-icon": {
      type: String,
      default: "info-circle"
    },
    /**
     * Show hint as body, default = false (i.e. show as title)
     */
    body: {
      type: Boolean,
      default: false
    }
  }
})
export default class AHint extends Vue {}
